// @ts-nocheck
// a library to wrap and simplify api calls
import apisauce from "apisauce";
import firebase from "firebase/app";

let SERVER_URL = process.env.REACT_APP_SERVER_URL;

// our "constructor"
const create = (baseURL = SERVER_URL) => {
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      "Cache-Control": "no-cache",
      "Access-Control-Allow-Origin": SERVER_URL,
      "Access-Control-Allow-Methods": "GET,HEAD,PUT,POST,DELETE,PATCH,OPTIONS"
    },
    // 10 second timeout...
    timeout: 180000
  });

  const createApiCall = (endpoint: any, params: any, callback: any) => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((idToken) => {
        params["idToken"] = idToken;
        api.post("/ghost/" + endpoint, params).then((res) => {
          callback(res);
        });
      });
  };

  const saveGhostCredentails = (
    publicationId: any,
    apiKey: any,
    url: any,
    callback: any
  ) => {
    createApiCall(
      "saveghostcredentials",
      { publicationId, apiKey, url },
      callback
    );
  };

  return {
    saveGhostCredentails
  };
};

export default {
  create
};
