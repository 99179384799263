// @ts-nocheck
import React from "react";
import { faGlobeAmericas } from "@fortawesome/free-solid-svg-icons";
import { AvatarSize, space } from "../Config/theme";
import styles from "../Containers/styles/LinkedInShareStyle";
import { withStyles } from "@mui/styles";
import Api from "../Services/Api";
import { Avatar, Divider, Grid, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mediaFileType, URL_REGEX, URL_REPLACE_TEXT } from "../Utils/Types";
import colors from "../Themes/Colors";
import { replaceAll } from "../Utils/UserUtils";
import DocumentViewer from "./DocumentViewer";

const api = Api.create();

export const LINKEDIN_TEXT_ONLY_PREVIEW = 106;
export const LINKEDIN_TEXT_ATTACHMENT_PREVIEW = 64;
export const LINKEDIN_IMAGE_DEFAULT_WIDTH = 540;

const expandDivLabel = {
  SEE_MORE: "...see more",
  COLLAPSE: "...collapse"
};

const supportedExtensions = [
  mediaFileType.PDF,
  mediaFileType.DOC,
  mediaFileType.DOCX,
  mediaFileType.PPT,
  mediaFileType.PPTX
];

class LinkedInPreview extends React.Component {
  bodyPreview: any;
  prevBodyPreviewHeight: any;
  constructor(props: any) {
    super(props);
    this.state = {
      isExpanded: false,
      showExpand: false,
      updateInProgress: false,
      imageOrientation: "landscape",
      height: 420,
      width: 540,
      externalCommentSectionWidth: "84%",
      prevBodyPreviewHeight: null,
      embedImageUrl: "",
      isExpandedManually: false
    };
    //Linkedin default height for portrait mode is 750 and landscape is 420
    this.bodyPreview = React.createRef();
  }

  componentDidMount() {
    this.displaySeeMore();

    let { linkedInPost, showPostCoverPhoto, thumbnail, isReviewScreen, post } =
      this.props;
    if (linkedInPost?.mediaFile?.type === mediaFileType.IMAGE) {
      this.getImageDimensions(linkedInPost?.mediaFile.url);
    } else if (linkedInPost?.mediaFile?.type === mediaFileType.VIDEO) {
      this.resizeLinkedInPostPreview(linkedInPost?.mediaFile, "landscape");
    } else if ((isReviewScreen || post) && showPostCoverPhoto && thumbnail) {
      this.getImageDimensions(thumbnail);
    }
  }

  componentDidUpdate() {
    const currentBodyPreviewHeight = this.bodyPreview.current?.clientHeight;
    if (currentBodyPreviewHeight !== this.prevBodyPreviewHeight) {
      // call displaySeeMore only if height has changed, and update the prevBodyPreviewHeight
      this.prevBodyPreviewHeight = currentBodyPreviewHeight;

      if (this.props.updateBody && !this.state.updateInProgress) {
        this.setState({ updateInProgress: true }, () => {
          this.displaySeeMore();
        });
      }
    }

    if (this.props.imageUploadComplete) {
      this.getImageDimensions(this.props.linkedInPost?.mediaFile?.url);
    }
    if (
      this.props.linkedInPost?.includeEmbed &&
      this.props.linkedInPost?.embed?.banner
    ) {
      let { embedImageUrl } = this.state;

      if (embedImageUrl !== this.props.linkedInPost?.embed?.banner) {
        this.getImageDimensions(this.props.linkedInPost?.embed?.banner);
        this.setState({
          embedImageUrl: this.props.linkedInPost?.embed?.banner
        });
      }
    }
  }

  resizeLinkedInPostPreview(
    preview: HTMLImageElement | HTMLVideoElement,
    orientation: String
  ): void {
    let deviceWidth = window.innerWidth;
    let width = preview.width;
    let height = preview.height;
    if (preview instanceof HTMLImageElement) {
      // Image previews
      if (orientation === "landscape") {
        // Landscape images
        if (deviceWidth < 576) {
          // Mobile devices
          this.setState({
            height: "180px",
            width: "320px",
            externalCommentSectionWidth: "70%"
          });
          return;
        }
        if (deviceWidth < 768) {
          // Tablet devices
          this.setState({
            externalCommentSectionWidth: "84%",
            height: "270px",
            width: "480px"
          });
          return;
        }
        // Desktop devices
        this.setState({
          externalCommentSectionWidth: "84%",
          height: (LINKEDIN_IMAGE_DEFAULT_WIDTH * height) / width,
          width: LINKEDIN_IMAGE_DEFAULT_WIDTH
        });
        return;
      }
      // Portrait images
      if (deviceWidth < 576) {
        // Mobile devices
        this.setState({
          height: "400px",
          width: "320px",
          externalCommentSectionWidth: "70%"
        });
        return;
      }
      if (deviceWidth < 768) {
        // Tablet devices
        this.setState({
          externalCommentSectionWidth: "84%",
          height: "530px",
          width: "480px"
        });
        return;
      }
      // Desktop devices
      this.setState({
        externalCommentSectionWidth: "84%",
        height: 750,
        width: LINKEDIN_IMAGE_DEFAULT_WIDTH
      });
      return;
    }
    // Video previews
    if (deviceWidth < 576) {
      // Mobile devices
      this.setState({
        height: "240px",
        width: "320px",
        externalCommentSectionWidth: "70%"
      });
      return;
    }
    if (deviceWidth < 768) {
      // Tablet devices
      this.setState({
        height: "360px",
        width: "480px"
      });
      return;
    }
    // Desktop devices
    this.setState({
      height: (LINKEDIN_IMAGE_DEFAULT_WIDTH * height) / width,
      width: LINKEDIN_IMAGE_DEFAULT_WIDTH
    });
  }

  getImageDimensions(src: any) {
    if (!src) {
      return;
    }
    //Linkedin default height for portrait mode is 750 and landscape is 420
    let image = new Image();
    image.src = src;
    image.onload = () => {
      let width = image.width;
      let height = image.height;

      if (width < height) {
        this.setState({
          imageOrientation: "portrait"
        });
        this.resizeLinkedInPostPreview(image, "portrait");
        return;
      }
      this.setState({
        imageOrientation: "landscape"
      });
      this.resizeLinkedInPostPreview(image, "landscape");
      this.props.toggleImageUploadComplete?.();
    };
  }

  //Function to check if there is an overflow in linkedin post body and display/hide see more option
  displaySeeMore = () => {
    if (
      this.bodyPreview.current?.clientHeight >= LINKEDIN_TEXT_ONLY_PREVIEW ||
      (this.bodyPreview.current?.clientHeight >=
        LINKEDIN_TEXT_ATTACHMENT_PREVIEW &&
        (this.props.linkedInPost?.mediaFile ||
          this.props.linkedInPost?.includeCoverPhoto))
    ) {
      this.setState({
        showExpand: true,
        updateInProgress: false,

        isExpanded: this.state.isExpandedManually
      });

      this.props.toggleUpdateBody?.(true);
      return;
    }
    this.setState({
      showExpand: false,
      updateInProgress: false,
      isExpanded: false
    });

    this.props.toggleUpdateBody?.(false);
  };

  expandContent = (event: any) => {
    let expandElement = event.target;

    if (expandElement && expandElement.innerHTML === expandDivLabel.SEE_MORE) {
      this.setState({ isExpanded: true, isExpandedManually: true });
      return;
    }
    if (expandElement && expandElement.innerHTML === expandDivLabel.COLLAPSE) {
      this.setState({ isExpanded: false, isExpandedManually: false });
    }
  };

  getHostName = (url: string) => {
    try {
      return new URL(url).hostname || "";
    } catch (e) {
      console.error(e);
      return "";
    }
  };

  render() {
    let {
      linkedInPost,

      linkedInHandle,

      isReviewScreen,

      showPostCoverPhoto,

      thumbnail,

      post,

      hostName,

      account,

      externalAccount,

      message,

      postURLWithUTM,

      isRepost,
      isTemplatePreview,
      classes
    } = this.props;

    let {
      isExpanded,
      showExpand,
      imageOrientation,
      height,
      width,
      externalCommentSectionWidth
    } = this.state;

    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        xs={12}
        className={isRepost ? classes.externalSharePaper : classes.previewPaper}
        style={{ minWidth: width, maxWidth: width }}
      >
        {LinkedInPostPreviewHeader(
          classes,
          account?.profilePicture || linkedInPost?.accountPicture,
          account?.name || linkedInPost?.accountName
        )}
        {LinkedInPostPreviewBody(
          classes,
          linkedInPost,
          isTemplatePreview || isExpanded,
          isTemplatePreview === undefined ? showExpand : !isTemplatePreview,
          postURLWithUTM,
          linkedInPost?.bodyPreview || linkedInPost?.body,
          this.bodyPreview,
          this.expandContent
        )}

        <Grid className={classes.item}>
          {linkedInPost?.mediaFile &&
            linkedInPost?.mediaFile.type === mediaFileType.IMAGE && (
              <img
                alt="post cover"
                src={linkedInPost.mediaFile.url}
                style={{
                  objectFit:
                    imageOrientation === "landscape" ? "cover" : "contain"
                }}
                width={isRepost ? "100%" : width}
                height={isRepost ? "100%" : height}
              />
            )}
          {linkedInPost?.mediaFile &&
            linkedInPost?.mediaFile.type === mediaFileType.VIDEO && (
              <video
                controls
                src={linkedInPost.mediaFile.url}
                className={classes.threadImage}
                poster={linkedInPost.mediaFile?.thumbnailForVideo || ""}
                width={width}
                height={height}
              />
            )}
          {linkedInPost?.mediaFile &&
            supportedExtensions.includes(linkedInPost?.mediaFile.type) && (
              <DocumentViewer documentUrl={linkedInPost?.mediaFile.url} />
            )}
          {linkedInPost?.includeCoverPhoto && (
            <div
              style={{
                background: colors.linkedInEmbedBackground,
                width: "100%",
                padding: "10px 0px"
              }}
            >
              {(isReviewScreen || post) && thumbnail && (
                <img
                  style={{
                    marginTop: -10,
                    objectFit:
                      imageOrientation === "landscape" ? "cover" : "contain"
                  }}
                  src={thumbnail}
                  width={width}
                  height={height}
                />
              )}

              <Typography variant="h200" style={{ padding: 10 }}>
                {post.title}

                <br />
              </Typography>

              <Typography variant="bodys" style={{ padding: 10 }}>
                {this.getHostName(postURLWithUTM)}{" "}
                {post.readTime && <span>&#8226; {post.readTime} </span>}
              </Typography>
            </div>
          )}
          {!isReviewScreen && linkedInPost?.includeEmbed && (
            <div
              style={{
                background: colors.linkedInEmbedBackground,
                width: "100%",
                padding: `${space.SMALL}px 0px`
              }}
            >
              {linkedInPost?.embed && (
                <>
                  {linkedInPost?.embed?.banner && (
                    <img
                      style={{
                        marginTop: `-${space.SMALL}px`,
                        objectFit:
                          imageOrientation === "landscape" ? "cover" : "contain"
                      }}
                      src={linkedInPost?.embed?.banner}
                      width={width}
                      height={height}
                    />
                  )}

                  <div style={{ padding: `${space.XS}` }}>
                    <Typography variant="h200">
                      {linkedInPost?.embed?.title}

                      <br />
                    </Typography>
                    {/* This generates basic linkedin embed ui. Title in one line and hostname along with read time in the next  */}

                    <Typography variant="bodys">
                      {this.getHostName(linkedInPost?.embed?.website)}{" "}
                      {linkedInPost?.embed?.readTime && (
                        <span>&#8226; {linkedInPost?.embed?.readTime} </span>
                      )}
                    </Typography>
                  </div>
                </>
              )}
            </div>
          )}
        </Grid>

        {/* comment.commentPreview contains html elements for tags or mentions. comment.comment only contain text  */}
        {linkedInPost?.comments
          ?.filter(
            (comment: any) => comment?.commentPreview || comment?.comment
          )
          ?.map((comment: any, index: any) => {
            let accounts = [
              ...(linkedInHandle?.accounts || []),
              ...(linkedInHandle?.organizations || [])
            ];
            let profilePicture = accounts?.find((account) => {
              return account?.userId === comment?.userId;
            })?.profilePicture;

            let name = accounts?.find((account) => {
              return account?.userId === comment?.userId;
            })?.name;

            return (
              !externalAccount?.id &&
              !isRepost && (
                <>
                  {!index && (
                    <Divider
                      style={{ margin: "20px 10px", width: "95%" }}
                      xs={12}
                    />
                  )}

                  <Grid
                    className={classes.item}
                    style={{
                      padding: "0px 10px"
                    }}
                  >
                    <Avatar
                      alt="linkedin profile picture"
                      sx={{ ...AvatarSize.m }}
                      src={profilePicture}
                      style={{ marginTop: 10 }}
                    />

                    <div
                      className={classes.commentSection}
                      style={{ width: "90%" }}
                    >
                      <Typography variant="h200">{name}</Typography>

                      <Typography
                        variant="bodys"
                        className={classes.previewCommentorProfileText}
                      >
                        Title
                      </Typography>

                      <div
                        style={{ marginTop: 10 }}
                        className={classes.previewCommentText}
                        dangerouslySetInnerHTML={{
                          __html: formatPreviewText(
                            comment?.commentPreview || comment?.comment,
                            postURLWithUTM
                          )
                        }}
                      />
                    </div>
                  </Grid>
                </>
              )
            );
          })}

        {externalAccount?.id && (
          <>
            <Divider style={{ margin: "20px 10px", width: "95%" }} xs={12} />
            <Grid
              className={classes.item}
              style={{
                padding: "0px 10px"
              }}
            >
              <Avatar
                alt="linkedin profile picture"
                sx={{ ...AvatarSize.m }}
                src={
                  externalAccount?.profilePicture || externalAccount?.picture
                }
                style={{ marginTop: 10 }}
              />

              <div
                className={classes.commentPreviewSection}
                style={{ width: externalCommentSectionWidth }}
              >
                <Typography variant="h200">
                  {`${externalAccount?.firstName || ""} ${
                    externalAccount?.lastName || ""
                  } ${externalAccount?.name || ""}`.trim()}
                </Typography>

                <Typography
                  variant="bodys"
                  className={classes.previewCommentorProfileText}
                >
                  Title
                </Typography>

                <div
                  style={{ marginTop: 10 }}
                  className={classes.previewCommentText}
                  dangerouslySetInnerHTML={{
                    __html: formatPreviewText(message, postURLWithUTM)
                  }}
                />
              </div>
            </Grid>
          </>
        )}
      </Grid>
    );
  }
}

export function LinkedInPostPreviewHeader(
  classes: any,
  profilePicture: any,
  profileName: any
) {
  return (
    <Grid item className={classes.profileSection}>
      <Avatar
        alt="linkedin profile picture"
        sx={{ ...AvatarSize.l }}
        src={profilePicture}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch"
        }}
      >
        <Typography variant="h200" style={{ marginLeft: 10 }}>
          {profileName}

          <span className={classes.previewCommentorProfileText}>
            {" "}
            &#8226; You
          </span>
        </Typography>

        <Typography variant="bodys" className={classes.previewProfileText}>
          Title
        </Typography>

        <Typography variant="bodys" className={classes.previewProfileText}>
          now
          <span>
            {" "}
            &#8226; <FontAwesomeIcon icon={faGlobeAmericas} />
          </span>
        </Typography>
      </div>
    </Grid>
  );
}

export function LinkedInPostPreviewBody(
  classes: any,
  linkedInPost: any,
  isExpanded: any,
  showExpand: any,
  postURLWithUTM: any,
  bodyText: any,
  bodyPreviewRef: any,
  expandContent: any,
  children = null
) {
  return (
    <Grid
      className={children ? classes.repostItem : classes.item}
      style={{
        padding: children ? 0 : `0px ${space.MEDIUM}`
      }}
    >
      <div
        ref={bodyPreviewRef}
        className={
          !isExpanded &&
          (linkedInPost?.mediaFile || linkedInPost?.includeCoverPhoto)
            ? classes.previewTextImageCollapsed
            : !isExpanded
            ? classes.previewTextCollpased
            : classes.previewText
        }
        onClick={expandContent}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: formatPreviewText(
              bodyText,
              postURLWithUTM,
              linkedInPost?.includeCoverPhoto
            )
          }}
        />
        {showExpand &&
          (!isExpanded ? (
            <span className={classes.expandDiv}>{expandDivLabel.SEE_MORE}</span>
          ) : (
            <span className={classes.collapseDiv}>
              {expandDivLabel.COLLAPSE}
            </span>
          ))}
      </div>

      {children && <diV>{children}</diV>}
    </Grid>
  );
}

//To display the expected text output for preview
export function formatPreviewText(
  content: any,
  postURLWithUTM = "",
  stripURL = false
) {
  if (!content) {
    return;
  }
  content = content.replace(
    URL_REGEX,
    (url: any) => `<a href=${url} target="_blank">${url}</a>`
  );
  content = content.replace(
    URL_REPLACE_TEXT,
    `<a href=${postURLWithUTM} target="_blank">${postURLWithUTM}</a>`
  );
  //Handle hash tags
  content = highlightHashtags(content);
  //Retain new line character
  content = content
    .replace(/<p>/g, "<div>")
    .replace(/<\/p>/g, "</div>")
    .replace(/\n/g, "<br/>");

  // The regex will replace all matching string with anchor tag
  // If mention(example- @horizon3ai -horizon3.ai) is matching then it will be replaced with anchor tag
  // Remove that anchor tag and replace it with inner text
  content = removeMentionUrlHighlight(content);
  return content;
}

// Removes anchor tag from mention and replaces it with inner text
function removeMentionUrlHighlight(content: string) {
  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");

    let mentions = doc.body.getElementsByClassName("mention");
    for (let mention of mentions) {
      // remove anchor tag from mention
      // get a tag and replace a tag with inner text
      let anchor = mention.getElementsByTagName("a")[0];
      if (anchor) {
        const textNode = document.createTextNode(anchor.innerText);
        anchor.parentNode.replaceChild(textNode, anchor);
      }
    }

    return doc.body.innerHTML;
  } catch (error) {
    return content;
  }
}

function highlightHashtags(body: any) {
  const REGEXP_HASHTAG = /#\S+/gi;
  const ALPHANUMERIC = /^#[a-zA-Z0-9]*$/;
  const NUMERIC = /^#[0-9]*$/;
  const REEXP_HASHTAGPARTIALSTRING = /^#[a-zA-Z0-9]+/g;

  //Space is added at end of body and before every new line character
  //to match complete string and avoid replacing substrings
  body = body + " ";
  body = replaceAll(body, "\n", " \n");

  let hashtags = body.match(REGEXP_HASHTAG);
  if (hashtags) {
    for (let hashtag of hashtags) {
      // A hashtag like #28354 with just numbers is invalid in LinkedIn
      if (NUMERIC.test(hashtag)) {
        continue;
      }
      if (ALPHANUMERIC.test(hashtag)) {
        body = body.replaceAll(
          hashtag + " ",
          '<span style="color:blue">' + hashtag + "</span> "
        );
      } else {
        //Hashtag like #independenceday20!22 enters this code block
        // output- #independenceday20 alone is highlighted
        let tagSubString = hashtag.match(REEXP_HASHTAGPARTIALSTRING);
        if (tagSubString?.length > 0) {
          let hashtagReplaceStr = hashtag.replace(
            tagSubString[0],
            '<span style="color:blue">' + tagSubString[0] + "</span>"
          );
          body = body.replaceAll(hashtag, hashtagReplaceStr);
        }
      }
    }
  }
  return body;
}

export default withStyles(styles)(LinkedInPreview);
