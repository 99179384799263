// @ts-nocheck
import {
  Box,
  Container,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Alert,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Skeleton
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Popover from "@mui/material/Popover";
import Snackbar from "@mui/material/Snackbar";
import { withStyles } from "@mui/styles";
import firebase from "firebase/app";
import "firebase/auth";
import moment from "moment";
import React, { Suspense } from "react";
import Cropper from "react-easy-crop";
import clsx from "clsx";
import { Helmet } from "react-helmet";
import BlogPostReviewSettings, {
  CUSTOM_FIELD_ID
} from "../Components/BlogPostReviewSettings";
import CustomFlatPicker from "../Components/CustomFlatPicker";
import { notify } from "../Components/CustomNotifications";
import EditorTopBar from "../Components/EditorTopBar";
import ImagePicker from "../Components/ImagePicker";
import Loading from "../Components/Loading";
import NewsletterReviewSettings from "../Components/NewsletterReviewSettings";
import StyledButton from "../design/components/StyledButton";
import StyledCheckbox from "../design/components/StyledCheckbox";
import StyledDialog from "../design/components/StyledDialog";
import colors from "../Themes/Colors";
import { SIDE_PANEL_WIDTH, space } from "../Config/theme";
import API from "../Services/Api";
import { uploadBlobImage } from "../Utils/Storage";
import {
  deleteItemInLocalStorage,
  getItemFromLocalStorage,
  setItemInLocalStorage
} from "../Utils/StorageHelper";
import {
  formatForDisplayDateTime,
  formatForGoogleThumbnailPreview,
  formatForHistoryPanel,
  formatForTimeWithTimeZone,
  formatTimeWithTimeZoneForEventInfo,
  getMinutesDifference,
  getTimeLeftInDaysHoursMinutes
} from "../Utils/TimeUtils";
import {
  blogDestinations,
  customFieldDataType,
  datePickerDefault,
  editorTopBarType,
  emailBannerType,
  postPublishType,
  postStatus,
  postType,
  postVariant,
  PROFILE_PIC,
  publicationReviewedStatus,
  publicationType,
  selectedPostFile,
  topicStatus,
  WEBFLOW_PREVIEW_TIMEOUT_IN_MINUTES,
  errorCodes,
  socialMediaType,
  socialMediaStatus,
  socialMediaPublishTypes,
  postFields,
  modulesAccess
} from "../Utils/Types";
import {
  formatNumber,
  truncateStringAndAddEllipses,
  getSiteURL,
  loadInstagramScript,
  redirectToLoginPage,
  loadTikTokScript,
  validateCustomField
} from "../Utils/UserUtils";
import ShareToSocial from "./ShareToSocial";
import styles from "./styles/ReviewStyle";
import qs from "query-string";
import StyledSelectableCard from "../Components/StyledSelectableCard";
import { ReactComponent as BlogPostIcon } from "../Images/blog_post.svg";
import { ReactComponent as NewsletterIcon } from "../Images/newsletter.svg";
import { AxiosResponse } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faHubspot,
  faLinkedinIn,
  faReddit,
  faInstagram,
  faTwitter,
  faWordpress,
  faStaylinked
} from "@fortawesome/free-brands-svg-icons";
import StyledAvatar from "../design/components/StyledAvatar";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import { compressImage } from "../Utils/ImageUtils";
import { isModuleDisabled } from "../Utils/AccessUtils";
import PageAccessContext from "../Utils/PageAccessContext";

type linkedIn = {
  accountName: string;
  scheduledOn: string;
  publishType: string;
  accountPicture: string;
  status: string;
};
type twitter = {
  twitterUserName: string;
  scheduledOn: string;
  publishType: string;
  twitterProfilePic: string;
  status: string;
};
type reddit = {
  redditUserName: string;
  scheduledOn: string;
  publishType: string;
  subreddit: string[];
  status: string;
};
type socialMedia = {
  accountName: string;
  scheduledOn: string;
  publishType: string;
  status: string;
};
type account = {
  name: string;
};
type medium = {
  selectedAccounts: account[];
  status: string;
};
type slack = {
  channels: account[];
  type: string;
  status: string;
  scheduledOn: string;
};
type destination = { id: string; name: string };

const fieldsForValidation = {
  TAGS: "tags",
  COVER_IMAGE: "cover_image",
  THUMB_NAIL: "thumbnail",
  META_TITLE: "meta_title",
  META_DESCRIPTION: "meta_description"
};

const _ = require("lodash");
const mime = require("mime-types");
const pixelWidth = require("string-pixel-width");

const PublishSuccess = React.lazy(() => import("../Components/PublishSuccess"));

const Page404 = React.lazy(() => import("./Page404"));

const EmailPreviewDialog = React.lazy(
  () => import("../Components/EmailPreviewDialog")
);

const api = API.create();
const subscribersListName = {
  PAID: "Paid"
};

const imageCropAspectRatios = {
  ORIGINAL: { name: "Original size", value: "original" },
  "2_1": { name: "2:1", value: 2 / 1 },
  "16_9": { name: "16:9", value: 16 / 9 },
  "4_3": { name: "4:3", value: 4 / 3 },
  "1_1": { name: "1:1", value: 1 / 1 }
};

export const googleThumbnailPreviewDetails = {
  TITLE_MAX_WIDTH: 600, //in px
  DESCRIPTION_CHAR_LIMIT: 158,
  FONT: "arial",
  TITLE_FONT_SIZE: 20,
  DESCRIPTION_FONT_SIZE: 14
};

const KEYWORD_SIMILARITY_CHECK_DELAY = 5000;

const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

const postSettings = {
  BLOG_POSTING: "Blog Posting",
  SOCIAL_POSTING: "Social Posting",
  NEWSLETTER_POSTING: "Newsletter Posting"
};
const StyledSelectableCardId = {
  BLOG_POST: "blog-post",
  NEWSLETTER: "newsletter"
};

const SOCIAL_DESCRIPTION_LENGTH = 320;
const WEBFLOW_REFERENCES = "_webflow_references";

const RECOMMENDED_SLUG_LENGTH = 75;
const RECOMMENDED_SLUG_WORD_COUNT = 5;

class Review extends React.Component {
  static contextType = PageAccessContext;
  BlogPostReviewSettingsRef: any;
  emailBannerImageFileUrl: any;
  emailBannerRef: any;
  fetchSocialPost: any;
  imagePickerRef: any;
  postCoverImageFileUrl: any;
  publishTypeRef: any;
  saveReviewDetails: any;
  sidePanelRef: any;
  timer: any;
  unmounted: any;
  webflowReferenceInterval: any;
  constructor(props: any) {
    super(props);

    let qsParse = qs.parse(this.props.location.search);
    this.state = {
      id: props.match.params.id,
      uploadLogoStatus: false,
      logoLocalPath: "",
      uploadedImageURL: "",
      type: postType.FREE,
      isPrivate: false,
      blogPostOnly: false,
      post: null,
      title: "",
      message: "",
      onSuccessButton: "",
      postURL: null,
      loading: true,
      error: false,
      errorMessage: "Looks like you are lost",
      publicationId: null,
      scheduledDate: null,
      subscribersCount: 0,
      deliveredCount: 0,
      src: null,
      openCrop: false,
      showPublishSuccess: false,
      client: null,
      poststatus: null,
      crop: { x: 0, y: 0 },
      zoom: 1,
      anchorEl: null,
      showEmailPreviewDialog: false,
      html: "",
      socialThumbnail: "",
      croppedSocialThumbnail: null,
      socialTitle: "",
      socialDescription: "",
      slug: "",
      postPictures: [],
      emailBanner: "",
      croppedEmailBanner: "",
      cropEmailBanner: false,
      cropThumbnail: false,
      tags: [],
      publishedOn: null,
      showEmailBanner: true,
      autocompleteTags: [],
      publicationTimezone: null,
      showPostCoverPhoto: false,
      alreadyPublished: false,
      imageExport: null,
      showEmailBannerProgress: false,
      showThumbnailProgress: false,
      publicationInReview: false,
      subscriberLists: [],
      selectedLists: [],
      prevSelectedLists: [],
      listIds: [],
      showEmailLimitWarning: false,
      showPublishConfirmation: false,
      showUpgradeBanner: false,
      isPostPaid: false,
      pubType: publicationType.FREE,
      selectedPostVariant: postVariant.BOTH,
      selectedPostSettings: postSettings.BLOG_POSTING,
      scrollToSlug: qsParse.scrollToSlug,
      selectedEmailBannerType: emailBannerType.NO_EMAIL_BANNER,
      coverImage: "",
      coverImageOriginal: "",
      teamMembers: [],
      replyTo: "",
      reschedule: false,
      isFreelancer: false,
      updateTopicTimeline: false,
      isKeywordPresent: null,
      slugWarning: null,
      slugWarningText: null,
      titleWarning: false,
      descriptionWarning: false,
      isMetaDescriptionDirty: false,
      isSlugDirty: false,
      publishInitialized: false,
      rescheduleTopicTimeline: false,
      showDateTimePicker: false,
      isApprover: false,
      senderName: "",
      destination: null,
      customFields: [],
      isPublishedElsewhere: false,
      webflowReference: [],
      isWebflowPreviewLoaderVisible: false,
      customFieldImageURL: "",
      shortenSlug: false,
      reduceSlugWordCount: false,
      clientTimezone: null,
      blogPostEnabled: true,
      newsletterEnabled: true,
      isPublishedElsewhereDisabled: false,
      showOutlinePageConfirmation: false,
      lastApprovalStage: topicStatus.IN_REVIEW,
      sendBlogNewsletter: false,
      showDestinationMissingDialog: false,
      disableScheduleButton: false,
      wordpressTags: [],
      wordpressCategories: [],
      coverImageCropSize: imageCropAspectRatios["16_9"].name,
      thumbnailCropSize: imageCropAspectRatios["2_1"].name,
      emailBannerCropSize: imageCropAspectRatios["4_3"].name,
      coverImageName: "",
      socialThumbnailName: "",
      emailBannerName: "",
      customFieldImageName: ""
    };

    this.publishTypeRef = React.createRef();
    this.emailBannerRef = React.createRef();
    this.imagePickerRef = React.createRef();
    this.sidePanelRef = React.createRef();
    this.BlogPostReviewSettingsRef = React.createRef();
    this.unmounted = false;
  }

  componentDidMount() {
    loadInstagramScript();
    loadTikTokScript();
    this.timer = null;

    this.getReviewDetails();
    this.saveReviewDetails = _.debounce(this.saveReviewFields, 1000);
  }

  componentWillUnmount = () => {
    this.unmounted = true;
    this.clearIntervalToFetchSocialPost();
    this.clearWebflowReferenceInterval();
  };

  clearIntervalToFetchSocialPost = () => {
    if (this.fetchSocialPost) {
      clearInterval(this.fetchSocialPost);
      delete this.fetchSocialPost;
    }
  };

  getReviewDetails = () => {
    let { scrollToSlug } = this.state;

    api.getReviewDetails(this.state.id, (res: any) => {
      if (res.status === 200) {
        if (res.data.loginRequired) {
          redirectToLoginPage();
          return;
        }

        // This post is public post, To access this post user must be logged out
        if (firebase.auth().currentUser && res.data.logoutRequired) {
          firebase
            .auth()
            .signOut()
            .then(() => {
              localStorage.clear();
              setItemInLocalStorage(PROFILE_PIC, "");
              window.location.reload();
            });
          return;
        }
        let post = res.data.post;
        let imageCropSize = post.publication.imageCropSize;
        let coverImage = post.coverImage;
        this.setState(
          {
            post: post,
            client: post.client,
            loading: false,
            type: post.type,
            isPrivate: post.isPrivate,
            subscribersCount: res.data.totalSubscribers,
            deliveredCount: res.data.deliveredCount,
            uploadedImageURL: coverImage,
            publicationId: post.publication._id,
            coverImage: coverImage,
            poststatus: post.status,
            scheduledDate: post.scheduledOn ? moment(post.scheduledOn) : null,
            socialDescription:
              res.data.metaDescription || this.getSocialDescription(post),
            slug: res.data.slug,
            socialTitle: post.metaTitle,
            html: res.data.html,
            postPictures: res.data.postPictures,
            emailBanner: post.emailBanner,
            croppedEmailBanner: post.emailBanner,
            tags: post.tags ? post.tags : [],
            showEmailBanner: post.showEmailBanner,
            autocompleteTags: res.data.tags,
            publicationTimezone: res.data.publicationTimezone,
            publishedOn: post.publishedOn,
            showPostCoverPhoto: post.showPostCoverPhoto,
            alreadyPublished:
              post.status === postStatus.PUBLISHED && !post.scheduledOn,
            publicationInReview:
              res.data.reviewed === publicationReviewedStatus.IN_REVIEW,
            subscriberLists: res.data.subscriberLists,
            selectedLists:
              res.data.prevSelectedLists.length > 0
                ? res.data.prevSelectedLists
                : [res.data.rootList],
            listIds:
              post.recipients.length > 0
                ? post.recipients
                : [res.data.rootList?._id],
            prevSelectedLists: res.data.prevSelectedLists,
            showUpgradeBanner: res.data.showUpgradeBanner,
            isPostPaid: post.type === postType.PAID,
            pubType: res.data.publicationType,
            socialThumbnail: post.thumbnail,
            selectedEmailBannerType: this.getSelectedEmailBannerType(post),
            selectedPostVariant: post.isPrivate
              ? postVariant.EMAIL_NEWSLETTER
              : post.blogPostOnly
              ? postVariant.BLOG_POST
              : postVariant.BOTH,
            selectedPostSettings: scrollToSlug
              ? postSettings.BLOG_POSTING
              : post.blogPostOnly ||
                (!post.publication.sendBlogNewsletter && !post.isPrivate)
              ? postSettings.BLOG_POSTING
              : postSettings.NEWSLETTER_POSTING,
            newsletterEnabled:
              post.isPrivate ||
              (!post.blogPostOnly && post.publication.sendBlogNewsletter),
            blogPostEnabled: !post.isPrivate,
            teamMembers: res.data.teamMembers,
            replyTo: post.replyTo || post.client?.email,
            coverImageOriginal: post.coverImageOriginal
              ? post.coverImageOriginal
              : coverImage,
            reschedule:
              post.scheduledOn &&
              moment(post.scheduledOn).isSameOrBefore(moment())
                ? true
                : false,
            isFreelancer: res.data.isFreelancer,
            event: res.data.event,
            blogPostOnly:
              !post.publication.sendBlogNewsletter ||
              post.blogPostOnly ||
              false,
            isMetaDescriptionDirty:
              post.metadata.isMetaDescriptionDirty || false,
            isSlugDirty: post.metadata.isSlugDirty || false,
            isApprover: res.data.isApprover,
            showEmailLimitWarning: res.data.showEmailLimitWarning,
            senderName: res.data.senderName,
            destination: this.getPostDestination(post),
            customFields: this.getCustomFields(post),
            customFieldsByDestination: [],
            isPublishedElsewhere: post.publishedElsewhere || false,
            showAdditionalPanel: false,
            defaultSyntaxHighlightingLanguage:
              post.publication?.defaultSyntaxHighlightingLanguage,
            clientTimezone: res.data.clientTimezone,
            lastApprovalStage: res.data.lastApprovalStage,
            sendBlogNewsletter: post.publication.sendBlogNewsletter,
            disableScheduleButton:
              post.scheduledOn && post.status === postStatus.PUBLISHED,
            wordpressTags: res.data.wordpressTags,
            wordpressCategories: res.data.wordpressCategories,
            coverImageCropSize:
              imageCropSize?.coverImage || imageCropAspectRatios["16_9"].name,
            thumbnailCropSize:
              imageCropSize?.thumbnail || imageCropAspectRatios["2_1"].name,
            emailBannerCropSize:
              imageCropSize?.emailBanner || imageCropAspectRatios["4_3"].name
          },
          () => {
            this.checkIfDescriptionIsTooLong();
            this.checkIsKeywordPresentInDesc();
            this.checkIfSlugContainsKeyword();
            this.checkIfTitleIsTooLong();
            this.filterCustomFieldsByDestination();
            this.checkSlugLength();
            this.saveBlogPostDestination(this.state.destination);

            this.state.subscriberLists.push({
              _id: -99,
              isRootList: false,
              name: "Create New List"
            });
          }
        );
        this.getWebflowReferences();
      } else {
        this.setState({
          loading: false,
          error: true
        });
      }
    });
  };

  getPostDestination = (post: any) => {
    if (!post.destination) {
      return null;
    }

    // Post is published return destination
    if (post.status === postStatus.PUBLISHED && !post.scheduledOn) {
      return post.destination;
    }

    // return null if external sites are empty or
    // previously selected destination is not one of the blog in the config
    if (
      !post.publication?.externalSite?.blogs?.length ||
      !post.publication.externalSite.blogs.some(
        (blog: any) => blog.id === post.destination?.id
      )
    ) {
      return null;
    }

    return post.destination;
  };

  //Fetch webflow reference field items
  getWebflowReferences = (refetch = true) => {
    let { id, post } = this.state;

    // If webflow connection is not active - don't use anything from local webflow reference or API
    if (
      !post?.publication?.webflow ||
      !post.publication?.webflow.some((webflow: any) => webflow.connected)
    ) {
      return;
    }

    try {
      //Checking if the webflow references are available in local storage
      let webflowData = getItemFromLocalStorage(`${id}${WEBFLOW_REFERENCES}`);
      if (webflowData) {
        let { webflowReference, createdOn } = JSON.parse(webflowData);
        // If local data was fetched in less than three minutes, use it.
        if (Math.abs(getMinutesDifference(createdOn)) < 3) {
          this.setState({
            webflowReference
          });
          return;
        } else {
          // else delete
          deleteItemInLocalStorage(`${id}${WEBFLOW_REFERENCES}`);
        }
      }
    } catch (error) {}

    api.getWebflowReferences(id, refetch, (res: any) => {
      if (res.status === 200) {
        let { webflowReference, retry } = res.data;

        if (retry) {
          this.retryWebflowReferences();
          return;
        }

        this.clearWebflowReferenceInterval();
        // Set data in local storage
        setItemInLocalStorage(
          `${id}${WEBFLOW_REFERENCES}`,
          JSON.stringify({ webflowReference, createdOn: moment() })
        );
        this.setState({
          webflowReference: webflowReference || []
        });
      }
    });
  };

  retryWebflowReferences = () => {
    this.clearWebflowReferenceInterval();

    if (this.unmounted) return;

    this.webflowReferenceInterval = setInterval(() => {
      this.getWebflowReferences(false);
    }, 5000);

    // remove interval after 5 minutes
    setTimeout(this.clearWebflowReferenceInterval, 5 * 60 * 1000);
  };

  clearWebflowReferenceInterval = () => {
    clearInterval(this.webflowReferenceInterval);
  };

  getCustomFields = (post: any) => {
    //Post can have old custom fields data
    //Check custom field config and add latest fields also
    if (post.customFields?.length) {
      let customFields = post.customFields;

      let postDestination = this.getPostDestinationOrFallbackDestination(post);
      return post.publication.blogCustomFields?.map((customField: any) => {
        let {
          name,
          type,
          destination,
          allowedValue,
          minimumValue,
          maximumValue,
          defaultValue,
          helpText = "",
          required = false
        } = customField || {};

        destination = destination || "";

        let value =
          type === customFieldDataType.DATE // Date type custom field don't have default value, setting current date as default
            ? moment(defaultValue || undefined)
            : defaultValue;

        let field = customFields.find(
          (field: any) => field.name === name && field.type === type
        );
        if (field && destination === postDestination?.id) {
          value =
            type === customFieldDataType.DATE // Date type custom field don't have default value, setting current date as default
              ? moment(field.value || undefined)
              : field.value;
        }

        return {
          name,
          type,
          helpText,
          required,
          destination,
          allowedValue,
          minimumValue,
          maximumValue,
          value
        };
      });
    }

    //If post don't have custom fields, Add all custom fields config
    if (post.publication.blogCustomFields?.length) {
      return post.publication.blogCustomFields?.map((customField: any) => {
        return {
          name: customField.name,
          type: customField.type,
          required: customField.required,
          helpText: customField.helpText,
          allowedValue: customField.allowedValue,
          destination: customField?.destination || "",
          minimumValue: customField.minimumValue,
          maximumValue: customField.maximumValue,
          value:
            customField.type === customFieldDataType.DATE
              ? moment()
              : customField.defaultValue
        };
      });
    }

    // else empty array
    return [];
  };

  getSocialDescription = (post: any) => {
    // isMetaDescriptionDirty true means user changed the description
    if (post.metadata?.isMetaDescriptionDirty) {
      return post.metaDescription || "";
    }

    return post.subtitle
      ? post.subtitle
      : truncateStringAndAddEllipses(post.plainText, SOCIAL_DESCRIPTION_LENGTH);
  };

  getSelectedEmailBannerType = (post: any) => {
    if (!post.showEmailBanner) {
      return emailBannerType.NO_EMAIL_BANNER;
    }
    if (post.coverImage === post.emailBanner || !post.emailBanner) {
      return emailBannerType.CROPPED_COVER_PHOTO;
    }
    return emailBannerType.UPLOAD_EMAIL_BANNER;
  };

  showSnackBar = () => {
    return (
      <Container>
        <Snackbar
          open={true}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert elevation={6} variant="filled" severity="warning">
            Your post will be clipped in emails due to it's length
          </MuiAlert>
        </Snackbar>
      </Container>
    );
  };

  showUpgradeBanner = () => {
    return (
      <Container>
        <Snackbar
          open={true}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            severity="warning"
            action={
              <StyledButton
                variant="constrastsecondary"
                onClick={this.openAccountScreen}
              >
                Upgrade
              </StyledButton>
            }
          >
            You've reached your subscriber limit. Upgrade to send email. You can
            still post to your blog
          </MuiAlert>
        </Snackbar>
      </Container>
    );
  };

  openAccountScreen = () => {
    this.props.history.push("/account#plans");
  };

  openCreateListScreen = () => {
    this.props.history.push(
      `/${this.state.publicationId}/list?postId=${this.state.post._id}`
    );
  };

  openSettings = () => {
    this.props.history.push(`/${this.state.publicationId}/blogsettings`);
  };

  openImagePicker = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
      cropEmailBanner: false
    });
  };

  closeImagePicker = () => {
    if (this.imagePickerRef?.checkImageUploading?.()) {
      return;
    }
    this.setState({
      anchorEl: null,
      cropEmailBanner: false
    });
  };

  toggleCropTool = () => {
    this.setState((prevState, props) => ({
      openCrop: !prevState.openCrop
    }));
  };

  onSelectFile = async (file: any) => {
    let targetEl = this.state.anchorEl.name;
    this.closeImagePicker();
    let extension = mime.extension(file.type);
    if (!["jpg", "jpeg", "png", "gif", "webp"].includes(extension)) {
      notify.show("Upload a PNG, JPG, JPEG, WEBP or GIF", "error");
      return;
    }

    let fileName = file.name || "";
    const MEGA_BYTE = 1024 * 1024;
    if (targetEl === selectedPostFile.SOCIAL_THUMBNAIL) {
      const FILE_SIZE_LIMIT = 2 * MEGA_BYTE; // 2MB Limit for thumbnail image
      if (file.size > FILE_SIZE_LIMIT) {
        notify.show(
          "Upload a thumbnail image that's smaller than 2 MB",
          "error"
        );
        return;
      }
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState({
          socialThumbnail: reader.result,
          uploadedImageURL: reader.result,
          cropThumbnail: true,
          cropEmailBanner: false,
          openCrop: true,
          socialThumbnailName: fileName
        });
      });
      reader.readAsDataURL(file);
    }
    if (targetEl === selectedPostFile.EMAIL_BANNER) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState({
          emailBanner: reader.result,
          cropThumbnail: false,
          cropEmailBanner: true,
          openCrop: true,
          emailBannerName: fileName
        });
      });
      reader.readAsDataURL(file);
    }
    if (targetEl === selectedPostFile.POST_COVER) {
      file = await compressImage(file, "image/webp");

      const FILE_SIZE_LIMIT = 4 * MEGA_BYTE; // 4MB Limit for cover image
      if (file.size > FILE_SIZE_LIMIT) {
        notify.show("Upload a cover image that's smaller than 4 MB", "error");
        return;
      }
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState({
          uploadedImageURL: reader.result,
          coverImageOriginal: reader.result,
          cropThumbnail: false,
          cropEmailBanner: false,
          openCrop: true,
          coverImageName: fileName
        });
      });
      reader.readAsDataURL(file);
    }
    if (targetEl === selectedPostFile.CUSTOM_IMAGE) {
      const FILE_SIZE_LIMIT = 4 * MEGA_BYTE; // 4MB Limit for custom image
      if (file.size > FILE_SIZE_LIMIT) {
        notify.show("Upload an image that's smaller than 4 MB", "error");
        return;
      }
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState({ customFieldImageURL: "" }, () =>
          this.setState({
            customFieldImageURL: reader.result,
            cropEmailBanner: false,
            cropThumbnail: false,
            openCrop: false,
            customFieldImageName: fileName
          })
        );
      });
      reader.readAsDataURL(file);
    }
  };

  onCropChange = (crop: any) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    this.makeClientCrop(croppedAreaPixels);
  };

  onZoomChange = (zoom: any) => {
    this.setState({ zoom });
  };

  async makeClientCrop(crop: any) {
    let { cropThumbnail, cropEmailBanner } = this.state;
    if (crop.width && crop.height) {
      if (cropThumbnail) {
        let coverImage = await this.getCroppedImg(crop, "newThumbnailFile.png");
        this.setState(
          { croppedSocialThumbnail: coverImage },
          this.saveReviewDetails
        );
      } else if (cropEmailBanner) {
        let coverImage = await this.getCroppedImg(
          crop,
          "newEmailBannerFile.png"
        );
        this.setState(
          { croppedEmailBanner: coverImage },
          this.saveReviewDetails
        );
        return;
      } else {
        let coverImage = await this.getCroppedImg(
          crop,
          "newPostCoverFile.webp"
        );
        this.setState({ coverImage: coverImage }, this.saveReviewDetails);
      }
    }
  }

  getCroppedImg(crop: any, fileName: any) {
    let { uploadedImageURL, emailBanner, cropEmailBanner } = this.state;
    let image = new Image();
    image.src = cropEmailBanner ? emailBanner : uploadedImageURL;
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            return;
          }

          blob.name = fileName;
          if (cropEmailBanner) {
            window.URL.revokeObjectURL(this.emailBannerImageFileUrl);
            this.emailBannerImageFileUrl = window.URL.createObjectURL(blob);
            resolve(this.emailBannerImageFileUrl);
          } else {
            window.URL.revokeObjectURL(this.postCoverImageFileUrl);
            this.postCoverImageFileUrl = window.URL.createObjectURL(blob);
            resolve(this.postCoverImageFileUrl);
          }
        },
        cropEmailBanner ? "image/png" : "image/webp"
      );
    });
  }

  getDestinationName = (
    destinationId: string,
    destinationList: destination[]
  ) => {
    return destinationList?.filter((blog: any) => blog.id === destinationId);
  };

  getScheduledTime = (
    postPublishStatus: string,
    publishType: string,
    scheduledDate: string = moment()
  ) => {
    scheduledDate = scheduledDate || moment();
    switch (publishType) {
      case socialMediaPublishTypes.TEN_MINUTES_LATER: {
        return formatForHistoryPanel(moment(scheduledDate).add(10, "minutes"));
      }
      case socialMediaPublishTypes.THIRTY_MINUTES_LATER: {
        return formatForHistoryPanel(moment(scheduledDate).add(30, "minutes"));
      }
      case socialMediaPublishTypes.HOUR_LATER: {
        return formatForHistoryPanel(moment(scheduledDate).add(1, "hour"));
      }
      case socialMediaPublishTypes.DAY_LATER: {
        return formatForHistoryPanel(moment(scheduledDate).add(1, "day"));
      }
      case socialMediaPublishTypes.WITH_POST: {
        return postPublishStatus;
      }
      default:
        return postPublishStatus;
    }
  };

  getLinkedInStatement = (
    linkedInPosts: linkedIn[],
    postPublishStatus: string,
    scheduledDate: string
  ) => {
    let linkedInStatement = linkedInPosts.map((linkedInPost: linkedIn) => {
      if (
        linkedInPost.status !== socialMediaStatus.COMPLETED &&
        linkedInPost.accountName !== ""
      ) {
        return this.getPostDetails(
          linkedInPost.accountName,
          linkedInPost.scheduledOn,
          postPublishStatus,
          linkedInPost.publishType,
          scheduledDate,
          true,
          linkedInPost.accountPicture
        );
      }
    });

    return linkedInStatement;
  };

  getPostDetails(
    name: string,
    postScheduledDate: string,
    postPublishStatus: string,
    type: string,
    blogScheduledDate: string,
    isProfilePicRequried: boolean = false,
    profilePic: string
  ) {
    return (
      <div
        style={{ width: "100%", display: "flex", margin: `${space.XS} 0px` }}
      >
        <span style={{ width: "50%", whiteSpace: "nowrap" }}>
          <Grid container direction="row">
            {isProfilePicRequried && (
              <Grid item>
                <StyledAvatar
                  src={profilePic || ""}
                  alt={name || ""}
                  style={{ width: 20, height: 20, marginRight: 10 }}
                />
              </Grid>
            )}
            <Grid item>
              <Typography variant="bodym">{name}</Typography>
            </Grid>
          </Grid>
        </span>
        <span style={{ width: "50%", float: "right", textAlign: "right" }}>
          <Typography variant="bodym">
            {type === socialMediaPublishTypes.LATER
              ? formatForHistoryPanel(postScheduledDate)
              : this.getScheduledTime(
                  postPublishStatus,
                  type,
                  blogScheduledDate
                )}
          </Typography>
        </span>
      </div>
    );
  }

  getTwitterStatement = (
    tweets: twitter[],
    postPublishStatus: string,
    scheduledDate: string
  ) => {
    let tweetStatement = tweets.map((tweet: twitter) => {
      if (
        tweet.status !== socialMediaStatus.COMPLETED &&
        tweet.twitterScreenName !== ""
      ) {
        return this.getPostDetails(
          tweet.twitterUserName,
          tweet.scheduledOn,
          postPublishStatus,
          tweet.publishType,
          scheduledDate,
          true,
          tweet.twitterProfilePic
        );
      }
    });

    return tweetStatement;
  };

  getRedditStatement = (
    redditPosts: reddit[],
    postPublishStatus: string,
    scheduledDate: string
  ) => {
    let redditStatement = redditPosts.map((redditPost: reddit) => {
      if (
        redditPost.status !== socialMediaStatus.COMPLETED &&
        redditPost.redditUserName !== ""
      ) {
        return redditPost.subreddits?.map((subreddit: string) => {
          return this.getPostDetails(
            subreddit,
            redditPost.scheduledOn,
            postPublishStatus,
            redditPost.publishType,
            scheduledDate
          );
        });
      }
    });

    return redditStatement;
  };

  getFacebookStatement = (
    facebookPosts: socialMedia[],
    postPublishStatus: string,
    scheduledDate: string
  ) => {
    let facebookStatement = facebookPosts.map((facebookPost: socialMedia) => {
      if (
        facebookPost.status !== socialMediaStatus.COMPLETED &&
        facebookPost.accountName !== null
      ) {
        return this.getPostDetails(
          facebookPost.accountName,
          facebookPost.scheduledOn,
          postPublishStatus,
          facebookPost.publishType,
          scheduledDate
        );
      }
    });
    return facebookStatement;
  };

  getInstaStatement = (
    instaPosts: socialMedia[],
    postPublishStatus: string,
    scheduledDate: string
  ) => {
    let instaStatement = instaPosts.map((instaPost: socialMedia) => {
      if (
        instaPost.status !== socialMediaStatus.COMPLETED &&
        instaPost.accountName !== null
      ) {
        return this.getPostDetails(
          instaPost.accountName,
          instaPost.scheduledOn,
          postPublishStatus,
          instaPost.publishType,
          scheduledDate
        );
      }
    });
    return instaStatement;
  };

  getSlackStatement = (
    slackMessages: slack[],
    postPublishStatus: string,
    scheduledDate: string
  ) => {
    let slackStatement = slackMessages.map((message: slack) => {
      if (message.status !== socialMediaStatus.COMPLETED) {
        return message.channels?.map((channel: account) => {
          return this.getPostDetails(
            channel.name,
            message.scheduledOn,
            postPublishStatus,
            message.type,
            scheduledDate
          );
        });
      }
    });

    return slackStatement;
  };

  getMediumStatement = (mediumPost: medium, postPublishStatus: string) => {
    let mediumStatement = [];

    if (mediumPost && mediumPost.status !== socialMediaStatus.COMPLETED) {
      mediumStatement = mediumPost.selectedAccounts?.map((account: account) => {
        return (
          <div style={{ width: "100%", display: "flex", margin: "8px 0px" }}>
            <span style={{ width: "50%", whiteSpace: "nowrap" }}>
              <Grid container direction="row">
                <Grid item>
                  <Typography variant="bodym">{account.name}</Typography>
                </Grid>
              </Grid>
            </span>
            <span style={{ width: "50%", float: "right", textAlign: "right" }}>
              <Typography variant="bodym">{postPublishStatus}</Typography>
            </span>
          </div>
        );
      });
    }

    return mediumStatement;
  };

  getLogo = (channel: string) => {
    let { classes } = this.props;
    let logoData = [
      {
        channel: socialMediaType.TWITTER,
        icon: faTwitter,
        image: "",
        className: clsx(classes.socialIcon, classes.twitter),
        alt: "Twitter",
        style: ""
      },
      {
        channel: socialMediaType.LINKED_IN,
        icon: faLinkedinIn,
        image: "",
        className: clsx(classes.socialIcon, classes.linkedin),
        alt: "LinkedIn",
        style: ""
      },
      {
        channel: socialMediaType.FACEBOOK,
        icon: faFacebook,
        image: "",
        className: clsx(classes.socialIcon, classes.linkedin),
        alt: "Facebook",
        style: ""
      },
      {
        channel: socialMediaType.REDDIT,
        icon: faReddit,
        image: "",
        className: clsx(classes.socialIcon, classes.reddit),
        alt: "Reddit",
        style: ""
      },
      {
        channel: socialMediaType.INSTAGRAM,
        icon: faInstagram,
        image: "",
        className: clsx(classes.socialIcon, classes.linkedin),
        alt: "Instagram",
        style: { color: colors.instagram }
      },
      {
        channel: socialMediaType.SLACK,
        icon: "",
        image: `${FRONTEND_URL}/resources/images/slack.svg`,
        className: clsx(classes.socialIcon, classes.slack),
        alt: "Slack",
        style: ""
      },
      {
        channel: socialMediaType.MEDIUM,
        icon: "",
        image: `${FRONTEND_URL}/resources/images/medium.svg`,
        className: clsx(classes.socialIcon, classes.medium),
        alt: "Medium",
        style: ""
      },
      {
        channel: blogDestinations.WEBFLOW,
        icon: "",
        image: `${FRONTEND_URL}/resources/images/webflow.svg`,
        className: clsx(classes.socialIcon, classes.slack),
        alt: "Webflow",
        style: ""
      },
      {
        channel: blogDestinations.WORDPRESS,
        icon: faWordpress,
        image: "",
        className: clsx(classes.socialIcon, classes.twitter),
        alt: "Wordpress",
        style: ""
      },
      {
        channel: blogDestinations.SHOPIFY,
        icon: "",
        image: `${FRONTEND_URL}/resources/images/shopify.svg`,
        className: clsx(classes.socialIcon, classes.slack),
        alt: "Shopify",
        style: ""
      },
      {
        channel: blogDestinations.GHOST,
        icon: "",
        image: `${FRONTEND_URL}/resources/images/ghost-logo-orb.png`,
        className: clsx(classes.socialIcon, classes.slack),
        alt: "Ghost",
        style: ""
      },
      {
        channel: blogDestinations.HUBSPOT,
        icon: faHubspot,
        image: "",
        className: clsx(classes.socialIcon, classes.hubspot),
        alt: "Hubspot",
        style: ""
      },
      {
        channel: blogDestinations.WEBHOOK,
        icon: faStaylinked,
        image: "",
        className: clsx(classes.socialIcon, classes.medium),
        alt: "Webhook",
        style: ""
      }
    ];

    let logo = logoData.find((logo) => logo.channel === channel);

    if (logo?.image) {
      return <img src={logo.image} className={logo.className} alt={logo.alt} />;
    }

    if (logo?.icon) {
      return (
        <FontAwesomeIcon
          icon={logo.icon}
          className={logo.className}
          alt={logo.alt}
          style={logo.style}
        />
      );
    }
  };
  getStatementDiv = (type: string, statement: HTMLObjectElement) => {
    let { publishSummaryLoading } = this.state;
    return (
      <div>
        {publishSummaryLoading ? (
          <Skeleton width="100%" height={35} />
        ) : (
          statement != "" && (
            <>
              <Divider style={{ width: "100%", marginBottom: 15 }} />
              {this.getLogo(type)}
              <p>{statement}</p>
            </>
          )
        )}
      </div>
    );
  };

  publish = () => {
    let {
      listIds,
      subscribersCount,
      deliveredCount = 0,
      title,
      onSuccessButton,
      showUpgradeBanner,
      selectedPostVariant,
      post,
      isPublishedElsewhere,
      scheduledDate,
      publicationId,
      blogPostOnly,
      sendBlogNewsletter
    } = this.state;

    if (!this.isCustomFieldsValid()) {
      return;
    }

    this.setState({ publishSummaryLoading: true });
    // If a publication has multiple external sites configured, the destination should be selected.
    if (
      selectedPostVariant !== postVariant.EMAIL_NEWSLETTER &&
      post.publication?.externalSite?.blogs?.length > 1 &&
      post.status !== postStatus.PUBLISHED &&
      !post.destination?.id
    ) {
      notify.show(
        "Please select where you'd like to publish this post",
        "error"
      );
      return;
    }

    if (
      selectedPostVariant !== postVariant.EMAIL_NEWSLETTER &&
      !post.publication?.externalSite?.blogs?.length &&
      post.status !== postStatus.PUBLISHED
    ) {
      this.setState({ showDestinationMissingDialog: true });
      return;
    }
    let publishTo = "";
    if (isPublishedElsewhere) {
      publishTo =
        "You're not publishing this via Letterdrop. It's not going out to your blog or email list.";
      this.setState({
        showNewsletterPublishConfirmation: true,
        title: title,
        message: publishTo,
        onSuccessButton: onSuccessButton
      });
      return;
    }

    onSuccessButton = scheduledDate ? "Schedule" : "Publish away";

    api.getPublishingDataDetails(
      post._id,
      publicationId,
      (res: AxiosResponse) => {
        if (res.status === 200) {
          let postPublishStatus = !scheduledDate
            ? formatForHistoryPanel(moment())
            : formatForHistoryPanel(scheduledDate);
          let linkedInStatement = this.getLinkedInStatement(
            res.data.linkedInPosts,
            postPublishStatus,
            scheduledDate
          );
          let tweetStatement = this.getTwitterStatement(
            res.data.tweets,
            postPublishStatus,
            scheduledDate
          );
          let redditStatement = this.getRedditStatement(
            res.data.redditPosts,
            postPublishStatus,
            scheduledDate
          );
          let facebookStatement = this.getFacebookStatement(
            res.data.faceBookPosts,
            postPublishStatus,
            scheduledDate
          );
          let instaStatement = this.getInstaStatement(
            res.data.instaPosts,
            postPublishStatus,
            scheduledDate
          );
          let slackStatement = this.getSlackStatement(
            res.data.slackMessages,
            postPublishStatus,
            scheduledDate
          );
          let mediumStatement = this.getMediumStatement(
            res.data.mediumPost,
            postPublishStatus
          );
          let destinationId = post.destination?.id;
          let destinationType = post.destination?.type;
          if (
            (!destinationId || !destinationType) &&
            post.status !== postStatus.PUBLISHED &&
            !post.publishedElsewhere
          ) {
            destinationId = res.data.destinationList?.externalSite.blogs[0]?.id;
            destinationType =
              res.data.destinationList?.externalSite.blogs[0]?.destination;
          }
          let destinationName = this.getDestinationName(
            destinationId,
            res.data.destinationList?.externalSite.blogs
          )[0]?.name;
          let destinationIcon = this.getLogo(destinationType);

          this.setState({
            publishSummaryLoading: false,
            linkedInStatement,
            tweetStatement,
            redditStatement,
            facebookStatement,
            instaStatement,
            slackStatement,
            mediumStatement,
            destinationName,
            destinationIcon
          });
        } else {
          this.setState({ publishSummaryLoading: false });
          notify.show(
            res.status === 400
              ? res.data
              : "Oops, something went wrong. Try again.",
            "error"
          );
        }
      }
    );

    title = "📋 One final check";
    let startingStamement = null;

    // If the user doesn't hide blog and has selected email lists
    startingStamement = <p>You’re publishing to: </p>;
    let newsletterStatement = null;
    // If post is already published and user is updating the post, append the additional text
    if (post.status === postStatus.PUBLISHED && !scheduledDate) {
      startingStamement = <p>You're updating posts on:</p>;
      if (sendBlogNewsletter) {
        newsletterStatement = (
          <p>
            It will send an email to new contacts who have been added to your
            list since you last sent this newsletter.
          </p>
        );
      }
    }

    let recipients = "";

    /**
     * calculating new readers count and showing message accordingly
     * new readers = total subscribers from selected list - email delivered count recorded in BigQuery
     */
    let newReaders = subscribersCount - deliveredCount;
    if (newReaders > 0) {
      recipients += `An email will be sent to ${newReaders} ${
        post.status === postStatus.PUBLISHED ? "new" : ""
      } reader${newReaders === 1 ? "" : "s"}. `;

      if (deliveredCount > 0) {
        recipients += `It was previously sent to ${deliveredCount} reader${
          deliveredCount === 1 ? "" : "s"
        }. It won't be resent to them. `;
      }

      recipients += `Emails can’t be unsent once you publish. Make sure you've viewed the email preview and sent yourself a test email.`;
    }
    if (sendBlogNewsletter) {
      if (blogPostOnly) {
        newsletterStatement = <p>No emails will be sent.</p>;
      } else {
        if (listIds.length > 0 && subscribersCount > 0) {
          newsletterStatement = <p>{recipients}</p>;
        }
        if (showUpgradeBanner) {
          publishTo =
            "You have more email subscribers than your plan allows. Please upgrade to continue sending email. You can still publish to your blog without upgrading.";
          onSuccessButton = "OK";
          this.setState({
            showNewsletterPublishConfirmation: true,
            title: title,
            message: publishTo,
            onSuccessButton: onSuccessButton
          });
          return;
        }
      }
    }

    // If newsletter only
    if (selectedPostVariant === postVariant.EMAIL_NEWSLETTER) {
      if (showUpgradeBanner) {
        publishTo =
          "You have more email subscribers than your plan allows. Please upgrade to continue sending email. You can still publish to your blog without upgrading.";
        onSuccessButton = "OK";
      } else if (
        listIds.length === 0 &&
        (post.status !== postStatus.PUBLISHED || post.scheduledOn)
      ) {
        // If user hides blog and doesn't select an email list
        publishTo =
          "This post won't appear in your blog. You are also not sending emails to anyone. You can always change this later, but just making sure this is what you want 🤔";
        onSuccessButton = "Yes, I'm sure";
      } else {
        publishTo =
          "You’re publishing this to your newsletter only. It won't appear on your site. ";
        if (
          !(post.status === postStatus.PUBLISHED && !post.scheduledOn) &&
          listIds.length > 0 &&
          subscribersCount > 0
        ) {
          publishTo += recipients;
        }
        onSuccessButton = scheduledDate ? "Schedule" : "Publish away";
      }

      this.setState({
        showNewsletterPublishConfirmation: true,
        title: title,
        message: publishTo,
        onSuccessButton: onSuccessButton
      });

      return;
    }

    this.setState({
      showPublishConfirmation: true,
      title: title,
      startingStamement: startingStamement,
      newsletterStatement,
      onSuccessButton: onSuccessButton
    });
  };

  toggleConfirmationDialog = () => {
    let { showPublishConfirmation } = this.state;
    this.setState({
      showPublishConfirmation: !showPublishConfirmation
    });
  };

  toggleNewsletterPublishConfirmationDialog = () => {
    let { showNewsletterPublishConfirmation } = this.state;
    this.setState({
      showNewsletterPublishConfirmation: !showNewsletterPublishConfirmation
    });
  };

  closePublishSuccessDialog = () => {
    this.getReviewDetails();
    this.setState({
      showPublishConfirmation: false,
      showPublishSuccess: false
    });
  };

  toggleRescheduleTopicTimeline = () => {
    let { rescheduleTopicTimeline } = this.state;
    this.setState({
      rescheduleTopicTimeline: !rescheduleTopicTimeline
    });
  };

  schedule = (isFromApproval = false) => {
    let { scheduledDate, publishInitialized, post } = this.state;
    if (publishInitialized) {
      return;
    }

    let reschedule =
      scheduledDate && moment(scheduledDate).isSameOrBefore(moment())
        ? true
        : false;

    if (reschedule && post.topicId) {
      this.toggleRescheduleTopicTimeline();
      return;
    }

    if (isFromApproval && !this.isCustomFieldsValid()) {
      return;
    }

    this.setState({ publishInitialized: true }, () =>
      this.save(scheduledDate, isFromApproval)
    );
  };

  save = async (scheduledDate = null, isFromApproval = false) => {
    let {
      id,
      type,
      coverImage,
      socialThumbnail,
      socialTitle,
      socialDescription,
      slug,
      isPrivate,
      blogPostOnly,
      croppedEmailBanner,
      tags,
      publishedOn,
      showPostCoverPhoto,
      listIds,
      post,
      selectedPostVariant,
      coverImageOriginal,
      customFieldsByDestination,
      coverImageName,
      socialThumbnailName,
      emailBannerName
    } = this.state;

    if (!slug) {
      notify.show("Your post needs a URL", "error");
      return;
    }

    let emailBannerURL = croppedEmailBanner;

    // Convert blobs to URLs
    if (coverImage.startsWith("blob:") || coverImage.startsWith("data:")) {
      coverImage = await uploadBlobImage(
        coverImage,
        post.publication.domain,
        coverImageName || ""
      );
    }

    // Store a copy of the image in original format since we convert it to webp
    if (
      coverImageOriginal?.startsWith("blob:") ||
      coverImageOriginal?.startsWith("data:")
    ) {
      coverImageOriginal = await uploadBlobImage(
        coverImageOriginal,
        post.publication.domain,
        coverImageName || ""
      );
    }

    if (
      socialThumbnail.startsWith("blob:") ||
      socialThumbnail.startsWith("data:")
    ) {
      socialThumbnail = await uploadBlobImage(
        socialThumbnail,
        post.publication.domain,
        socialThumbnailName || ""
      );
    }

    if (
      croppedEmailBanner.startsWith("blob:") ||
      croppedEmailBanner.startsWith("data:")
    ) {
      emailBannerURL = await uploadBlobImage(
        croppedEmailBanner,
        post.publication.domain,
        emailBannerName || ""
      );
    }

    await firebase.auth().currentUser.reload();

    let emailVerified = firebase.auth().currentUser.emailVerified;

    if (selectedPostVariant === postVariant.BLOG_POST) {
      listIds = [];
      blogPostOnly = true;
    }

    api.publishPost(
      id,
      coverImage,
      coverImageOriginal,
      type,
      scheduledDate,
      isPrivate,
      socialThumbnail,
      socialTitle,
      socialDescription,
      slug,
      emailBannerURL,
      tags,
      publishedOn,
      showPostCoverPhoto,
      listIds,
      emailVerified,
      blogPostOnly,
      customFieldsByDestination,
      isFromApproval,
      (res: any) => {
        if (res.status === 200) {
          this.setState({
            post: res.data.post,
            postURL: res.data.postURL,
            showPublishSuccess: true,
            publishInitialized: false,
            coverImage,
            coverImageOriginal,
            socialThumbnail,
            croppedEmailBanner
          });
        } else if (res.status === 400) {
          this.setState({
            publishInitialized: false
          });
          notify.show(res.data, "error");
        }
      }
    );
  };

  saveReviewFields = async () => {
    let {
      id,
      type,
      coverImage,
      socialThumbnail,
      croppedSocialThumbnail,
      socialTitle,
      socialDescription,
      isPrivate,
      blogPostOnly,
      croppedEmailBanner,
      tags,
      showPostCoverPhoto,
      scheduledDate,
      coverImageOriginal,
      replyTo,
      listIds,
      post,
      isMetaDescriptionDirty,
      isSlugDirty,
      slug,
      senderName,
      customFieldsByDestination,
      isPublishedElsewhere,
      coverImageName,
      socialThumbnailName,
      emailBannerName
    } = this.state;

    //if cropped thumbnail available save cropped thumbnail
    socialThumbnail = croppedSocialThumbnail || socialThumbnail;

    // Delete destination key in customFieldsByDestination so that we don't save them in post customFieldsByDestination
    // Create a Deep copy for the new array so that it doesn't reflect anywhere else in the page
    let customFieldsForSaveReview = _.cloneDeep(customFieldsByDestination);

    customFieldsForSaveReview.forEach((customField: any) => {
      delete customField.destination;
    });

    if (coverImage.startsWith("blob:") || coverImage.startsWith("data:")) {
      coverImage = await uploadBlobImage(
        coverImage,
        post.publication.domain,
        coverImageName || ""
      );
    }

    if (
      socialThumbnail.startsWith("blob:") ||
      socialThumbnail.startsWith("data:")
    ) {
      this.setState({ showThumbnailProgress: true });
      socialThumbnail = await uploadBlobImage(
        socialThumbnail,
        post.publication.domain,
        socialThumbnailName || ""
      );
    }

    if (
      croppedEmailBanner.startsWith("blob:") ||
      croppedEmailBanner.startsWith("data:")
    ) {
      this.setState({ showEmailBannerProgress: true });
      croppedEmailBanner = await uploadBlobImage(
        croppedEmailBanner,
        post.publication.domain,
        emailBannerName || ""
      );
    }

    if (
      coverImageOriginal?.startsWith("blob:") ||
      coverImageOriginal?.startsWith("data:")
    ) {
      coverImageOriginal = await uploadBlobImage(
        coverImageOriginal,
        post.publication.domain,
        coverImageName || ""
      );
    }
    api.saveReviewDetails(
      id,
      coverImage,
      type,
      scheduledDate,
      isPrivate,
      socialThumbnail,
      socialTitle,
      socialDescription,
      croppedEmailBanner,
      tags,
      showPostCoverPhoto,
      coverImageOriginal,
      replyTo,
      listIds,
      blogPostOnly,
      isMetaDescriptionDirty,
      isSlugDirty,
      slug,
      senderName,
      customFieldsForSaveReview,
      isPublishedElsewhere,
      (res: any) => {
        this.setState({
          showEmailBannerProgress: false,
          showThumbnailProgress: false,
          croppedSocialThumbnail: null,
          coverImage,
          socialThumbnail,
          croppedEmailBanner,
          coverImageOriginal
        });
        if (res.status === 400) {
          notify.show(res.data, "error");
        }
      }
    );
  };

  openPostScreen = () => {
    let { post, scheduledDate, postURL } = this.state;
    this.redirect(scheduledDate, post, postURL);
  };

  redirect = (scheduledDate: any, post: any, postURL: any) => {
    if (scheduledDate) {
      this.props.history.push(`/${post.publication._id}/posts`);
      return;
    }

    let url = getSiteURL(
      post.publication,
      true,
      "/" + postURL.url,
      post.destination
    );
    window.open(url, "_self");
  };

  toggleScheduleDialog = () => {
    this.setState({
      showDateTimePicker: true
    });
  };

  onScheduleDateChange = (date: any) => {
    let currentDate = new Date();
    date = new Date(date);
    if (date > currentDate) {
      this.setState({
        scheduledDate: date
      });
    }
    this.setState(
      {
        disableScheduleButton: false
      },
      () => {
        notify.show("Click on schedule to reflect your changes", "success");
      }
    );
  };

  getDefaultCoverImage = () => {
    return FRONTEND_URL + "/resources/images/default_cover_photo.jpg";
  };

  isDefaultCoverImage = () => {
    let { uploadedImageURL } = this.state;
    return uploadedImageURL.includes("default_cover_photo");
  };

  closeDialog = () => {
    this.setState({
      showEmailPreviewDialog: false
    });
  };

  openEmailPreviewDialog = () => {
    this.setState({ showEmailPreviewDialog: true });
  };

  onDescriptionChange = (value: any) => {
    if (value && value.length > SOCIAL_DESCRIPTION_LENGTH) {
      return;
    }

    this.setState(
      {
        socialDescription: value,
        isMetaDescriptionDirty: true
      },
      () => {
        this.checkIfDescriptionIsTooLong();
        this.checkIsKeywordPresentInDesc();
        this.saveReviewDetails();
      }
    );
  };

  checkIsKeywordPresentInDesc = _.debounce(() => {
    let { socialDescription, publicationId, post } = this.state;

    let { contentAnalyzed } = this.state.post;
    let keyword = contentAnalyzed?.keyword;

    if (keyword) {
      api.getKeywordSimilarity(
        publicationId,
        keyword,
        socialDescription,
        post._id,
        (res: any) => {
          if (res.status === 200) {
            this.setState({ isKeywordPresent: res.data.isKeywordPresent });
          } else {
            this.setState({ isKeywordPresent: false });
            notify.show(
              res.status === 400
                ? res.data
                : "Oops, something went wrong. Try again.",
              "error"
            );
          }
        }
      );
    }
    return;
  }, KEYWORD_SIMILARITY_CHECK_DELAY);

  handleTitleChange = (event: any) => {
    let { value } = event.target;
    this.setState({ socialTitle: value }, () => {
      this.checkIfTitleIsTooLong();
      this.saveReviewDetails();
    });
  };

  onSlugChange = (value: any) => {
    const SPACE_REGEX = /\s+/g;
    value = value.trim().toLowerCase().replace(SPACE_REGEX, "-");

    // Regex for anyting not lowercase alphabets, numbers, hyphens
    let regexp = /[^a-z0-9-]/g;
    value = value.replaceAll(regexp, "").trim();

    this.setState(
      {
        slug: value,
        isSlugDirty: true
      },
      () => {
        this.saveReviewDetails();
        this.checkIfSlugContainsKeyword();
        this.checkSlugLength();
      }
    );
  };

  checkSlugLength = () => {
    let { slug, shortenSlug, reduceSlugWordCount } = this.state;

    shortenSlug = slug.length > RECOMMENDED_SLUG_LENGTH;

    reduceSlugWordCount = slug.split("-").length > RECOMMENDED_SLUG_WORD_COUNT;

    this.setState({ shortenSlug, reduceSlugWordCount });
  };

  checkIfSlugContainsKeyword = () => {
    let { slug } = this.state;

    let { contentAnalyzed } = this.state.post;
    if (contentAnalyzed.keyword) {
      let keyword = contentAnalyzed.keyword.split(/\s+/).join("-");
      let slugWarningText = `Include the keyword, ${keyword}, in your slug`;
      if (contentAnalyzed.status && !slug.includes(keyword)) {
        this.setState({ slugWarningText, slugWarning: true });
      } else {
        this.setState({ slugWarningText, slugWarning: false });
      }
    }
  };

  checkIfTitleIsTooLong = () => {
    let { socialTitle } = this.state;
    let width = pixelWidth(socialTitle, {
      font: googleThumbnailPreviewDetails.FONT,
      size: googleThumbnailPreviewDetails.TITLE_FONT_SIZE
    });

    if (width > googleThumbnailPreviewDetails.TITLE_MAX_WIDTH) {
      this.setState({
        titleWarning: true
      });
      return;
    }
    this.setState({
      titleWarning: false
    });
  };

  checkIfDescriptionIsTooLong = () => {
    let { socialDescription } = this.state;
    let googlePreviewThumbnailDescription =
      formatForGoogleThumbnailPreview(moment()) + " — " + socialDescription;
    if (
      googlePreviewThumbnailDescription.length >
      googleThumbnailPreviewDetails.DESCRIPTION_CHAR_LIMIT
    ) {
      this.setState({
        descriptionWarning: true
      });
      return;
    }
    this.setState({
      descriptionWarning: false
    });
  };

  onImageSelected = (imageUrl: any) => {
    let targetEl = this.state.anchorEl.name;
    this.closeImagePicker();
    if (targetEl === selectedPostFile.SOCIAL_THUMBNAIL) {
      this.setState({ socialThumbnail: imageUrl }, this.saveReviewDetails);
    }
    if (targetEl === selectedPostFile.EMAIL_BANNER) {
      this.setState({ croppedEmailBanner: imageUrl }, this.saveReviewDetails);
    }
    if (targetEl === selectedPostFile.POST_COVER) {
      let { selectedEmailBannerType, croppedEmailBanner } = this.state;
      if (selectedEmailBannerType === emailBannerType.CROPPED_COVER_PHOTO) {
        croppedEmailBanner = "";
      }
      this.setState(
        {
          coverImage: imageUrl,
          croppedEmailBanner: croppedEmailBanner,
          coverImageOriginal: imageUrl
        },
        this.saveReviewDetails
      );
    }
    if (targetEl === selectedPostFile.CUSTOM_IMAGE) {
      this.setState({
        customFieldImageURL: imageUrl,
        cropEmailBanner: false,
        openCrop: false
      });
    }
  };

  onTagsChange = (tags: any) => {
    this.setState({ tags }, this.saveReviewDetails);
  };

  updatePublishedOn = (date: any) => {
    let { clientTimezone } = this.state;

    // Replace system timezone with our own client account settings timezone
    if (clientTimezone) {
      date = moment(date).tz(clientTimezone, true);
    }
    this.setState({ publishedOn: date });
  };

  handleEmailBannerToggle = () => {
    let { post, showEmailBanner } = this.state;
    api.toggleEmailBanner(post._id, showEmailBanner, (res: any) => {
      if (res.status === 200) {
        this.setState({ showEmailBanner: res.data });
      } else {
        notify.show(
          res.status === 400
            ? res.data
            : "Something went wrong. Please try again",
          "error"
        );
      }
    });
  };

  showPostCoverPhotoToggle = () => {
    let { showPostCoverPhoto, selectedEmailBannerType } = this.state;
    if (selectedEmailBannerType === emailBannerType.CROPPED_COVER_PHOTO) {
      this.changeEmailBanner();
    }
    this.setState(
      {
        showPostCoverPhoto: !showPostCoverPhoto
      },
      this.saveReviewDetails
    );
  };

  togglePublishedElsewhere = () => {
    let { isPublishedElsewhere } = this.state;

    this.setState(
      {
        isPublishedElsewhere: !isPublishedElsewhere
      },
      this.saveReviewDetails
    );
  };

  sendPostTestEmail = async () => {
    let { post, croppedEmailBanner, coverImage } = this.state;

    if (coverImage.startsWith("blob:")) {
      coverImage = await uploadBlobImage(coverImage, post.publication.domain);
    }
    if (croppedEmailBanner.startsWith("blob:")) {
      croppedEmailBanner = await uploadBlobImage(
        croppedEmailBanner,
        post.publication.domain
      );
    }

    this.setState({
      croppedEmailBanner,
      coverImage
    });

    api.sendPostTestEmail(
      post._id,
      coverImage,
      croppedEmailBanner,
      (res: any) => {
        if (res.status === 200) {
          notify.show(`Sent an email to ${res.data.email}`, "success");
        }
      }
    );
  };

  setDefaultSyntaxHighlightingLanguage = (
    defaultSyntaxHighlightingLanguage: any
  ) => {
    let { post, publicationId } = this.state;
    api.saveDefaultSyntaxHighlightingLanguage(
      publicationId,
      defaultSyntaxHighlightingLanguage,
      post._id,
      (res: any) => {
        if (res.status === 200) {
          this.setState({
            defaultSyntaxHighlightingLanguage: res.data
          });
        }
      }
    );
  };

  handleChange = (e: any, values: any) => {
    for (let value of values) {
      if (value._id === -99) {
        this.openCreateListScreen();
        return;
      }
      if (value.isRootList) {
        this.setState(
          {
            selectedLists: [value],
            listIds: [value._id]
          },
          () => {
            this.getSubscribersCount([value._id]);
            this.saveReviewDetails();
          }
        );
        return;
      }
    }

    let selectedListIds: any = [];

    values.forEach((list: any) => {
      selectedListIds.push(list._id);
    });

    this.setState(
      {
        selectedLists: values,
        listIds: selectedListIds
      },
      () => {
        this.getSubscribersCount(selectedListIds);
        this.saveReviewDetails();
      }
    );
  };

  getSubscribersCount = (listIds: any) => {
    let { selectedLists, post } = this.state;
    if (listIds.length === 0) {
      this.setState({
        subscribersCount: 0
      });
      return;
    }
    api.getSubscribersCount(listIds, post._id, (res: any) => {
      if (res.status === 200) {
        this.setState({
          subscribersCount: res.data.subscribersCount,
          deliveredCount: res.data.deliveredCount
        });
      } else {
        this.setState({
          selectedLists: selectedLists.slice(0, -1)
        });
        notify.show(
          res.status === 400
            ? res.data
            : "Something went wrong. Please try again",
          "error"
        );
      }
    });
  };

  setPostToPaid = () => {
    let { isPostPaid, subscriberLists } = this.state;

    let status = !isPostPaid;

    let type = status ? postType.PAID : postType.FREE;

    let selectedLists = status
      ? subscriberLists.filter(
          (list: any) => list.name === subscribersListName.PAID
        )
      : subscriberLists.filter((list: any) => list.isRootList === true);

    let selectedListIds = selectedLists.filter((list: any) => list._id);

    this.setState(
      {
        type,
        isPostPaid: status,
        selectedLists: selectedLists,
        listIds: selectedListIds
      },

      this.getSubscribersCount(selectedListIds)
    );
  };

  changePostToInReview = () => {
    let { post, publicationId, isFreelancer } = this.state;
    api.changePostToInReview(post._id, (res: any) => {
      if (res.status === 200) {
        this.props.history.push(
          isFreelancer ? "/agency/writer-projects" : `/${publicationId}/posts`
        );
      } else if (res.status === 400) {
        if (res.data === errorCodes.OUTLINE_NOT_APPROVED) {
          this.setState({ showOutlinePageConfirmation: true });
          return;
        }
        notify.show(res.data, "error");
      }
    });
  };

  toggleOutlinePageConfirmation = () => {
    let { showOutlinePageConfirmation } = this.state;
    this.setState({
      showOutlinePageConfirmation: !showOutlinePageConfirmation
    });
  };

  redirectToContentOutline = () => {
    let { post, publicationId } = this.state;

    this.props.history.push(
      `/${publicationId}/topics/${post.topicId.contentMap}/contentmap`
    );
  };

  sendReviewCommentToAuthor = () => {
    let { post } = this.state;
    api.sendReviewCommentToAuthor(post._id, (res: any) => {
      if (res.status === 200) {
        notify.show(
          "Your request to change were sent to the author",
          "success"
        );
        this.setState(this.toggleUpdateTopicTimeline);
      } else if (res.status === 400) {
        notify.show(res.data, "error");
      }
    });
  };

  toggleUpdateTopicTimeline = () => {
    let { updateTopicTimeline, publicationId } = this.state;
    if (updateTopicTimeline) {
      this.props.history.push(`/${publicationId}/posts`);
    }
    this.setState({ updateTopicTimeline: !updateTopicTimeline });
  };

  openCalenderEvent = () => {
    let { post, event, publicationId } = this.state;

    let publishDate =
      post.topicId?.contentStages.find(
        (stage: any) => stage.internalContentStageName === topicStatus.PUBLISHED
      ).deadline || null;

    this.props.history.push(
      `/${publicationId}/calendar?eventId=${
        post.topicId?._id || event?._id
      }&createdOn=${publishDate || event?.createdOn}&topicId=${
        post.topicId._id
      }&backpage=posts`
    );
  };

  changePostVariant = (id: any) => {
    let {
      selectedEmailBannerType,

      blogPostEnabled,

      newsletterEnabled,

      isPublishedElsewhere,

      isPublishedElsewhereDisabled
    } = this.state;
    isPublishedElsewhere = false;
    isPublishedElsewhereDisabled = false;
    let isPrivate = false;
    let blogPostOnly = false;
    let selectedPostSettings = postSettings.BLOG_POSTING;
    let selectedPostVariant = postVariant.BOTH;

    switch (id) {
      case StyledSelectableCardId.BLOG_POST:
        blogPostEnabled = !blogPostEnabled;
        break;
      case StyledSelectableCardId.NEWSLETTER:
        newsletterEnabled = !newsletterEnabled;
        break;
      default:
        break;
    }

    if (blogPostEnabled && !newsletterEnabled) {
      selectedPostVariant = postVariant.BLOG_POST;
      blogPostOnly = true;
    } else if (!blogPostEnabled && newsletterEnabled) {
      isPrivate = true;
      selectedPostVariant = postVariant.EMAIL_NEWSLETTER;
      selectedPostSettings = postSettings.NEWSLETTER_POSTING;
      if (selectedEmailBannerType === emailBannerType.CROPPED_COVER_PHOTO) {
        this.changeEmailBanner();
      }
    } else if (!blogPostEnabled && !newsletterEnabled) {
      selectedPostSettings = null;
      selectedPostVariant = postVariant.NO_POST;
      isPublishedElsewhere = true;
      isPublishedElsewhereDisabled = true;
    }

    this.setState(
      {
        blogPostEnabled,
        newsletterEnabled,
        selectedPostVariant,
        selectedPostSettings,
        isPrivate,
        blogPostOnly,
        isPublishedElsewhere,
        isPublishedElsewhereDisabled
      },
      () => this.saveReviewDetails()
    );
  };

  changeEmailBanner = () => {
    this.setState(
      {
        selectedEmailBannerType: emailBannerType.NO_EMAIL_BANNER,
        showEmailBanner: false
      },
      () => this.handleEmailBannerToggle()
    );
  };

  handlePostSettingsChange = (event: any, selectedPostSettings: any) => {
    this.setState(
      {
        selectedPostSettings: selectedPostSettings
      },
      () => this.forceUpdate()
    );
  };

  handleEmailBannerTypeChange = (event: any) => {
    let { croppedEmailBanner, cropEmailBanner, anchorEl } = this.state;

    let showEmailBanner = true;
    this.emailBannerRef.current.name = selectedPostFile.EMAIL_BANNER;

    if (event.target.value === emailBannerType.NO_EMAIL_BANNER) {
      showEmailBanner = false;
    } else if (event.target.value === emailBannerType.UPLOAD_EMAIL_BANNER) {
      anchorEl = this.emailBannerRef.current;
      cropEmailBanner = true;
    } else if (event.target.value === emailBannerType.CROPPED_COVER_PHOTO) {
      croppedEmailBanner = "";
    }

    this.setState(
      {
        selectedEmailBannerType: event.target.value,
        showEmailBanner: showEmailBanner,
        croppedEmailBanner: croppedEmailBanner,
        anchorEl: anchorEl,
        cropEmailBanner: cropEmailBanner
      },
      () => this.handleEmailBannerToggle()
    );
  };

  onPostScheduleChange = (event: any) => {
    if (event.target.value === postPublishType.NOW) {
      this.setState({
        scheduledDate: "",
        disableScheduleButton: false
      });
      return;
    }
    this.toggleScheduleDialog();
  };

  handleScheduleDateSelect = () => {
    this.toggleScheduleDialog();
  };

  getPostRecipientsText = () => {
    let {
      selectedPostVariant,

      subscribersCount,

      prevSelectedLists,

      post,

      blogPostOnly
    } = this.state;

    if (
      selectedPostVariant === postVariant.BLOG_POST ||
      (post.status === postStatus.PUBLISHED &&
        !post.scheduledOn &&
        prevSelectedLists[0]?.isRootList) ||
      blogPostOnly
    ) {
      return "";
    }
    return (
      <>
        Email to{" "}
        <b>
          {formatNumber(subscribersCount)} reader
          {subscribersCount === 1 ? "" : "s"}
        </b>
      </>
    );
  };

  getScheduleDate = (scheduledDate: any) => {
    let formattedScheduledDate = "";
    if (scheduledDate) {
      formattedScheduledDate = formatForTimeWithTimeZone(scheduledDate);
    }
    //Reason to use &nbsp; instead of space
    //If we use space its getting trimmed in dropdown menu
    return formattedScheduledDate ? (
      <>
        on&nbsp;<b>{formattedScheduledDate.split("at")[0]}</b>&nbsp;at&nbsp;
        <b>{formattedScheduledDate.split("at")[1]}</b>
      </>
    ) : (
      ""
    );
  };

  handleTeamMemberChange = (event: any) => {
    this.setState({ replyTo: event.target.value }, this.saveReviewDetails);
  };

  handleSenderNameChange = (event: any) => {
    this.setState({ senderName: event.target.value }, this.saveReviewDetails);
  };

  filterCustomFieldsByDestination = () => {
    // Filtering of Custom Fields based on Destination

    let { post, destination, customFields, customFieldsByDestination } =
      this.state;

    let destinationId = destination?.id;

    // If post destination is not present
    if (!destination) {
      let destinationsList = post?.publication?.externalSite?.blogs;

      // There's no external Destinations in db, show none
      if (!destinationsList?.length) {
        this.setState({ customFieldsByDestination: [] });
        return;
      }

      // If no destination and the post was already published we show no custom fields
      if (post.status === postStatus.PUBLISHED && !post.scheduledOn) {
        this.setState({ customFieldsByDestination: [] });
        return;
      }

      // Otherwise show the first destination's custom fields
      destinationId = destinationsList[0]?.id;
    }

    // Filter out custom fields by post destination
    customFieldsByDestination =
      customFields.filter((customField: any) => {
        return customField?.destination === destinationId;
      }) || [];
    this.setState({ customFieldsByDestination });
  };

  previewPostInWebflow = (destinationId: any, withoutEmbeds: any) => {
    let { publicationId, post, slug } = this.state;

    if (!this.isCustomFieldsValid()) {
      return;
    }

    let postId = this.state.post._id;
    this.setState({
      isWebflowPreviewLoaderVisible: true
    });
    if (withoutEmbeds) {
      notify.show(`Taking you to webflow editor in a few seconds.`, "success");
    } else {
      notify.show(
        `The preview might take a minute. Refresh if you get a 404. We'll take it down in ${WEBFLOW_PREVIEW_TIMEOUT_IN_MINUTES} minutes after you've had a chance to take a peek.`,
        "success"
      );
    }
    // Open external site in which the post was previewed (Destination Base URL + slug)
    let webflowStagingUrl = getSiteURL(
      post.publication,
      true,
      `/${slug}`,
      post.destination
    );

    api.postToWebflowStaging(
      publicationId,
      postId,
      destinationId,
      (res: any) => {
        if (res.status === 200) {
          post.webflowPostId = res.data?.webflowPostId;
          let webflowSubdomain = res.data?.webflowSubdomain;

          if (res.data.postURL?.url && res.data.postURL?.url !== slug) {
            webflowStagingUrl = getSiteURL(
              post.publication,
              true,
              `/${res.data.postURL?.url}`,
              post.destination
            );
          }

          if (webflowSubdomain) {
            let url = new URL(webflowStagingUrl);
            url.hostname = webflowSubdomain;
            webflowStagingUrl = url.href;
          }
          if (withoutEmbeds) {
            webflowStagingUrl += "?edit=1";
          }
          window.open(webflowStagingUrl, "_blank");
          this.setState({
            post,
            slug: res.data.postURL?.url || slug,
            isWebflowPreviewLoaderVisible: false
          });
        } else if (res.status === 400) {
          notify.show(res.data, "error");
        }
        this.setState({
          isWebflowPreviewLoaderVisible: false
        });
      }
    );
  };

  saveBlogPostDestination = (destination: any) => {
    let { post, slug, destination: oldDestination } = this.state;

    api.saveBlogPostDestination(
      post.publication._id,
      post._id,
      destination,
      slug,
      (res: any) => {
        if (res.status === 200) {
          post.destination = destination;
          this.setState(
            {
              post,
              destination,
              slug: res.data.slug || slug
            },
            () => {
              this.filterCustomFieldsByDestination();
            }
          );

          // Webflow preview switch - archives the preview post in the old destination
          if (
            oldDestination?.type === socialMediaType.WEBFLOW &&
            oldDestination?.id &&
            oldDestination?.id !== destination?.id
          ) {
            this.switchWebflowPostId(oldDestination.id);
          }
        } else {
          notify.show(
            res.status === 400
              ? res.data
              : "Oops, we couldn't save your blog post destination. Contact support@letterdrop.com.",
            "error"
          );
        }
      }
    );
  };

  switchWebflowPostId = (prevDestinationId: any) => {
    let { post } = this.state;
    // Prevent creating previews until we have switched the destination completely
    this.setState({
      isWebflowPreviewLoaderVisible: true
    });
    api.switchWebflowPostIdForPreview(
      post.publication._id,
      post._id,
      prevDestinationId,
      (res: any) => {
        if (res.status === 200) {
        } else {
          notify.show(
            res.status === 400
              ? res.data
              : "Oops, an error occured while switching webflow preview. Contact support@letterdrop.com.",
            "error"
          );
        }
        this.setState({
          post,
          isWebflowPreviewLoaderVisible: false
        });
      }
    );
  };

  onCustomFieldChange = (customFieldsByDestination: any) => {
    this.setState({ customFieldsByDestination }, this.saveReviewDetails);
  };

  getSelectedCollectionWebflowRef = () => {
    let { webflowReference, post } = this.state;

    // Sample webflowReference = {collectionId:"", fieldReferences: {tags:[], someCustomField: [], ...}}
    // Show the referenced fields of the configured external site if just one webflow external site is configured.
    // Because we do not display the destination selector if just one site is set
    if (
      !post.destination?.id &&
      post.publication?.externalSite?.blogs?.length === 1 &&
      post.publication.externalSite.blogs[0].destination ===
        blogDestinations.WEBFLOW
    ) {
      let ref = webflowReference.find(
        (ref: any) =>
          ref.collectionId === post.publication.externalSite.blogs[0].id
      );
      return ref?.fieldReferences || "";
    }

    // Show referenced fields of selected destination.
    if (post.destination?.id) {
      let ref = webflowReference.find(
        (ref: any) => ref.collectionId === post.destination?.id
      );
      return ref?.fieldReferences || "";
    }

    // Otherwise empty
    return "";
  };

  getCustomFieldRef = () => {
    let { post, wordpressTags, wordpressCategories } = this.state;

    let destination = post.destination;

    if (
      !post.destination?.id &&
      post.publication?.externalSite?.blogs?.length === 1
    ) {
      destination = {
        id: post.publication.externalSite.blogs[0].id,
        type: post.publication.externalSite.blogs[0].destination
      };
    }

    let wordpressCustomRef = {
      tags: wordpressTags,
      category: wordpressCategories
    };

    if (destination?.type === blogDestinations.WORDPRESS) {
      let fieldsMappingWordpress = post.publication?.wordpress?.fieldsMapping;
      let newCustomFields = {};

      if (fieldsMappingWordpress) {
        Object.keys(fieldsMappingWordpress).forEach((key: any) => {
          let value = fieldsMappingWordpress[key];
          newCustomFields[value] = wordpressCustomRef[key] || key;
        });
      }

      return newCustomFields;
    }

    if (destination?.type === blogDestinations.WEBFLOW) {
      return this.getSelectedCollectionWebflowRef();
    }
    return {};
  };

  skipCrop = () => {
    let { uploadedImageURL } = this.state;
    let image = new Image();
    image.onload = () => {
      // setting actual image size as crop size
      let crop = {
        width: image.width,
        height: image.height,
        x: 0,
        y: 0
      };
      let croppedArea = null;
      // We need to convert the picture to webp format.
      // To convert image format, use the crop function.
      this.onCropComplete(croppedArea, crop);
      this.toggleCropTool();
    };
    image.src = uploadedImageURL;
  };

  getScreenSize = () => {
    let { showAdditionalPanel } = this.state;

    if (!showAdditionalPanel) {
      return "100%";
    }

    return "calc(100% - " + SIDE_PANEL_WIDTH + ")";
  };

  getStylingForReviewContainer = () => {
    let { showAdditionalPanel } = this.state;

    if (!showAdditionalPanel) {
      return {
        paddingLeft: "10%",
        paddingRight: "10%",
        width: this.getScreenSize()
      };
    }

    return {
      paddingLeft: "0%",
      paddingRight: "0%",
      width: this.getScreenSize()
    };
  };

  getPostDestinationOrFallbackDestination = (post) => {
    let destination = post.destination;
    if (
      !destination?.id &&
      post.publication?.externalSite?.blogs?.length === 1 &&
      post.publication.externalSite.blogs[0].destination ===
        blogDestinations.WEBFLOW
    ) {
      destination = {
        id: post.publication.externalSite.blogs[0].id,
        type: post.publication.externalSite.blogs[0].destination
      };
    }

    return destination;
  };

  isCustomFieldsValid = () => {
    let { customFieldsByDestination, post } = this.state;

    try {
      let destination = this.getPostDestinationOrFallbackDestination(post);

      let invalidCustomFields = [];
      let nativeFields = this.getPostNativeFieldsForValidation();
      customFieldsByDestination = [
        ...(customFieldsByDestination || []),
        ...nativeFields
      ];

      for (let customField of customFieldsByDestination) {
        if (customField.destination !== destination?.id) {
          continue;
        }

        let errorMessage = validateCustomField(
          customField.type,
          customField.value,
          customField.required,
          customField.minimumValue,
          customField.maximumValue
        );
        if (errorMessage) {
          invalidCustomFields.push({ ...customField, errorMessage });
        }
      }

      if (invalidCustomFields.length) {
        this.setState(
          { selectedPostSettings: postSettings.BLOG_POSTING },
          () => {
            let customFieldsValidationErrors = {};
            invalidCustomFields.forEach((customField) => {
              customFieldsValidationErrors[customField.name] =
                customField.errorMessage;
            });

            this.BlogPostReviewSettingsRef.current?.setCustomFieldsValidationErrors?.(
              customFieldsValidationErrors
            );
          }
        );

        this.scrollToElement(
          invalidCustomFields[0].id ||
            `${CUSTOM_FIELD_ID}${invalidCustomFields[0].name}`
        );

        return false;
      }
    } catch (error) {
      console.error(error);
    }

    this.BlogPostReviewSettingsRef.current?.setCustomFieldsValidationErrors?.(
      {}
    );

    return true;
  };

  getPostNativeFieldsForValidation = () => {
    let {
      post,
      webflowReference,
      tags,
      coverImage,
      socialThumbnail,
      socialTitle,
      socialDescription
    } = this.state;

    try {
      let destination = this.getPostDestinationOrFallbackDestination(post);

      let nativeFields = [
        {
          name: postFields.tags.name,
          id: postFields.tags.slug,
          value: tags,
          type: customFieldDataType.MULTI_SELECT_LIST,
          required: false,
          minimumValue: null,
          maximumValue: null
        },
        {
          name: postFields.coverImage.name,
          id: postFields.coverImage.slug,
          value: coverImage,
          type: customFieldDataType.IMAGE,
          required: false,
          minimumValue: null,
          maximumValue: null
        },
        {
          name: postFields.thumbnail.name,
          id: postFields.thumbnail.slug,
          value: socialThumbnail,
          type: customFieldDataType.IMAGE,
          required: false,
          minimumValue: null,
          maximumValue: null
        },
        {
          name: postFields.metaTitle.name,
          id: postFields.metaTitle.slug,
          value: socialTitle,
          type: customFieldDataType.TEXT,
          required: false,
          minimumValue: null,
          maximumValue: null
        },
        {
          name: postFields.metaDescription.name,
          id: postFields.metaDescription.slug,
          value: socialDescription,
          type: customFieldDataType.TEXT,
          required: false,
          minimumValue: null,
          maximumValue: null
        }
      ];

      return nativeFields.map((nativeField) => {
        let collectionFields = webflowReference?.find(
          (ref) => ref.collectionId === destination?.id
        )?.collectionFields;

        if (!collectionFields) {
          return nativeField;
        }

        let webflowAccount = post.publication.webflow?.find(
          (account) => account.collectionId === destination?.id
        );

        if (!webflowAccount?.fieldsMapping) {
          return nativeField;
        }

        let mappedField = Object.keys(webflowAccount.fieldsMapping).find(
          (key) => {
            if (nativeField.id === postFields.tags.slug) {
              return [postFields.tags.slug, postFields.tag.slug].includes(
                webflowAccount.fieldsMapping[key]
              );
            }
            return webflowAccount.fieldsMapping[key] === nativeField.id;
          }
        );

        if (!mappedField) {
          return nativeField;
        }

        let collectionField = collectionFields.find((collectionField) => {
          return collectionField.slug === mappedField;
        });

        if (!collectionField) {
          return nativeField;
        }

        let validations = collectionField.validations;
        nativeField.required = collectionField.isRequired;
        nativeField.minimumValue =
          validations?.minValue || validations?.minLength;
        nativeField.maximumValue =
          validations?.maxValue || validations?.maxLength;
        nativeField["destination"] = webflowAccount.collectionId;

        return nativeField;
      });
    } catch (error) {
      return [];
    }
  };

  scrollToElement = (elementId) => {
    let retries = 0;
    this.elementInterval = setInterval(() => {
      if (retries === 10) {
        clearInterval(this.elementInterval);
      }
      retries += 1;

      let element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        clearInterval(this.elementInterval);
      }
    }, 500);
  };

  getCropSizeForImage = (returnAspectValue: boolean = false) => {
    let {
      coverImageCropSize,
      thumbnailCropSize,
      emailBannerCropSize,
      cropThumbnail,
      cropEmailBanner
    } = this.state;

    let aspectRatio = coverImageCropSize;
    if (cropThumbnail) {
      aspectRatio = thumbnailCropSize;
    }

    if (cropEmailBanner) {
      aspectRatio = emailBannerCropSize;
    }

    if (!returnAspectValue) {
      return aspectRatio;
    }

    return (
      Object.values(imageCropAspectRatios).find(
        (value) => value.name === aspectRatio
      )?.value || imageCropAspectRatios.ORIGINAL.value
    );
  };

  onImageCropSizeChange = (size) => {
    let {
      coverImageCropSize,
      thumbnailCropSize,
      emailBannerCropSize,
      cropThumbnail,
      cropEmailBanner
    } = this.state;

    if (cropThumbnail) {
      thumbnailCropSize = size;
    } else if (cropEmailBanner) {
      emailBannerCropSize = size;
    } else {
      coverImageCropSize = size;
    }

    this.setState(
      {
        coverImageCropSize,
        thumbnailCropSize,
        emailBannerCropSize
      },
      this.saveImageCropSize
    );
  };

  saveImageCropSize = () => {
    let { post, coverImageCropSize, thumbnailCropSize, emailBannerCropSize } =
      this.state;

    api.saveImageCropSizes(
      post.publication._id,
      coverImageCropSize,
      thumbnailCropSize,
      emailBannerCropSize,
      () => {}
    );
  };

  getPostSettingsButtons = () => {
    let {
      post,
      selectedPostVariant,
      selectedPostSettings,
      newsletterEnabled,
      sendBlogNewsletter
    } = this.state;

    let buttonOptions = Object.keys(postSettings).filter((key) => {
      if (
        (postSettings[key] === postSettings.NEWSLETTER_POSTING &&
          !sendBlogNewsletter &&
          !newsletterEnabled) ||
        (postSettings[key] === postSettings.SOCIAL_POSTING &&
          isModuleDisabled(
            this.context,
            post.publication._id,
            modulesAccess.SOCIAL
          ))
      ) {
        return false;
      }

      return true;
    });

    if (buttonOptions.length < 2) {
      return "";
    }

    return (
      <ToggleButtonGroup size="large" value={selectedPostSettings}>
        {buttonOptions.map((key) => {
          return (
            <ToggleButton
              style={{ width: "100%" }}
              disabled={(() => {
                switch (selectedPostVariant) {
                  case postVariant.EMAIL_NEWSLETTER:
                    return [
                      postSettings.BLOG_POSTING,
                      postSettings.SOCIAL_POSTING
                    ].includes(postSettings[key]);
                  case postVariant.BLOG_POST:
                    return (
                      postSettings[key] === postSettings.NEWSLETTER_POSTING
                    );
                  case postVariant.NO_POST:
                    return true;
                  default:
                    return false;
                }
              })()}
              onClick={this.handlePostSettingsChange}
              value={postSettings[key]}
            >
              {postSettings[key]}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    );
  };

  render() {
    let { classes } = this.props;
    let {
      uploadedImageURL,
      loading,
      error,
      errorMessage,
      scheduledDate,
      post,
      coverImageOriginal,
      croppedEmailBanner,
      crop,
      coverImage,
      openCrop,
      showPublishSuccess,
      poststatus,
      postURL,
      zoom,
      anchorEl,
      showEmailPreviewDialog,
      socialDescription,
      slug,
      postPictures,
      cropEmailBanner,
      cropThumbnail,
      emailBanner,
      tags,
      publishedOn,
      autocompleteTags,
      showPostCoverPhoto,
      alreadyPublished,
      publicationInReview,
      subscriberLists,
      selectedLists,
      showEmailLimitWarning,
      showPublishConfirmation,
      prevSelectedLists,
      showUpgradeBanner,
      isPostPaid,
      pubType,
      selectedPostVariant,
      selectedPostSettings,
      socialThumbnail,
      selectedEmailBannerType,
      message,
      title,
      onSuccessButton,
      teamMembers,
      replyTo,
      reschedule,
      isFreelancer,
      slugWarning,
      slugWarningText,
      titleWarning,
      descriptionWarning,
      socialTitle,
      isKeywordPresent,
      updateTopicTimeline,
      rescheduleTopicTimeline,
      showDateTimePicker,
      isApprover,
      senderName,
      destination,
      customFieldsByDestination,
      isPublishedElsewhere,
      isWebflowPreviewLoaderVisible,
      defaultSyntaxHighlightingLanguage,
      customFieldImageURL,
      customFieldImageName,
      shortenSlug,
      reduceSlugWordCount,
      clientTimezone,
      scrollToSlug,
      isMetaDescriptionDirty,
      isSlugDirty,
      blogPostEnabled,
      newsletterEnabled,
      isPublishedElsewhereDisabled,
      showOutlinePageConfirmation,
      lastApprovalStage,
      sendBlogNewsletter,
      showDestinationMissingDialog,
      disableScheduleButton,
      linkedInStatement,
      newsletterStatement,
      tweetStatement,
      redditStatement,
      facebookStatement,
      instaStatement,
      slackStatement,
      mediumStatement,
      destinationIcon,
      destinationName,
      startingStamement,
      showNewsletterPublishConfirmation,
      wordpressTags,
      wordpressCategories
    } = this.state;

    let open = Boolean(anchorEl);
    let id = open ? "simple-popover" : undefined;

    if (loading) {
      return <Loading />;
    }

    if (error) {
      return (
        <Suspense fallback={<div />}>
          <Page404 errorMessage={errorMessage} />
        </Suspense>
      );
    }

    let postBaseUrl = getSiteURL(post.publication, true, "/", post.destination);
    return (
      <Container className={classes.maxWidth100}>
        <Helmet>
          <title>Review - {post.title} | Letterdrop</title>
        </Helmet>
        {showEmailLimitWarning &&
          selectedPostVariant !== postVariant.BLOG_POST &&
          this.showSnackBar()}
        {showUpgradeBanner && this.showUpgradeBanner()}

        <Grid
          container
          style={{ width: this.getScreenSize() }}
          className={classes.headerContainer}
        >
          <EditorTopBar
            screen={editorTopBarType.POST_REVIEW}
            fromReviewScreen={true}
            publicationId={post.publication._id}
            backLink={`/${post.publication._id}/posts/${post._id}`}
            backLabel="Editor"
            showReviewBanner={publicationInReview}
            postPublishHeader={true}
            reviewHeaderText={this.getPostRecipientsText()}
            toggleScheduleDialog={this.toggleScheduleDialog}
            publishTypeSelectRef={this.publishTypeRef}
            publish={this.publish}
            changePostToInReview={this.changePostToInReview}
            schedule={this.schedule}
            post={post}
            scheduledDate={this.getScheduleDate(scheduledDate)}
            onPostScheduleChange={this.onPostScheduleChange}
            onScheduleDateSelect={this.handleScheduleDateSelect}
            hideScheduleSelector={
              post.status === postStatus.PUBLISHED && !post.scheduledOn
            }
            sendReviewCommentToAuthor={this.sendReviewCommentToAuthor}
            reschedule={reschedule}
            isApprover={isApprover}
            isWebflowPreviewLoaderVisible={isWebflowPreviewLoaderVisible}
            lastApprovalStage={lastApprovalStage}
            contentStages={post.topicId?.contentStages || []}
            previewEmail={this.openEmailPreviewDialog}
            previewPost={(event: any) => {
              this.BlogPostReviewSettingsRef.current?.handlePreviewButtonClick(
                event
              );
            }}
            isNewsletterSettingsSelected={
              selectedPostSettings === postSettings.NEWSLETTER_POSTING
            }
            isBlogPostSettingsSelected={
              selectedPostSettings === postSettings.BLOG_POSTING
            }
            disableScheduleButton={disableScheduleButton}
          />
        </Grid>

        <Grid
          container
          direction="column"
          className={classes.maxWidthContainer}
          justifyContent="center"
        >
          <Grid
            style={this.getStylingForReviewContainer()}
            container
            item
            direction="column"
            className={classes.container}
            justifyContent="center"
          >
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.reviewTitleContainer}
            >
              {sendBlogNewsletter && (
                <Grid container direction="column" xs="12">
                  <Grid item xs="6" style={{ marginBottom: space.XS }}>
                    <Typography variant="h500" className={classes.reviewTitle}>
                      Publishing Type
                    </Typography>
                  </Grid>
                  <Grid item xs="6" style={{ marginBottom: space.SMALL }}>
                    <Typography variant="bodym" className={classes.postType}>
                      Select your post type
                    </Typography>
                  </Grid>

                  <Grid display="flex" xs="12">
                    <Grid item xs="6">
                      <StyledSelectableCard
                        id={StyledSelectableCardId.BLOG_POST}
                        icon={BlogPostIcon}
                        title="Blog Post"
                        description="Share your article on your blog & socials"
                        checked={blogPostEnabled}
                        onChange={this.changePostVariant}
                      />
                    </Grid>

                    <Grid item xs="6">
                      {(sendBlogNewsletter || newsletterEnabled) && (
                        <StyledSelectableCard
                          id={StyledSelectableCardId.NEWSLETTER}
                          icon={NewsletterIcon}
                          title="Newsletter"
                          description="Share your article to your newsletter"
                          checked={newsletterEnabled}
                          onChange={this.changePostVariant}
                        />
                      )}
                    </Grid>
                  </Grid>
                  {!blogPostEnabled && !newsletterEnabled && (
                    <Alert severity="info" style={{ marginTop: 10 }}>
                      Looks like you're publishing this elsewhere. For example,
                      as a whitepaper or on 3rd party news site. We'll mark this
                      as published, but won't actually publish it for you
                    </Alert>
                  )}

                  <Divider className={classes.divider} />
                </Grid>
              )}

              <Typography variant="h500" className={classes.reviewTitle}>
                Settings
              </Typography>

              <Typography variant="bodym" className={classes.postType}>
                For each type of publication, you can configure its specific
                parameters
              </Typography>

              {this.getPostSettingsButtons()}

              {pubType === publicationType.PAID && (
                <div
                  style={{ marginTop: space.SMALL, marginBottom: space.SMALL }}
                >
                  <StyledCheckbox
                    className={classes.fontSize14}
                    color="primary"
                    checked={isPostPaid}
                    onChange={this.setPostToPaid}
                    label={
                      <Typography>
                        This post is only for paid members
                      </Typography>
                    }
                  />
                </div>
              )}
            </Grid>

            <Divider className={classes.divider} />

            {selectedPostSettings === postSettings.BLOG_POSTING && (
              <BlogPostReviewSettings
                ref={this.BlogPostReviewSettingsRef}
                scrollToSlug={scrollToSlug}
                post={post}
                coverImage={coverImage}
                socialDescription={socialDescription}
                slug={slug}
                tags={tags}
                autocompleteTags={autocompleteTags}
                status={poststatus}
                publishedOn={publishedOn}
                showPostCoverPhoto={showPostCoverPhoto}
                socialThumbnail={socialThumbnail}
                onTagsChange={this.onTagsChange}
                updatePublishedOn={this.updatePublishedOn}
                openImagePicker={this.openImagePicker}
                publication={post.publication}
                postURL={postBaseUrl}
                titleWarning={titleWarning}
                descriptionWarning={descriptionWarning}
                socialTitle={socialTitle}
                onDescriptionChange={this.onDescriptionChange}
                onTitleChange={this.handleTitleChange}
                onSlugChange={this.onSlugChange}
                showPostCoverPhotoToggle={this.showPostCoverPhotoToggle}
                slugWarning={slugWarning}
                slugWarningText={slugWarningText}
                isKeywordPresent={isKeywordPresent}
                destination={destination}
                onDestinationChange={this.saveBlogPostDestination}
                previewPostInWebflow={this.previewPostInWebflow}
                customFields={customFieldsByDestination}
                onCustomFieldChange={this.onCustomFieldChange}
                keyword={
                  post.contentAnalyzed && post.contentAnalyzed.keyword
                    ? post.contentAnalyzed.keyword
                    : ""
                }
                isPublishedElsewhere={isPublishedElsewhere}
                isPublishedElsewhereDisabled={isPublishedElsewhereDisabled}
                togglePublishedElsewhere={this.togglePublishedElsewhere}
                webflowReferences={this.getSelectedCollectionWebflowRef()}
                customFieldReferences={this.getCustomFieldRef()}
                wordpressReferences={post.publication?.wordpress?.fieldsMapping}
                isWebflowPreviewLoaderVisible={isWebflowPreviewLoaderVisible}
                setDefaultSyntaxHighlightingLanguage={
                  this.setDefaultSyntaxHighlightingLanguage
                }
                defaultSyntaxHighlightingLanguage={
                  defaultSyntaxHighlightingLanguage
                }
                customFieldImageURL={customFieldImageURL}
                customFieldImageName={customFieldImageName}
                shortenSlug={shortenSlug}
                reduceSlugWordCount={reduceSlugWordCount}
                clientTimezone={clientTimezone}
                isMetaDescriptionDirty={isMetaDescriptionDirty}
                isSlugDirty={isSlugDirty}
                nativeFields={this.getPostNativeFieldsForValidation()}
                wordpressTags={wordpressTags}
                wordpressCategories={wordpressCategories}
              />
            )}
            {selectedPostSettings === postSettings.SOCIAL_POSTING &&
              !isModuleDisabled(
                this.context,
                post.publication._id,
                modulesAccess.SOCIAL
              ) && (
                <ShareToSocial
                  post={post}
                  isApprovedPublication={
                    post.publication.reviewed ===
                    publicationReviewedStatus.APPROVED
                  }
                  showPostCoverPhoto={showPostCoverPhoto}
                  coverImage={coverImageOriginal}
                  socialThumbnail={socialThumbnail}
                />
              )}
            {selectedPostSettings === postSettings.NEWSLETTER_POSTING && (
              <NewsletterReviewSettings
                post={post}
                showPostCoverPhoto={showPostCoverPhoto}
                publicationInReview={publicationInReview}
                subscriberLists={subscriberLists}
                selectedLists={selectedLists}
                prevSelectedLists={prevSelectedLists}
                selectedPostVariant={selectedPostVariant}
                selectedEmailBannerType={selectedEmailBannerType}
                onListChange={this.handleChange}
                onEmailBannerTypeChange={this.handleEmailBannerTypeChange}
                openEmailPreviewDialog={this.openEmailPreviewDialog}
                emailBannerRef={this.emailBannerRef}
                handleTeamMemberChange={this.handleTeamMemberChange}
                handleSenderNameChange={this.handleSenderNameChange}
                replyTo={replyTo}
                teamMembers={teamMembers}
                senderName={senderName}
              />
            )}

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={this.closeImagePicker}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "center"
              }}
            >
              <ImagePicker
                unsplashRef={(ref: any) => {
                  this.imagePickerRef = ref;
                }}
                onImageSelect={this.onImageSelected}
                onImageUpload={this.onSelectFile}
                postPictures={postPictures}
                isCoverPhoto={!cropEmailBanner}
                showUnsplashPicker={cropEmailBanner ? false : true}
                convertToWebp={anchorEl?.name === selectedPostFile.POST_COVER}
              />
            </Popover>

            <CustomFlatPicker
              publicationId={post.publication._id}
              open={showDateTimePicker}
              value={scheduledDate}
              anchorEl={this.publishTypeRef?.current}
              onClose={(date) => {
                this.setState(
                  {
                    showDateTimePicker: false
                  },

                  this.onScheduleDateChange(date)
                );
              }}
              type={datePickerDefault.DEADLINE}
            />
          </Grid>
        </Grid>

        {selectedPostSettings === postSettings.SOCIAL_MEDIA_SETTINGS && (
          <Grid container style={{ width: this.getScreenSize() }}>
            <ShareToSocial
              post={post}
              isApprovedPublication={
                post.publication.reviewed === publicationReviewedStatus.APPROVED
              }
              showPostCoverPhoto={showPostCoverPhoto}
              coverImage={coverImageOriginal}
              socialThumbnail={socialThumbnail}
            />
          </Grid>
        )}

        <StyledDialog
          open={openCrop}
          title={`Crop your ${cropThumbnail ? "thumbnail" : "cover"} photo`}
          body={
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  margin: `${space.MEDIUM} 0px`,
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <StyledSelect
                  value={this.getCropSizeForImage()}
                  size="medium"
                  label="Crop your image"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    this.onImageCropSizeChange(event.target.value)
                  }
                >
                  {Object.values(imageCropAspectRatios).map(
                    (aspectRatio, index) => {
                      return (
                        <StyledMenuItem value={aspectRatio.name} key={index}>
                          <Typography variant="bodym">
                            {aspectRatio.name}
                          </Typography>
                        </StyledMenuItem>
                      );
                    }
                  )}
                </StyledSelect>
              </div>

              {this.getCropSizeForImage() ===
              imageCropAspectRatios.ORIGINAL.name ? (
                <img
                  src={
                    cropEmailBanner
                      ? emailBanner
                      : uploadedImageURL
                      ? uploadedImageURL
                      : this.getDefaultCoverImage()
                  }
                  alt="Original size"
                  style={{
                    width: "100%",
                    height: "auto",
                    marginTop: space.SMALL
                  }}
                />
              ) : (
                <Container className={classes.cropContainer}>
                  <Cropper
                    image={
                      cropEmailBanner
                        ? emailBanner
                        : uploadedImageURL
                        ? uploadedImageURL
                        : this.getDefaultCoverImage()
                    }
                    crop={crop}
                    zoom={zoom}
                    aspect={this.getCropSizeForImage(true)}
                    onCropChange={this.onCropChange}
                    onZoomChange={this.onZoomChange}
                    onCropComplete={this.onCropComplete}
                  />
                </Container>
              )}
            </div>
          }
          successButtonName="Upload"
          successCallback={() => {
            this.getCropSizeForImage() === imageCropAspectRatios.ORIGINAL.name
              ? this.skipCrop()
              : this.toggleCropTool();
          }}
          cancelCallback={this.toggleCropTool}
        />

        <Dialog // can't change to styleddialog because it doesn't have title and dialog actions
          open={showPublishSuccess}
          onClose={this.closePublishSuccessDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}></Box>

              <Box>
                <IconButton onClick={this.closePublishSuccessDialog}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>

          <DialogContent className={classes.padding35}>
            <Suspense fallback={<div />}>
              <PublishSuccess
                post={post}
                scheduledDate={scheduledDate}
                poststatus={poststatus}
                postURL={postURL}
                alreadyPublished={alreadyPublished}
                isFreelancer={isFreelancer}
                isPublishedElsewhere={isPublishedElsewhere}
              />
            </Suspense>
          </DialogContent>
        </Dialog>

        <StyledDialog
          open={updateTopicTimeline}
          title="Update your timeline?"
          body={`You just requested a revision on this post. This post is set to be
          published at ${formatTimeWithTimeZoneForEventInfo(
            moment(post.scheduledOn)
          )}, which 
          ${
            getMinutesDifference(moment(post.scheduledOn)) > 0
              ? "is " +
                getTimeLeftInDaysHoursMinutes(
                  getMinutesDifference(moment(post.scheduledOn))
                ) +
                " from now."
              : " has passed."
          }`}
          successButtonName="Change deadlines"
          successCallback={this.openCalenderEvent}
          cancelButtonName="Skip"
          cancelCallback={this.toggleUpdateTopicTimeline}
        />

        <StyledDialog
          open={showDestinationMissingDialog}
          title="Set up a destination first"
          body="Connect to a frontend like Webflow, WordPress, HubSpot, etc so that we can push this content live to your site. You can find integration options in Settings."
          successButtonName="Go to Settings"
          successCallback={this.openSettings}
          cancelCallback={() => {
            this.setState({ showDestinationMissingDialog: false });
          }}
        />

        {showEmailPreviewDialog && (
          <EmailPreviewDialog
            postId={post._id}
            showPopup={showEmailPreviewDialog}
            coverImage={coverImageOriginal}
            emailBannerImage={croppedEmailBanner}
            closeDialog={this.closeDialog}
            sendPostTestEmail={this.sendPostTestEmail}
          />
        )}
        <StyledDialog
          open={showNewsletterPublishConfirmation}
          title={title}
          body={message}
          successButtonName={onSuccessButton}
          successCallback={() => this.schedule()}
          cancelCallback={this.toggleNewsletterPublishConfirmationDialog}
        />
        <StyledDialog
          open={showPublishConfirmation}
          title={title}
          body={
            <div style={{ width: "100%" }}>
              <div style={{ width: "100%" }}>
                <p>{startingStamement}</p>
                <p>{destinationIcon}</p>
                <p style={{ width: "100%", display: "flex" }}>
                  <span style={{ width: "50%" }}>{destinationName}</span>
                  <span
                    style={{ width: "50%", float: "right", textAlign: "right" }}
                  >
                    {formatForHistoryPanel(
                      scheduledDate ? scheduledDate : moment()
                    )}
                  </span>
                </p>
              </div>

              {this.getStatementDiv(
                socialMediaType.LINKED_IN,
                linkedInStatement
              )}
              {this.getStatementDiv(socialMediaType.TWITTER, tweetStatement)}
              {this.getStatementDiv(
                socialMediaType.FACEBOOK,
                facebookStatement
              )}
              {this.getStatementDiv(socialMediaType.REDDIT, redditStatement)}
              {this.getStatementDiv(socialMediaType.INSTAGRAM, instaStatement)}
              {this.getStatementDiv(socialMediaType.SLACK, slackStatement)}
              {this.getStatementDiv(socialMediaType.MEDIUM, mediumStatement)}

              <div style={{ width: "100%" }}>
                <>
                  <Divider style={{ width: "100%", marginBottom: 15 }} />
                  <Typography variant="bodym">{newsletterStatement}</Typography>
                </>
              </div>
            </div>
          }
          successButtonName={onSuccessButton}
          successCallback={() => this.schedule()}
          cancelCallback={this.toggleConfirmationDialog}
        />

        <StyledDialog
          open={rescheduleTopicTimeline}
          title="Update your timeline?"
          body={`You want to approve this post but the final draft was due on ${formatForDisplayDateTime(
            post.scheduledOn
          )}, which has passed.`}
          successButtonName="Change timeline"
          successCallback={() => {
            this.toggleRescheduleTopicTimeline();
            this.toggleScheduleDialog();
          }}
          cancelCallback={this.toggleRescheduleTopicTimeline}
        />

        <StyledDialog
          open={showOutlinePageConfirmation}
          title="You need to submit an outline first"
          body={
            "This post needs an outline to be submitted and approved first before submitting a draft. Let's take you to where you can submit your outline."
          }
          successButtonName="OK"
          successCallback={() => {
            this.redirectToContentOutline();
          }}
          cancelCallback={this.toggleOutlinePageConfirmation}
        />
      </Container>
    );
  }
}

export default withStyles(styles)(Review);
