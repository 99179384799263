// @ts-nocheck
import { grey } from "@mui/material/colors";
import colors from "../../Themes/Colors";
import { space } from "../../Config/theme";

const styles = (theme: any) => ({
  accountSection: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 50
  },

  cardTitle: {
    fontWeight: "900",
    fontSize: 16,
    fontFamily: "Poppins"
  },

  cardSubtitle: {
    color: colors.fontSecondary,
    marginBottom: space.MEDIUM,
    fontSize: 14,
    fontFamily: "Poppins"
  },

  input: {
    backgroundColor: colors.inputBackground,
    border: "none",
    marginTop: 0,
    padding: 0,
    width: "100%",
    color: colors.fontPrimary,
    "&:focus": {
      backgroundColor: colors.inputFocusBackground,
      border: "none",
      color: colors.fontPrimary
    }
  },

  mentionText: {
    color: colors.primary,
    textDecoration: "none"
  },

  postUrl: {
    backgroundColor: grey[100],
    color: colors.fontSecondary,
    borderRadius: 5,
    padding: 10,
    paddingRight: 3,
    fontFamily: "Inconsolata",
    width: "100%",
    minHeight: 50,
    fontSize: 18
  },

  avatar: {
    width: 30,
    height: 30
  },

  userName: {
    marginLeft: 10
  },

  selectedAccount: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    height: 20
  },

  imageIconContainer: {
    background: "white",
    padding: 0,
    marginTop: 10
  },

  closeIconContainer: {
    display: "flex",
    flexDirection: "row-reverse"
  },

  storyImageContainer: {
    display: "inline-flex",
    position: "relative",
    marginTop: 20,
    paddingRight: 20,
    width: 200,
    height: 200
  },

  storyImage: {
    width: "100%",
    objectFit: "cover"
  },

  closeIconImage: {
    position: "absolute",
    top: -15,
    right: 5,
    background: colors.primary,
    color: colors.white,
    padding: 4,
    "&:hover": {
      backgroundColor: colors.primary,
      color: colors.white
    }
  },

  errorIcon: {
    height: 18,
    width: 20,
    verticalAlign: "middle"
  },

  textAreaOutline: {
    width: "100%",
    resize: "vertical"
  },

  editableLabel: {
    color: colors.fontSecondary,
    fontSize: 14
  },

  editableLabelDiv: {
    padding: 20
  }
});

export default styles;
