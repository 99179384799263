// @ts-nocheck
import { ContextType } from "react";
import { clientPlanTypes } from "./Types";

export const features = {
  CUSTOM_DOMAIN: "custom_domain",
  DATA_ENRICHMENT: "data_enrichment",
  HOME_PAGE_CUSTOMIZATION: "home_page_customization",
  REFERRAL: "referral",
  POST_FEEDBACK: "post_feedback",
  AUDIO_POSTS: "audio_posts",
  WEBFLOW: "webflow",
  GHOST: "ghost",
  API_AND_WEBHOOK_ZAPIER: "api_and_webhook_zapier",
  REQUIRE_LOGIN_TO_VIEW: "require_login_to_view",
  HIDE_SITE: "hide_site",
  CUSTOM_HEADER_FOOTER: "custom_header_footer",
  TOPIC_BOARD: "topic_board",
  TOPIC_BOARD_SLACK_EMAIL: "topic_board_slack_email",
  AI_GENERATION: "ai_generation",
  CONTENT_ANALYZER: "content_analyer",
  SERP_RESEARCH: "SERP_RESEARCH",
  CAMPAIGNS: "campaigns",

  // SEO
  SEO_OPTIMIZER_AND_CONTENT_TUNING: "seo_optimizer_and_content_tuning",
  INTERNAL_LINK_BUILDER: "internal_link_builder",
  CONTENT_REFRESHING_MONITOR: "content_refreshing_monitor",
  PROGRAMMATIC_SEO: "programmatic_seo",
  KEYWORD_RESEARCH: "keyword_research",

  // Social Media
  SOCIAL_MEDIA_ACCOUNTS: "social_media_accounts",
  EMPLOYEE_ADVOCACY_FOR_LINKEDIN: "employee_advocacy_for_linkedin",

  //Sales Enablement
  GONG_INSIGHTS: "gong_insights",
  GONG_CONTENT_SUGGESTIONS: "gong_content_suggestions",

  //AI
  AI_CONTENT_GENERATION: "ai_content_generation",
  AI_BLOG_TO_SOCIAL: "ai_blog_to_social",
  AI_VIDEO_TO_TEXT: "ai_video_to_text",
  AI_WRITING_FRAMEWORKS: "ai_writing_frameworks",
  AI_STYLE_GUIDE: "ai_style_guide",

  //CMS
  BLOG_DESTINATIONS: "blog_destinations",
  IMPORT_BLOG_POSTS: "import_blog_posts",

  //Team
  TEAM_MEMBERS: "team_members",
  FREELANCERS: "freelancers"
};

export const importBlogPostSource = {
  WORDPRESS: "wordpress",
  WEBFLOW: "WEBFLOW"
};

const planFreeLimits = {
  [features.TEAM_MEMBERS]: 0,
  [features.SEO_OPTIMIZER_AND_CONTENT_TUNING]: 2,
  [features.OUTLINE_GENERATION]: 2,
  [features.SOCIAL_MEDIA_ACCOUNTS]: 1,
  [features.BLOG_DESTINATIONS]: 0,
  [features.FREELANCERS]: 0,
  [features.IMPORT_BLOG_POSTS]: [
    importBlogPostSource.WEBFLOW,
    importBlogPostSource.WORDPRESS
  ]
};

const plan1 = [
  features.SEO_OPTIMIZER_AND_CONTENT_TUNING,
  features.KEYWORD_RESEARCH,
  features.SOCIAL_MEDIA_ACCOUNTS,
  features.AI_CONTENT_GENERATION,
  features.AI_BLOG_TO_SOCIAL,
  features.BLOG_DESTINATIONS,
  features.IMPORT_BLOG_POSTS,
  features.TEAM_MEMBERS,
  features.FREELANCERS
];

const plan1Limits = {
  [features.TEAM_MEMBERS]: 1,
  [features.SEO_OPTIMIZER_AND_CONTENT_TUNING]: 4,
  [features.OUTLINE_GENERATION]: 4,
  [features.SOCIAL_MEDIA_ACCOUNTS]: 1,
  [features.BLOG_DESTINATIONS]: 1,
  [features.FREELANCERS]: 0,
  [features.IMPORT_BLOG_POSTS]: [
    importBlogPostSource.WEBFLOW,
    importBlogPostSource.WORDPRESS
  ]
};

const plan2 = [
  features.SEO_OPTIMIZER_AND_CONTENT_TUNING,
  features.INTERNAL_LINK_BUILDER,
  features.CONTENT_REFRESHING_MONITOR,
  features.PROGRAMMATIC_SEO,
  features.KEYWORD_RESEARCH,
  features.SOCIAL_MEDIA_ACCOUNTS,
  features.AI_CONTENT_GENERATION,
  features.AI_BLOG_TO_SOCIAL,
  features.AI_VIDEO_TO_TEXT,
  features.AI_WRITING_FRAMEWORKS,
  features.AI_STYLE_GUIDE,
  features.BLOG_DESTINATIONS,
  features.IMPORT_BLOG_POSTS,
  features.TEAM_MEMBERS,
  features.FREELANCERS
];

const plan2Limits = {
  [features.TEAM_MEMBERS]: 3,
  [features.SEO_OPTIMIZER_AND_CONTENT_TUNING]: 12,
  [features.OUTLINE_GENERATION]: 12,
  [features.SOCIAL_MEDIA_ACCOUNTS]: 5,
  [features.BLOG_DESTINATIONS]: 2,
  [features.FREELANCERS]: 5,
  [features.IMPORT_BLOG_POSTS]: [
    importBlogPostSource.WEBFLOW,
    importBlogPostSource.WORDPRESS
  ]
};

const plan3 = [
  features.CUSTOM_DOMAIN,
  features.HOME_PAGE_CUSTOMIZATION,
  features.POST_FEEDBACK,
  features.REFERRAL,
  features.DATA_ENRICHMENT,
  features.AUDIO_POSTS,
  features.WEBFLOW,
  features.API_AND_WEBHOOK_ZAPIER,
  features.HIDE_SITE,
  features.GHOST,
  features.REQUIRE_LOGIN_TO_VIEW,
  features.CUSTOM_HEADER_FOOTER,
  features.TOPIC_BOARD,
  features.AI_GENERATION,
  features.TOPIC_BOARD_SLACK_EMAIL,
  features.CONTENT_ANALYZER,
  features.SERP_RESEARCH,
  features.CAMPAIGNS,

  // New
  features.SEO_OPTIMIZER_AND_CONTENT_TUNING,
  features.INTERNAL_LINK_BUILDER,
  features.CONTENT_REFRESHING_MONITOR,
  features.PROGRAMMATIC_SEO,
  features.KEYWORD_RESEARCH,
  features.SOCIAL_MEDIA_ACCOUNTS,
  features.EMPLOYEE_ADVOCACY_FOR_LINKEDIN,
  features.GONG_INSIGHTS,
  features.GONG_CONTENT_SUGGESTIONS,
  features.AI_CONTENT_GENERATION,
  features.AI_BLOG_TO_SOCIAL,
  features.AI_VIDEO_TO_TEXT,
  features.AI_WRITING_FRAMEWORKS,
  features.AI_STYLE_GUIDE,
  features.BLOG_DESTINATIONS,
  features.IMPORT_BLOG_POSTS,
  features.TEAM_MEMBERS,
  features.FREELANCERS
];

const plan3Limits = {};

export function isLimitExceeded(
  feature: any,
  context: any,
  publicationId: any,
  currentUsage: any
) {
  try {
    let plan = getPublicationPlan(context, publicationId);

    if (plan === clientPlanTypes.FREE) {
      return planFreeLimits[feature] <= currentUsage;
    }

    if (plan === clientPlanTypes.PLAN1) {
      return plan1Limits[feature] <= currentUsage;
    }

    if (plan === clientPlanTypes.PLAN2) {
      return plan2Limits[feature] <= currentUsage;
    }

    if (plan === clientPlanTypes.PLAN3) {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
  // other plans
  return true;
}

export function getPublicationPlan(context: any, publicationId: any) {
  let publication = context?.publications?.find(
    (publication: any) => publication._id === publicationId
  );

  return publication?.plan || clientPlanTypes.FREE;
}

export function getFeatureLimit(
  feature: string,
  context: ContextType,
  publicationId: string
) {
  try {
    let plan = getPublicationPlan(context, publicationId);

    if (plan === clientPlanTypes.PLAN1) {
      return plan1Limits[feature];
    }

    if (plan === clientPlanTypes.PLAN2) {
      return plan2Limits[feature];
    }

    if (plan === clientPlanTypes.PLAN3) {
      return "";
    }
  } catch (error) {
    console.log(error);
  }
  // Free plan or other plans
  return 0;
}

export function hasAccess(feature: any, context: any, publicationId: any) {
  try {
    let plan = getPublicationPlan(context, publicationId);

    if (plan === clientPlanTypes.PLAN1) {
      return plan1.includes(feature);
    }

    if (plan === clientPlanTypes.PLAN2) {
      return plan2.includes(feature);
    }

    if (plan === clientPlanTypes.PLAN3) {
      return true;
    }
  } catch (error) {
    console.log(error);
  }

  return false;
}

export function isModuleDisabled(
  context: ContextType,
  publicationId: string,
  module: string
) {
  try {
    let publication = context?.publications?.find(
      (publication: any) => publication._id === publicationId
    );

    if (!publication) {
      return true;
    }

    return publication.disabledModules.includes(module);
  } catch (error) {
    console.log(error);
    return false;
  }
}
