// @ts-nocheck
import { StyledEngineProvider } from "@mui/material/styles";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import * as Sentry from "@sentry/browser";
import firebase from "firebase/app";
import posthog from "posthog-js";
import React, { Component, Suspense } from "react";
import ReactGA from "react-ga";

import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import { BrowserRouter, Switch } from "react-router-dom";

import AcceptTeamInvite from "./Components/AcceptTeamInvite";

import CustomRoute from "./Components/CustomRoute";

import DeviceCompatibilityBanner from "./Components/DeviceCompatibilityBanner";
import theme from "./Config/theme";

import KeywordResearch from "./Containers/KeywordResearch";

import Login from "./Containers/Login";

import Main from "./Main";
import API from "./Services/Api";
import { PageAccessContextProvider } from "./Utils/PageAccessContext";
import { LicenseInfo } from "@mui/x-license-pro";
import Loading from "./Components/Loading";

const CheckoutCallback = React.lazy(
  () => import("./Components/CheckoutCallback")
);

const CostCalculator = React.lazy(() => import("./Components/CostCalculator"));
const TwitterClientCallback = React.lazy(
  () => import("./Containers/TwitterClientCallback")
);

const RedditCallback = React.lazy(() => import("./Containers/RedditCallback"));

const TwitterCallback = React.lazy(
  () => import("./Containers/TwitterCallback")
);
const LinkedInCallback = React.lazy(
  () => import("./Containers/LinkedInCallback")
);

const Publications = React.lazy(() => import("./Containers/Publications"));
const OnboardingBusiness = React.lazy(
  () => import("./Containers/OnboardingBusiness")
);

const Profile = React.lazy(() => import("./Containers/Profile"));

const PaymentCallback = React.lazy(
  () => import("./Components/PaymentCallback")
);

const CompleteSignIn = React.lazy(() => import("./Containers/CompleteSignIn"));

const Unsubscribe = React.lazy(() => import("./Containers/Unsubscribe"));
const WebFlowCallback = React.lazy(
  () => import("./Containers/WebflowCallback")
);

const ConnectWebflow = React.lazy(() => import("./Containers/ConnectWebflow"));

const WebflowAuthCallback = React.lazy(
  () => import("./Containers/WebflowAuthCallback")
);

const LinkedinExternalConnectCallback = React.lazy(
  () => import("./Containers/LinkedinExternalConnectCallback")
);

const RecordSubjectQuote = React.lazy(
  () => import("./Containers/RecordSubjectQuote")
);

const ChatGPTSearchSetup = React.lazy(
  () => import("./Containers/ChatGPTSearchSetup")
);

const ProgrammaticSeo = React.lazy(
  () => import("./Containers/ProgrammaticSeo")
);

const SocialSellingDemo = React.lazy(
  () => import("./Containers/SocialSellingDemo")
);

const LinkedinExternalComment = React.lazy(
  () => import("./Containers/LinkedinExternalComment")
);

const ShopifyCallback = React.lazy(
  () => import("./Containers/ShopifyCallback")
);

const HubSpotCallback = React.lazy(
  () => import("./Containers/HubSpotCallback")
);

const SlackQAReview = React.lazy(() => import("./Containers/SlackQAReview"));

const GSCCallback = React.lazy(() => import("./Containers/GSCCallback"));
const GrainCallback = React.lazy(() => import("./Containers/GrainCallback"));
const ZoomCallback = React.lazy(() => import("./Containers/ZoomCallback"));

const GACallback = React.lazy(() => import("./Containers/GACallback"));

const YoutubeCallback = React.lazy(
  () => import("./Containers/YoutubeCallback")
);

const WordpressCallback = React.lazy(
  () => import("./Containers/WordpressCallback")
);

const SalesforceErrorCallback = React.lazy(
  () => import("./Containers/SalesforceErrorCallback")
);

const ProjectChecklist = React.lazy(
  () => import("./Containers/ProjectChecklist")
);

const FreelancerInvite = React.lazy(
  () => import("./Components/FreelancerInvite")
);

const DownloadExport = React.lazy(() => import("./Containers/DownloadExport"));
const DownloadRecipient = React.lazy(
  () => import("./Containers/DownloadRecipient")
);

const ApproveLinkedInPost = React.lazy(
  () => import("./Containers/ApproveLinkedInPost")
);

const ApprovePostAsTeammatePost = React.lazy(
  () => import("./Containers/ApprovePostAsTeammatePost")
);

const LinkedInShare = React.lazy(() => import("./Containers/LinkedInShare"));

const SalesInsightToSocial = React.lazy(
  () => import("./Containers/SalesInsightToSocial")
);

let GOOGLE_ANALYTICS_ID = "UA-134454960-16";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  GOOGLE_ANALYTICS_ID = "DEV_CODE";
}
if (process.env.NODE_ENV !== "development" && !process.env.REACT_APP_STAGING) {
  posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_POSTHOG_HOST
  });
}

ReactGA.initialize(GOOGLE_ANALYTICS_ID);

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_LICENSE);

const api = API.create();
class Root extends Component {
  constructor() {
    super();
    this.state = {
      authenticated: false,
      firebaseListenerRegistered: false,
      pageAccessContext: {
        refresh: this.refreshContextWithNewPublication,
        setContextData: this.setContextData
      }
    };
  }

  componentDidMount() {
    this.checkIfUserRegistered();
  }

  checkIfUserRegistered = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.getContextForPageAccess();
      } else {
        this.setState({
          firebaseListenerRegistered: true,
          authenticated: false
        });
      }
    });
  };

  setContextData = (pageAccess: any, callback: any) => {
    this.setState(
      {
        pageAccessContext: {
          ...pageAccess,
          refresh: this.refreshContextWithNewPublication,
          setContextData: this.setContextData
        }
      },
      callback
    );
  };

  refreshContextWithNewPublication = (publication: any, callback: any) => {
    let newContext = {
      ...this.state.pageAccessContext,
      refresh: this.refreshContextWithNewPublication,
      setContextData: this.setContextData
    };
    newContext[publication._id] = []; // the person who created is the admin so no restrictions
    if (!newContext.publications) {
      newContext.publications = [];
    }
    newContext.publications.push(publication);
    this.setState(
      {
        pageAccessContext: newContext
      },
      callback
    );
  };

  getContextForPageAccess = () => {
    api.getPageAccessDetails((res: any) => {
      if (res.status === 200) {
        this.setState({
          firebaseListenerRegistered: true,
          authenticated: true,
          pageAccessContext: {
            ...res.data,
            refresh: this.refreshContextWithNewPublication,
            setContextData: this.setContextData
          }
        });
      } else {
        this.setState({
          firebaseListenerRegistered: true,
          authenticated: true
        });
      }
    });
  };

  render() {
    if (!this.state.firebaseListenerRegistered) {
      return <Loading />;
    } else {
      return (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <PageAccessContextProvider value={this.state.pageAccessContext}>
              <ReactNotifications />

              <DeviceCompatibilityBanner />

              <BrowserRouter>
                <Suspense fallback={<div />}>
                  <Switch>
                    <CustomRoute exact path="/" />

                    <CustomRoute exact path="/login" component={Login} />

                    <CustomRoute
                      exact
                      path="/webflowcallback"
                      component={WebFlowCallback}
                      isProtected
                    />

                    <CustomRoute
                      exact
                      path="/connect-webflow"
                      component={ConnectWebflow}
                    />

                    <CustomRoute
                      exact
                      path="/webflow/authcallback"
                      component={WebflowAuthCallback}
                    />

                    <CustomRoute
                      exact
                      path="/chatgpt"
                      component={ChatGPTSearchSetup}
                    />

                    <CustomRoute
                      exact
                      path="/programmatic-seo"
                      component={ProgrammaticSeo}
                    />

                    <CustomRoute
                      exact
                      path="/social-selling-demo"
                      component={SocialSellingDemo}
                    />

                    <CustomRoute
                      exact
                      path="/:id/linkedin/comment/:linkedinPostId/:linkedinUserId/:linkedinCommentId?"
                      component={LinkedinExternalComment}
                    />

                    <CustomRoute
                      exact
                      path="/:id/linkedin/connect/external"
                      component={LinkedinExternalConnectCallback}
                    />

                    <CustomRoute
                      exact
                      path="/:id/quote/:subjectQuoteRequestId"
                      component={RecordSubjectQuote}
                    />

                    <CustomRoute
                      exact
                      path="/shopifycallback"
                      component={ShopifyCallback}
                      isProtected
                    />

                    <CustomRoute
                      exact
                      path="/twittercallback"
                      component={TwitterCallback}
                    />

                    <CustomRoute
                      exact
                      path="/twitterclientcallback"
                      component={TwitterClientCallback}
                    />

                    <CustomRoute
                      exact
                      path="/costcalculator"
                      component={CostCalculator}
                    />

                    <CustomRoute
                      exact
                      path="/completesignin"
                      component={CompleteSignIn}
                    />

                    <CustomRoute
                      exact
                      path="/unsubscribe/:id"
                      component={Unsubscribe}
                    />

                    <CustomRoute
                      exact
                      path="/redditcallback"
                      component={RedditCallback}
                      isProtected
                    />

                    <CustomRoute
                      exact
                      path="/checkoutcallback"
                      component={CheckoutCallback}
                      isProtected
                    />

                    <CustomRoute
                      exact
                      path="/workspaces"
                      component={Publications}
                      isProtected
                    />

                    <CustomRoute
                      exact
                      path="/project-checklist/:id"
                      component={ProjectChecklist}
                      isProtected
                    />

                    <CustomRoute
                      exact
                      path="/linkedincallback"
                      component={LinkedInCallback}
                    />
                    {/* <CustomRoute
                      exact
                      path="/onboarding"
                      component={Onboarding}
                      isProtected
                    /> */}

                    <CustomRoute
                      exact
                      path="/getstarted"
                      component={OnboardingBusiness}
                      isProtected
                    />

                    <CustomRoute
                      exact
                      path="/account"
                      component={Profile}
                      isProtected
                    />

                    <CustomRoute
                      exact
                      path="/paymentsuccess"
                      component={PaymentCallback}
                      isProtected
                    />

                    <CustomRoute
                      exact
                      path="/keywordresearch"
                      component={KeywordResearch}
                      isProtected
                    />

                    <CustomRoute
                      exact
                      path="/acceptteaminvite/:id"
                      component={AcceptTeamInvite}
                    />

                    <CustomRoute
                      exact
                      path="/freelancerinvite/:id"
                      component={FreelancerInvite}
                      isProtected
                    />

                    <CustomRoute
                      exact
                      path="/hubspotcallback"
                      component={HubSpotCallback}
                      isProtected
                    />

                    <CustomRoute
                      exact
                      path="/slackqareview/:id"
                      component={SlackQAReview}
                    />

                    <CustomRoute
                      exact
                      path="/gsccallback"
                      component={GSCCallback}
                      isProtected
                    />

                    <CustomRoute
                      exact
                      path="/auth/grain/callback/:id"
                      component={GrainCallback}
                      isProtected
                    />

                    <CustomRoute
                      exact
                      path="/zoom/callback"
                      component={ZoomCallback}
                      isProtected
                    />

                    <CustomRoute
                      exact
                      path="/youtubecallback"
                      component={YoutubeCallback}
                      isProtected
                    />
                    <CustomRoute
                      exact
                      path="/wordpresscallback"
                      component={WordpressCallback}
                      isProtected
                    />

                    <CustomRoute
                      exact
                      path="/gacallback"
                      component={GACallback}
                      isProtected
                    />

                    <CustomRoute
                      exact
                      path="/salesforceerror"
                      component={SalesforceErrorCallback}
                    />

                    <CustomRoute
                      exact
                      path="/downloadExport/:id"
                      component={DownloadExport}
                      isProtected
                    />

                    <CustomRoute
                      exact
                      path="/downloadrecipient/:id"
                      component={DownloadRecipient}
                      isProtected
                    />

                    <CustomRoute
                      exact
                      path="/approve/:id/:userId"
                      component={ApproveLinkedInPost}
                    />

                    <CustomRoute
                      exact
                      path="/post-as-teammate/:id/:userId"
                      component={ApprovePostAsTeammatePost}
                    />

                    <CustomRoute
                      path="/linkedin/:linkedinid/approve/:userId"
                      component={LinkedInShare}
                    />

                    <CustomRoute
                      path="/:publicationId/:callId/draft/:insightId/:linkedinPostId/:userId?"
                      component={SalesInsightToSocial}
                    />

                    <CustomRoute path="/agency" component={Main} isProtected />

                    <CustomRoute path="/:id" component={Main} />
                  </Switch>
                </Suspense>
              </BrowserRouter>
            </PageAccessContextProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      );
    }
  }
}

export default Root;
