// @ts-nocheck
import { Grid, Container, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import * as React from "react";
import StyledButton from "../design/components/StyledButton";

import StyledInput from "../design/components/StyledInput";
import Api from "../Services/Api";
import { notify } from "./CustomNotifications";
import styles from "./styles/GatsbyCloudSettingsStyle";

const api = Api.create();

class GatsbyCloudSettings extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {
      previewWebhook: props.publication.gatsby.previewWebhook,
      buildWebhook: props.publication.gatsby.buildWebhook,
      unsaved: []
    };
    let savedCopy = { ...this.state };

    this.state["savedCopy"] = savedCopy;
  }
  componentDidMount() {}

  handleWebhookChange = (event: any) => {
    let { savedCopy, unsaved } = this.state;
    let { name, value } = event.target;
    value = value.trim();
    if (savedCopy[name] !== undefined) {
      if (savedCopy[name] !== value) {
        unsaved[name] = value;
      } else if (savedCopy[name] === value) {
        delete unsaved[name];
      }
    }
    this.setState({ [name]: value, unsaved });
  };

  validWebhookUrl = (value: any) => {
    const GATSBY_WEBHOOK_REGEX =
      /^(https:\/\/webhook\.gatsbyjs\.com\/hooks\/data_source)([\/publish\/]*)([0-9a-z\-]+)$/gm;
    return GATSBY_WEBHOOK_REGEX.test(value);
  };

  saveGatsbyWebhooks = () => {
    let { publication } = this.props;

    let { previewWebhook, buildWebhook } = this.state;

    if (previewWebhook && !this.validWebhookUrl(previewWebhook)) {
      notify.show("enter a valid preview webhook url", "error");
      return false;
    }
    if (buildWebhook && !this.validWebhookUrl(buildWebhook)) {
      notify.show("enter a valid build webhook url", "error");
      return false;
    }

    api.saveGatsbyWebhooks(
      publication._id,
      { previewWebhook, buildWebhook },
      (res: any) => {
        if (res.status === 200) {
          let savedCopy = { ...this.state };
          this.setState({
            savedCopy,
            unsaved: []
          });
        }
        if (res.status === 400) {
          notify.show("Unable to save webhooks", "error");
        }
      }
    );
  };

  generateSaveButton = (key: any) => {
    let { classes } = this.props;
    return (
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        container
        justifyContent="flex-end"
        spacing={1}
        direction="row"
        className={classes.saveGrid}
      >
        <Grid item>
          <StyledButton onClick={this.saveGatsbyWebhooks}>Save</StyledButton>
        </Grid>

        <Grid item>
          <StyledButton
            variant="tertiary"
            color="secondary"
            onClick={() => this.revertKeyChange(key)}
          >
            Cancel
          </StyledButton>
        </Grid>
      </Grid>
    );
  };

  revertKeyChange = (key: any) => {
    let { savedCopy } = this.state;
    let change = {};
    for (let key in savedCopy) {
      change[key] = savedCopy[key];
    }

    change["unsaved"] = [];
    this.setState(change);
  };

  render() {
    let { classes } = this.props;

    let { previewWebhook, buildWebhook, unsaved } = this.state;
    return (
      <Container className={classes.container}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h400" paragraph>
              Gatsby Cloud Webhooks
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              variant="bodym"
              paragraph
              className={classes.sectionTitle}
            >
              Preview webhook
            </Typography>

            <Typography
              variant="bodym"
              paragraph
              className={classes.sectionSubtitle}
            >
              To trigger Gatsby preview builds when you update/publish any post
              in Letterdrop
            </Typography>

            <StyledInput
              size="medium"
              name="previewWebhook"
              className={classes.apiKey}
              placeholder="https://webhook.gatsbyjs.com/hooks/data_source/<site id>"
              onChange={this.handleWebhookChange}
              value={previewWebhook}
              fullWidth
            />
          </Grid>

          <Grid item>
            <Typography
              variant="bodym"
              paragraph
              className={classes.sectionTitle}
            >
              Build webhook
            </Typography>

            <Typography
              variant="bodym"
              paragraph
              className={classes.sectionSubtitle}
            >
              To trigger Gatsby production builds when you update/publish any
              post in Letterdrop
            </Typography>

            <StyledInput
              size="medium"
              name="buildWebhook"
              className={classes.apiKey}
              placeholder="https://webhook.gatsbyjs.com/hooks/data_source/publish/<site id>"
              onChange={this.handleWebhookChange}
              value={buildWebhook}
              fullWidth
            />
          </Grid>
        </Grid>
        {(unsaved["buildWebhook"] ||
          unsaved["buildWebhook"] === "" ||
          unsaved["previewWebhook"] ||
          unsaved["previewWebhook"] === "") &&
          this.generateSaveButton()}
      </Container>
    );
  }
}

export default withStyles(styles)(GatsbyCloudSettings);
