// @ts-nocheck
import CloseIcon from "@mui/icons-material/Close";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import ImageIcon from "@mui/icons-material/Image";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Avatar,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Popover,
  Tooltip,
  Typography
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { withStyles } from "@mui/styles";
import moment from "moment";
import React, { Fragment } from "react";

import CopyToClipboard from "react-copy-to-clipboard";
import { notify } from "../Components/CustomNotifications";

import ImagePicker from "../Components/ImagePicker";

import StyledChip from "../design/components/StyledChip";

import StyledDialog from "../design/components/StyledDialog";

import StyledInput from "../design/components/StyledInput";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import API from "../Services/SlackApi";
import POST_API from "../Services/Api";
import { uploadBlobImage } from "../Utils/Storage";
import { formatForTimeWithTimeZone } from "../Utils/TimeUtils";
import {
  URL_REPLACE_TEXT,
  datePickerDefault,
  generateSocialPostStatus,
  postStatus,
  socialMediaPublishTypes,
  socialMediaStatus,
  socialMediaType
} from "../Utils/Types";
import styles from "./styles/SlackShareMessageStyle";
import { hideOtherChatWidgets, showOtherChatWidgets } from "../Utils/UserUtils";

import CustomFlatPicker from "./CustomFlatPicker";
import { ReactComponent as AskAIIcon } from "../Images/icon24/ask-ai.svg";
import { faTerminal } from "@fortawesome/free-solid-svg-icons";
import { space } from "../Config/theme";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

import AIInstructionsDialog from "../Components/AIInstructionsDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colors from "../Themes/Colors";
import StyledButton from "../design/components/StyledButton";

const _ = require("lodash");
const api = API.create();
const postApi = POST_API.create();
const DEFAULT_INDEX = -1;

class SlackShareMessage extends React.Component {
  fetchSocialPost: any;
  imagePickerRef: any;
  publishTypeRef: any;
  searchSlackChannelDebounce: any;
  constructor(props: any) {
    super(props);
    this.state = {
      openImagePicker: false,
      scheduledDate: null,
      channelSearch: "",
      channels: [],
      refreshClicked: false,
      showConfirmationDialog: false,
      showDateTimePicker: false,
      prevSocialPostText: [],
      socialPostLoading: false,
      instructions: props.publication?.linkedInCustomPrompt || "",
      showInstructionDialog: false
    };
    this.publishTypeRef = React.createRef();
    this.imagePickerRef = React.createRef();
  }

  componentDidMount = () => {
    this.searchSlackChannelDebounce = _.debounce(
      this.searchSlackChannels,
      1000
    );
    setTimeout(() => {
      hideOtherChatWidgets();
    }, 3000);
  };

  componentWillUnmount() {
    showOtherChatWidgets();
    this.clearIntervalToFetchSocialPost();
  }

  handleAccountChange = (event: any) => {
    let { message, index } = this.props;
    message.account = event.target.value;

    this.props.updateMessageAtIndex(message, index);

    this.props.saveSlackMessageDebounce(message);
  };

  handleUserSearchChange = (event: any) => {
    this.setState(
      {
        [event.target.name]: event.target.value
      },
      this.searchSlackChannelDebounce
    );
  };

  searchSlackChannels = () => {
    let { channelSearch } = this.state;

    let { id, message, postId } = this.props;

    if (!message.account) {
      notify.show("Please select a slack workspace to search users", "error");
      return;
    }

    api.searchSlackChannels(
      id,
      message.account.teamId,
      channelSearch,
      postId,
      (res: any) => {
        if (res.status === 200) {
          this.setState({
            channels: res.data
          });
        }
      }
    );
  };

  handleChannelChanged = (event: any, value: any) => {
    let { message, index } = this.props;
    message.channels = value;

    this.props.updateMessageAtIndex(message, index);

    this.props.saveSlackMessageDebounce(message);
  };

  handleTextChange = (value: any) => {
    let { message, index } = this.props;
    message.text = value;

    this.props.updateMessageAtIndex(message, index);

    this.props.saveSlackMessageDebounce(message);
  };

  handlePublishTypeChange = (event: any, tweetIndex: any) => {
    let { message, index } = this.props;

    if (event.target.value === socialMediaPublishTypes.LATER) {
      // handled by onClick - handlePublishLater
      return;
    }

    message.scheduledOn = null;
    message.type = event.target.value;

    this.props.updateMessageAtIndex(message, index);

    this.props.saveSlackMessageDebounce(message);
  };

  handlePublishLater = () => {
    let { message } = this.props;
    this.setState({ showDateTimePicker: true });
  };

  onScheduleDateChange = (date: any) => {
    let { message, index } = this.props;
    message.scheduledOn = date;
    message.type = socialMediaPublishTypes.LATER;
    message.status = socialMediaStatus.SCHEDULED;

    this.props.updateMessageAtIndex(message, index);

    this.props.saveSlackMessageDebounce(message);
  };

  onImageSelected = (imageUrl: any) => {
    let { message, index } = this.props;
    message.images.push(imageUrl);

    this.props.updateMessageAtIndex(message, index);

    this.props.saveSlackMessageDebounce(message);
    this.toggleImagePicker();
  };

  onSelectFile = (file: any) => {
    this.toggleImagePicker();
    const IMAGE_SIZE_LIMIT = 5 * 1024 * 1024; // 5MB Limit for image
    const GIF_SIZE_LIMIT = 15 * 1024 * 1024; // 15MB Limit for image
    if (!file.type.includes("image")) {
      notify.show("Only images are allowed", "error");
    }
    if (file.type === "image/gif" && file.size > GIF_SIZE_LIMIT) {
      notify.show("Upload a gif image that's smaller than 15 MB", "error");
      return;
    }
    if (file.size > IMAGE_SIZE_LIMIT) {
      notify.show("Upload a image that's smaller than 5 MB", "error");
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      let imageUrl = "";
      if (
        reader.result?.startsWith("blob:") ||
        reader.result?.startsWith("data:")
      ) {
        imageUrl = await uploadBlobImage(
          reader.result,

          this.props.publication.domain
        );
      }
      if (!imageUrl) {
        return;
      }

      let { message, index } = this.props;
      message.images.push(imageUrl);

      this.props.updateMessageAtIndex(message, index);

      this.props.saveSlackMessageDebounce(message);
    });
    reader.readAsDataURL(file);
  };

  getScheduleDate = (scheduledDate: any) => {
    let formattedScheduledDate = "";
    if (scheduledDate) {
      formattedScheduledDate = formatForTimeWithTimeZone(moment(scheduledDate));
    }
    //Reason to use &nbsp; instead of space
    //If we use space its getting trimmed in dropdown menu
    return formattedScheduledDate ? (
      <>
        on&nbsp;<b>{formattedScheduledDate.split("at")[0]}</b>&nbsp;at&nbsp;
        <b>{formattedScheduledDate.split("at")[1]}</b>
      </>
    ) : (
      ""
    );
  };

  deleteSlackMessage = () => {
    let { index } = this.props;

    this.props.deleteSlackMessage(index);
  };

  toggleImagePicker = () => {
    let { openImagePicker } = this.state;
    this.setState({
      openImagePicker: !openImagePicker
    });
  };

  removeImage = (imageIndex: any) => {
    let { message, index } = this.props;
    message.images.splice(imageIndex, 1);

    this.props.updateMessageAtIndex(message, index);

    this.props.saveSlackMessageDebounce(message);
  };

  setIntervalToFetchSocialPost = () => {
    if (this.fetchSocialPost) {
      return;
    }
    this.generateSocialPost(true);

    this.fetchSocialPost = setInterval(() => {
      this.generateSocialPost(false);
    }, 5000);
  };

  clearIntervalToFetchSocialPost = () => {
    if (this.fetchSocialPost) {
      clearInterval(this.fetchSocialPost);
      delete this.fetchSocialPost;
    }
  };

  generateSocialPost = (regenerate: any) => {
    let { prevSocialPostText, instructions } = this.state;

    let { message, post, publication } = this.props;
    this.setState({
      socialPostLoading: true
    });
    postApi.generateSocialPostFromBlog(
      publication._id,
      post._id,
      post.htmlText,
      regenerate,
      socialMediaType.LINKED_IN,
      instructions,
      message?.userId || "",
      [], // samples
      (res: any) => {
        if (res.status === 200) {
          let socialPost = res.data.socialPost;
          if (socialPost?.status !== generateSocialPostStatus.COMPLETED) {
            return;
          }
          this.clearIntervalToFetchSocialPost();

          if (!socialPost?.body) {
            this.setState({
              socialPostLoading: false
            });
            return;
          }

          this.setState({
            prevSocialPostText: [...prevSocialPostText, message?.text || ""],
            socialPostText: socialPost.body,
            socialPostLoading: false
          });
          this.handleTextChange(socialPost.body);
        } else {
          this.setState({
            socialPostLoading: false
          });
          notify.show(
            res.status === 400
              ? res.data
              : "Oops, something went wrong. Try again.",
            "error"
          );
        }
      }
    );
  };

  refreshChannels = () => {
    let { message, id } = this.props;
    if (!message.account) {
      notify.show("Select slack workspace first", "error");
      return;
    }

    api.refreshChannel(id, message.account.teamId, (res: any) => {});
    notify.show(
      "Your request to refresh channel is being processed, results will appear soon",
      "info"
    );
    this.setState({
      refreshClicked: true
    });
  };

  toggleConfirmationDialog = () => {
    this.setState((prevState, props) => ({
      showConfirmationDialog: !prevState.showConfirmationDialog
    }));
  };

  handleImagePickerClose = () => {
    if (this.imagePickerRef?.checkImageUploading?.()) {
      return;
    }
    this.setState({
      openImagePicker: false
    });
  };

  showInstruction = (showInstructionDialog: any) => {
    this.setState({
      showInstructionDialog
    });
  };

  onSaveInstructions = (instructions: any) => {
    this.setState({
      instructions: instructions.trim(),
      showInstructionDialog: false
    });
  };

  render() {
    let {
      classes,

      isReviewScreen = false,

      workspaces,

      index: messageIndex,

      message,

      publication,

      postId
    } = this.props;
    let { images = [], scheduledOn, type } = message;
    let {
      openImagePicker,

      channels,

      channelSearch,

      refreshClicked,

      showConfirmationDialog,

      showDateTimePicker,

      socialPostLoading,

      showInstructionDialog,

      instructions
    } = this.state;

    let showAdditionalPublishType =
      message.post &&
      ((message.post.status && message.post.status !== postStatus.PUBLISHED) ||
        message.post.scheduledOn);

    return (
      <Fragment>
        <Fragment key={messageIndex}>
          <div container style={{ display: "flex", flexDirection: "column" }}>
            {messageIndex !== 0 && <Divider style={{ margin: 20 }} />}
            {message.status === socialMediaStatus.COMPLETED && (
              <div className={classes.editableLabelDiv}>
                <Typography className={classes.editableLabel}>
                  This post is published and can’t be updated
                </Typography>
              </div>
            )}
          </div>

          <Grid
            container
            direction="row"
            className={classes.section}
            style={{
              marginTop: messageIndex !== 0 ? 30 : 0,
              paddingRight: isReviewScreen ? "35px" : "0px"
            }}
          >
            <Grid id="account_and_editor" container item>
              <Grid
                container
                item
                id="Account"
                xs={12}
                className={classes.accountSection}
              >
                <Typography className={classes.cardTitle}>Workspace</Typography>

                <Typography className={classes.cardSubtitle}>
                  Which Slack Workspace do you want to post to?
                </Typography>

                <Grid item>
                  <FormControl
                    variant="outlined"
                    size="medium"
                    fullWidth
                    className={classes.input}
                  >
                    <StyledSelect
                      value={message.account}
                      onChange={this.handleAccountChange}
                      size="large"
                      disabled={message.status === socialMediaStatus.COMPLETED}
                      className={classes.input}
                      renderValue={() => {
                        return (
                          <div className={classes.selectedAccount}>
                            <Avatar
                              variant="circular"
                              src={message.account?.picture}
                              alt="Slack workspace picture"
                              className={classes.avatar}
                            />

                            <Typography className={classes.userName}>
                              {message.account?.name}
                              {", "}
                              {message.account?.workspaceName}
                            </Typography>
                          </div>
                        );
                      }}
                    >
                      {workspaces.map((workspace: any, index: any) => {
                        return (
                          <StyledMenuItem value={workspace} key={index}>
                            <Avatar
                              variant="circular"
                              src={workspace.picture}
                              alt="Slack workspace picture"
                              className={classes.avatar}
                            />

                            <Typography className={classes.userName}>
                              {workspace.name}
                              {", "}
                              {workspace.workspaceName}
                            </Typography>
                          </StyledMenuItem>
                        );
                      })}
                    </StyledSelect>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                item
                id="User"
                xs={12}
                className={classes.accountSection}
              >
                <Typography className={classes.cardTitle}>Channels</Typography>

                <Typography className={classes.cardSubtitle}>
                  Which channels do you want to post to?
                  {!refreshClicked && (
                    <Tooltip title="Not seeing your channels, Click here to refresh">
                      <IconButton
                        size="small"
                        onClick={this.refreshChannels}
                        style={{
                          marginLeft: 5
                        }}
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Typography>

                <Grid item>
                  <Autocomplete
                    id="users"
                    multiple
                    freeSolo
                    options={channels}
                    value={message.channels}
                    onChange={this.handleChannelChanged}
                    getOptionLabel={(option) => "#" + option.name}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <StyledChip
                          variant="gray"
                          label={`#${option.name}`}
                          key={index}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderOption={(props, option, { selected }) => {
                      return (
                        <div {...props}>
                          <Typography style={{ marginLeft: 10 }}>
                            #{option.name}
                          </Typography>
                        </div>
                      );
                    }}
                    renderInput={(params) => (
                      <StyledInput
                        {...params}
                        size="large"
                        placeholder="#general"
                        margin="normal"
                        variant="outlined"
                        name="channelSearch"
                        value={channelSearch || ""}
                        onChange={this.handleUserSearchChange}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} className={classes.accountSection}>
                <Typography className={classes.cardTitle}>Message</Typography>

                <Typography className={classes.cardSubtitle}>
                  You can{" "}
                  <a
                    href="https://api.slack.com/reference/surfaces/formatting#mentioning-users"
                    style={{
                      textDecoration: "none"
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className={classes.mentionText}>{" @mention "}</span>
                  </a>{" "}
                  usernames or the entire channel
                </Typography>

                <Grid item container direction="column">
                  <Grid container>
                    <Grid item style={{ width: "100%" }}>
                      <StyledInput
                        placeholder="We just published a blog post on stratchery"
                        multiline
                        disabled={
                          message.status === socialMediaStatus.COMPLETED
                        }
                        maxRows={8}
                        minRows={5}
                        name="text"
                        value={message.text}
                        onChange={(event: any) =>
                          this.handleTextChange(event.target.value)
                        }
                        className={classes.textAreaOutline}
                        onKeyDown={(e: any) => {
                          // if ctrl + z pressed
                          if (e.ctrlKey && e.keyCode === 90) {
                            let { prevSocialPostText } = this.state;
                            if (prevSocialPostText.length) {
                              let oldValue = prevSocialPostText.pop();
                              this.setState({
                                prevSocialPostText: prevSocialPostText
                              });
                              this.handleTextChange(oldValue);
                            }
                          }
                        }}
                      />
                    </Grid>
                    {isReviewScreen && (
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        {socialPostLoading ? (
                          <CircularProgress
                            style={{ marginLeft: 20, marginRight: -60 }}
                            size={25}
                          />
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginLeft: space.SMALL,
                              marginRight: -60
                            }}
                          >
                            <Tooltip title="Generate Social Post">
                              <IconButton
                                size="large"
                                onClick={() =>
                                  this.setIntervalToFetchSocialPost()
                                }
                              >
                                <AskAIIcon />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Instructions">
                              <IconButton
                                size="large"
                                style={{
                                  marginTop: space.XXS
                                }}
                                onClick={() => this.showInstruction(true)}
                              >
                                <FontAwesomeIcon
                                  icon={faTerminal}
                                  color={colors.iconColor}
                                  style={{ width: 24, height: 24 }}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )}
                      </Grid>
                    )}
                  </Grid>

                  <div>
                    {images.length < 4 &&
                      message.status !== socialMediaStatus.COMPLETED && (
                        <Grid item className={classes.imageIconContainer}>
                          <Tooltip title="Upload image">
                            <IconButton
                              onClick={this.toggleImagePicker}
                              size="large"
                            >
                              <ImageIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                  </div>

                  <Grid item>
                    {images.map((image: any, imageIndex: any) => {
                      //Displaying images if any exists
                      return (
                        <div
                          className={classes.storyImageContainer}
                          key={imageIndex}
                        >
                          <img
                            key={imageIndex}
                            alt="post pic"
                            src={image}
                            className={classes.storyImage}
                          />
                          {message.status !== socialMediaStatus.COMPLETED && (
                            <IconButton
                              className={classes.closeIconImage}
                              onClick={() => this.removeImage(imageIndex)}
                              size="large"
                            >
                              <CloseIcon />
                            </IconButton>
                          )}
                        </div>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>

              {message.postUrl && (
                <Grid
                  container
                  item
                  id="Post"
                  xs={12}
                  className={classes.accountSection}
                >
                  <Typography className={classes.cardTitle}>
                    Link to your post
                  </Typography>

                  <Typography className={classes.cardSubtitle}>
                    Copy this link to your Slack message, or use{" "}
                    {URL_REPLACE_TEXT}, and we'll replace it with the final URL.
                  </Typography>

                  <Grid item>
                    <InputBase
                      className={classes.postUrl}
                      value={message.postUrl + "?utm=" + message.utm}
                      multiline={true}
                      contentEditable={false}
                      onFocus={(event) => event.target.select()}
                      fullWidth
                      endAdornment={
                        <InputAdornment position="end">
                          <CopyToClipboard
                            onCopy={() =>
                              notify.show("Copied to clipboard", "success")
                            }
                            text={message.postUrl + "?utm=" + message.utm}
                          >
                            <IconButton size="large">
                              <FileCopyOutlinedIcon />
                            </IconButton>
                          </CopyToClipboard>
                        </InputAdornment>
                      }
                    />
                  </Grid>
                </Grid>
              )}

              <Grid
                container
                item
                xs={12}
                className={classes.accountSection}
                style={{ marginBottom: 20 }}
              >
                <Typography className={classes.cardTitle}>When</Typography>

                <Typography className={classes.cardSubtitle}>
                  Schedule when your Slack message should go out{" "}
                  {message.post && (
                    <Tooltip title="Scheduled time change will only reflect after post is updated">
                      <InfoIcon className={classes.errorIcon} />
                    </Tooltip>
                  )}
                </Typography>

                <Grid item>
                  <FormControl
                    variant="outlined"
                    size="medium"
                    fullWidth
                    className={classes.input}
                  >
                    <StyledSelect
                      value={type}
                      disabled={message.status === socialMediaStatus.COMPLETED}
                      onChange={this.handlePublishTypeChange}
                      size="large"
                      className={classes.input}
                    >
                      {!isReviewScreen && (
                        <StyledMenuItem value={socialMediaPublishTypes.NOW}>
                          Now
                        </StyledMenuItem>
                      )}

                      <StyledMenuItem
                        value={socialMediaPublishTypes.LATER}
                        onClick={this.handlePublishLater}
                      >
                        {type === socialMediaPublishTypes.LATER && scheduledOn
                          ? this.getScheduleDate(scheduledOn)
                          : "Later"}
                      </StyledMenuItem>
                      {message.post && (
                        <StyledMenuItem
                          value={socialMediaPublishTypes.WITH_POST}
                        >
                          Same time as the blog post
                        </StyledMenuItem>
                      )}
                      {(showAdditionalPublishType ||
                        type === socialMediaPublishTypes.TEN_MINUTES_LATER) && (
                        <StyledMenuItem
                          value={socialMediaPublishTypes.TEN_MINUTES_LATER}
                        >
                          10 minutes later
                        </StyledMenuItem>
                      )}
                      {(showAdditionalPublishType ||
                        type ===
                          socialMediaPublishTypes.THIRTY_MINUTES_LATER) && (
                        <StyledMenuItem
                          value={socialMediaPublishTypes.THIRTY_MINUTES_LATER}
                        >
                          30 minutes later
                        </StyledMenuItem>
                      )}
                      {(showAdditionalPublishType ||
                        type === socialMediaPublishTypes.HOUR_LATER) && (
                        <StyledMenuItem
                          value={socialMediaPublishTypes.HOUR_LATER}
                        >
                          1 hour later
                        </StyledMenuItem>
                      )}
                      {(showAdditionalPublishType ||
                        type === socialMediaPublishTypes.DAY_LATER) && (
                        <StyledMenuItem
                          value={socialMediaPublishTypes.DAY_LATER}
                        >
                          A day later
                        </StyledMenuItem>
                      )}
                    </StyledSelect>
                  </FormControl>
                </Grid>
              </Grid>

              {message.status !== socialMediaStatus.COMPLETED && (
                <Grid
                  container
                  style={{
                    marginBottom: 20,
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <StyledButton
                    variant="destructivesecondary"
                    onClick={this.toggleConfirmationDialog}
                    color="secondary"
                  >
                    Delete draft
                  </StyledButton>

                  <Tooltip title="Copy everywhere">
                    <IconButton
                      onClick={() =>
                        this.props.toggleShowCopyConfirmation(messageIndex)
                      }
                      size="large"
                    >
                      <ContentCopyOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Grid>

          <CustomFlatPicker
            publicationId={this.props.publication._id}
            open={showDateTimePicker}
            value={scheduledOn}
            disablePast={true}
            onClose={(date) => {
              this.setState(
                {
                  showDateTimePicker: false
                },

                this.onScheduleDateChange(date)
              );
            }}
            type={datePickerDefault.SOCIAL}
          />
        </Fragment>

        <Popover
          id="thread_image_picker"
          open={openImagePicker}
          anchorEl={<div />}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center"
          }}
          onClose={this.handleImagePickerClose}
        >
          <ImagePicker
            unsplashRef={(ref: any) => {
              this.imagePickerRef = ref;
            }}
            onImageSelect={this.onImageSelected}
            onImageUpload={this.onSelectFile}
            postPictures={[]}
            showUnsplashPicker={true}
          />
        </Popover>

        <StyledDialog
          open={this.props.showCopyConfirmation}
          title="Do you want to copy this to other accounts?"
          body="We'll overwrite the body, time, and other details of other accounts."
          closeCallback={() =>
            this.props.toggleShowCopyConfirmation(DEFAULT_INDEX)
          }
          cancelCallback={() =>
            this.props.toggleShowCopyConfirmation(DEFAULT_INDEX)
          }
          successCallback={() => this.props.copyEverywhere()}
          successButtonName="OK"
          maxWidth="sm"
          fullWidth
        />

        <StyledDialog
          open={showConfirmationDialog}
          title="Are you sure?"
          body="Once deleted, you can't recover your message."
          successButtonName="Yes, I'm sure"
          successCallback={this.deleteSlackMessage}
          cancelCallback={this.toggleConfirmationDialog}
        />

        {showInstructionDialog && (
          <AIInstructionsDialog
            open={showInstructionDialog}
            value={instructions}
            onSaveInstructions={this.onSaveInstructions}
            onClose={() => this.showInstruction(false)}
            publicationId={publication._id}
            postId={postId}
          />
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(SlackShareMessage);
