import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  Grid,
  Skeleton,
  Typography
} from "@mui/material";
import _ from "lodash";
import querystring from "query-string";
import React, { Component, Suspense, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { notify } from "../Components/CustomNotifications";
import HubSpotConnect from "../Components/HubSpotConnect";
import Loading from "../Components/Loading";
import { space } from "../Config/theme";
import StyledButton from "../design/components/StyledButton";
import StyledDialog from "../design/components/StyledDialog";
import StyledInput from "../design/components/StyledInput";
import { StyledMenuItem } from "../design/components/StyledSelect";
import API from "../Services/Api";
import colors from "../Themes/Colors";
import { hubspotConnectionTypes, socialMediaType } from "../Utils/Types";
import StyledSwitch from "../design/components/StyledSwitch";

const Page404 = React.lazy(() => import("./Page404"));

const api = API.create();

type MappingType = { [key: string]: { label: string; mapKey: string } };

export const defaultHubspotCrmContactsMapping: MappingType = {
  firstname: {
    label: "First Name",
    mapKey: "firstname"
  },
  lastname: {
    label: "Last Name",
    mapKey: "lastname"
  },
  jobTitle: {
    label: "Job Title",
    mapKey: "jobtitle"
  },
  linkedinUrl: {
    label: "LinkedIn URL",
    mapKey: "hs_linkedin_url"
  },
  vanityName: {
    label: "Vanity Name",
    mapKey: "ltrd_linkedin_vanity_name"
  },
  region: {
    label: "Location",
    mapKey: "city"
  },
  companyName: {
    label: "Company Name",
    mapKey: "company"
  },
  companySize: {
    label: "Company Size",
    mapKey: "company_size"
  },
  companyLocationName: {
    label: "Company Location",
    mapKey: "ltrd_company_location"
  },
  activities: {
    label: "LinkedIn Activities",
    mapKey: "ltrd_activities"
  },
  firstEngaged: {
    label: "First Engaged",
    mapKey: "hs_sa_first_engagement_date"
  },
  lastEngaged: {
    label: "Last Engaged",
    mapKey: "hs_last_sales_activity_timestamp"
  },
  lastEngagedPostRootUrl: {
    label: "Last Engaged Post",
    mapKey: "ltrd_last_engaged_post_url"
  }
};

type ClassProps = {} & RouteComponentProps<{ id: string }>;

type ClassState = {
  success: boolean;
  loading: boolean;
  error: boolean;
  errorMessage: string;
  publicationId: string;
  blogs: any[];
  hubspotId: string;
  isCRM: boolean;
  hubId: string;
  allContactsProperties: { name: string; label: string }[];
  loadingAllContactsProperties: boolean;
  linkedInActivityPush: boolean;
  mapping: MappingType;
  saveMappingError: string;
};

class HubSpotCallback extends Component<ClassProps, ClassState> {
  constructor(props: ClassProps) {
    super(props);
    this.state = {
      success: true,
      loading: false,
      error: false,
      errorMessage: "",
      publicationId: "",
      blogs: [],
      hubspotId: "",
      isCRM: false,
      hubId: "",
      allContactsProperties: [],
      loadingAllContactsProperties: false,
      linkedInActivityPush: true,
      mapping: _.cloneDeep(defaultHubspotCrmContactsMapping),
      saveMappingError: ""
    };
  }

  async componentDidMount() {
    let { code, state } = querystring.parse(this.props.location.search);
    const splittedState = String(state || "").split("_");
    const publicationId = splittedState[0];
    const isCRM = splittedState[1] === hubspotConnectionTypes.CRM;

    this.setState({ loading: true });
    api.createHubSpotAccessToken(
      String(code || ""),
      publicationId,
      isCRM,
      (res) => {
        if (res.status === 200) {
          let {
            blogs,
            accountId,
            hubspotId: hubId,
            allContactsProperties
          } = res.data;
          this.setState({
            blogs,
            loading: false,
            publicationId,
            hubspotId: accountId,
            isCRM,
            hubId,
            allContactsProperties: allContactsProperties || []
          });
          return;
        } else {
          setTimeout(() => {
            this.setState({
              success: false,
              loading: false,
              error: true,
              errorMessage:
                "Failed to connect to HubSpot. Contact support@letterdrop.com"
            });
          });
        }
      }
    );
  }

  handleClose = () => {
    let { publicationId } = this.state;
    const isCRM = false;
    api.disconnectAccount(
      publicationId,
      socialMediaType.HUBSPOT,
      "",
      isCRM,
      (res) => {}
    );
    this.openIntegrationSettings();
  };

  saveMapping = () => {
    const { publicationId, hubId, linkedInActivityPush, mapping } = this.state;

    const errMsg = verifyIntentAccountMapping(mapping);
    this.setState({ saveMappingError: errMsg });
    setTimeout(() => this.setState({ saveMappingError: "" }), 5000);
    if (errMsg) return;

    api.saveCRMcontactsConfigFromHubspot(
      publicationId,
      hubId,
      mapping,
      linkedInActivityPush,
      (res) => {
        if (res.status === 200) {
          this.openIntegrationSettings();
        } else {
          notify.show(
            "Failed to save Details. Please try again later.",
            "error"
          );
        }
      }
    );
  };

  refreshAllProperties = () => {
    const { publicationId, hubId } = this.state;
    if (!hubId) return;
    this.setState({ loadingAllContactsProperties: true });
    api.getAllContactsPropertiesHubspotCRM(publicationId, hubId, (res) => {
      this.setState({ loadingAllContactsProperties: false });
      const allContactsProperties = res.data?.allContactsProperties;
      if (allContactsProperties?.length) {
        this.setState({ allContactsProperties });
      } else {
        notify.show("Failed to fetch contact properties from Hubspot", "error");
      }
    });
  };

  openIntegrationSettings = () => {
    this.props.history.push(`/${this.state.publicationId}/integrationsettings`);
  };

  render() {
    let {
      loading,
      error,
      errorMessage,
      publicationId,
      blogs,
      hubspotId,
      isCRM,
      allContactsProperties,
      loadingAllContactsProperties,
      linkedInActivityPush,
      mapping,
      saveMappingError
    } = this.state;

    if (loading) {
      return <Loading />;
    }

    if (error) {
      return (
        <Suspense fallback={<div />}>
          <Page404 errorMessage={errorMessage} />
        </Suspense>
      );
    }

    if (isCRM) {
      return (
        <>
          <StyledDialog
            open={true}
            title="Set up your Hubspot CRM integration"
            body={
              <>
                <IntentAccountPropertiesMapping
                  mapping={mapping}
                  setMapping={(m) => this.setState({ mapping: m })}
                  allProperties={allContactsProperties}
                  refreshAllProperties={this.refreshAllProperties}
                  loadingAllProperties={loadingAllContactsProperties}
                  linkedInActivityPush={linkedInActivityPush}
                  setLinkedInActivityPush={(v) =>
                    this.setState({ linkedInActivityPush: v })
                  }
                  error={saveMappingError}
                />
              </>
            }
            successButtonName={"Save"}
            successCallback={this.saveMapping}
          />
        </>
      );
    }

    return (
      <div>
        <HubSpotConnect
          open={true}
          publicationId={publicationId}
          blogs={blogs}
          syncPosts={true}
          onClose={this.handleClose}
          onSuccess={this.openIntegrationSettings}
          hubspotId={hubspotId}
        />
      </div>
    );
  }
}

export default withRouter(HubSpotCallback);

export const IntentAccountPropertiesMapping: React.FC<{
  mapping: MappingType;
  setMapping: (mapping: MappingType) => void;
  allProperties: { name: string; label: string }[];
  refreshAllProperties: () => void;
  loadingAllProperties: boolean;
  linkedInActivityPush: boolean;
  setLinkedInActivityPush: (v: boolean) => void;
  error: string;
}> = ({
  mapping,
  setMapping,
  allProperties,
  refreshAllProperties,
  loadingAllProperties,
  linkedInActivityPush,
  setLinkedInActivityPush,
  error
}) => {
  const emptyProperty = { name: "", label: "Select a property" };

  return (
    <div>
      <Grid
        item
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="bodym">
          Push LinkedIn activity to Hubspot Contacts
        </Typography>
        <StyledSwitch
          checked={linkedInActivityPush}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setLinkedInActivityPush(e.target.checked)
          }
        />
      </Grid>

      <Divider />

      <Grid
        item
        display="flex"
        justifyContent="space-between"
        my={space.MEDIUM}
        alignItems="center"
      >
        <Typography variant="bodym">
          Map HubSpot contacts fields to Letterdrop outputs
        </Typography>

        <StyledButton
          variant="textprimary"
          startIcon={<RefreshIcon />}
          onClick={refreshAllProperties}
          disabled={loadingAllProperties}
        >
          Refresh fields
        </StyledButton>
      </Grid>

      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        {error && (
          <Typography variant="bodys" color={colors.failureRed}>
            *{error}
          </Typography>
        )}
      </Grid>

      <Grid
        item
        display="flex"
        justifyContent="space-between"
        my={space.SMALL}
        alignItems="center"
      >
        <Typography variant="h300">Letterdrop Fields</Typography>
        <Typography variant="h300">HubSpot Fields</Typography>
      </Grid>

      {loadingAllProperties ? (
        <>
          {Array.from({ length: 4 }).map(() => (
            <Grid
              item
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={space.SMALL}
            >
              <Skeleton width="50%" height={60} />
              <Skeleton width="50%" height={60} />
            </Grid>
          ))}
        </>
      ) : (
        <Grid item>
          {Object.keys(mapping).map((key) => {
            const { label, mapKey } = mapping[key];

            return (
              <Box
                key={key}
                display="flex"
                flexDirection="row"
                alignItems="center"
                marginBottom={space.MEDIUM}
              >
                <Typography
                  variant="body2"
                  style={{
                    marginRight: 10,
                    width: "40%",
                    minWidth: "40%"
                  }}
                >
                  {label}
                </Typography>

                <FormControl variant="outlined" size="small" fullWidth>
                  <Autocomplete
                    options={[emptyProperty, ...allProperties]}
                    value={
                      allProperties.find((p) => p.name === mapKey) ||
                      emptyProperty
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    disabled={key === "vanityName"}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, value) => {
                      const newMapping = _.cloneDeep(mapping);
                      newMapping[key].mapKey = value?.name || "";
                      setMapping(newMapping);
                    }}
                    size="medium"
                    fullWidth
                    renderOption={(params, option) => (
                      <StyledMenuItem {...params} key={option.name}>
                        {option.label}
                      </StyledMenuItem>
                    )}
                    renderInput={(params) => (
                      <StyledInput
                        {...params}
                        size="small"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </Box>
            );
          })}
        </Grid>
      )}
    </div>
  );
};

export const verifyIntentAccountMapping = (mapping: MappingType): string => {
  const emptyMapLabel: string[] = [];
  const usedMapping: { [mapKey: string]: string } = {};
  const duplicateMapLabel: string[] = [];

  Object.keys(mapping).forEach((key) => {
    const { label, mapKey } = mapping[key];

    if (!mapKey) {
      emptyMapLabel.push(label);
      return;
    }

    if (usedMapping[mapKey]) {
      duplicateMapLabel.push(usedMapping[mapKey], label);
      return;
    }

    usedMapping[mapKey] = label;
  });

  if (emptyMapLabel.length) {
    let msg = emptyMapLabel.slice(0, 4).join(", ");
    msg += " can't be empty";

    return msg;
  }

  if (duplicateMapLabel.length) {
    let msg = duplicateMapLabel.slice(0, 4).join(", ");
    msg += " have duplicate mapping";
    return msg;
  }

  return "";
};
