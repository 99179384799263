// @ts-nocheck
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  capitalize,
  Container,
  FormControl,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { withStyles } from "@mui/styles";
import React, { Component } from "react";
import StyledButton from "../design/components/StyledButton";

import StyledChip from "../design/components/StyledChip";

import StyledDialog from "../design/components/StyledDialog";

import StyledInput from "../design/components/StyledInput";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import API from "../Services/Api";
import Images from "../Themes/Images";
import { teamRoles } from "../Utils/Types";
import { handleSingular, isValidEmail } from "../Utils/UserUtils";
import { notify } from "./CustomNotifications";
import styles from "./styles/TeamManagementStyles";
import PageAccessContext from "../Utils/PageAccessContext";
import {
  features,
  getFeatureLimit,
  isLimitExceeded
} from "../Utils/AccessUtils";

import UpgradePlanDialog from "../design/components/UpgradePlanDialog";

const api = API.create();
const HELP_CENTER_ARTICLE =
  "https://letterdrop.notion.site/Invite-your-team-and-set-roles-a83ce7fce8994db0b68f5e4fb743ba8d";

const teammateRoles = {
  ADMIN: "admin",
  TEAMMATE: "teammate",
  WRITER: "writer",
  VIEWER: "viewer",
  AGENCY: "agency"
};

class TeamManagement extends Component {
  static contextType = PageAccessContext;
  constructor(props: any) {
    super(props);
    this.state = {
      publicationId: this.props.publication._id,
      teammates: [],
      openInviteTeammate: false,
      email: "",
      role: "teammate",
      showDeleteConfirmation: false,
      teammateIdToRemove: null,
      showChangeRoleDialog: false,
      teammateIndexToChangeRole: null,
      newRole: "",
      showUpgradeDialog: false
    };
  }

  componentDidMount = () => {
    this.loadTeammates();
  };

  loadTeammates = () => {
    let { publicationId } = this.state;
    api.getTeammates(publicationId, (res: any) => {
      if (res.status === 200) {
        this.setState({ teammates: res.data.teammates });
      } else {
        notify.show("Couldn't load your team", "error");
      }
    });
  };
  toggleInviteTeammate = () => {
    let { teammates, publicationId } = this.state;

    let teamLength = teammates.filter(
      (teammate: any) => teammate.role !== teamRoles.FREELANCER
    ).length;

    if (
      isLimitExceeded(
        features.TEAM_MEMBERS,
        this.context,
        publicationId,
        teamLength
      )
    ) {
      this.setState({ showUpgradeDialog: true });
      return;
    }

    this.setState({
      openInviteTeammate: !this.state.openInviteTeammate,
      email: ""
    });
  };

  hideInviteDialog = () => {
    this.setState({
      openInviteTeammate: false,
      email: ""
    });
  };

  handleChange = (event: any) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  sendInvite = () => {
    let { email, teammates } = this.state;

    for (let team of teammates) {
      if (team.email === email.trim()) {
        notify.show("They're already on your team", "error");
        return;
      }
    }

    if (!isValidEmail(email)) {
      notify.show("Enter a valid email address", "error");
      return;
    }

    this.sendTeamInvitation();
  };

  sendTeamInvitation = () => {
    let { publicationId, email, role } = this.state;
    api.sendTeamInvitation(publicationId, email, role, null, (res: any) => {
      if (res.status === 200) {
        notify.show(
          "We sent them an email invite. Ask them to check their inbox.",
          "success"
        );
        this.loadTeammates();
      } else {
        notify.show(
          res.status === 400
            ? res.data
            : "Oops, We couldn't send the invite. Please try again",
          "error"
        );
      }
      this.hideInviteDialog();
    });
  };

  resendInvite = (teamInvite: any) => {
    this.setState(
      { email: teamInvite.email, role: teamInvite.role },
      this.sendTeamInvitation
    );
  };

  showConfirmationToRemove = (teammateId: any) => {
    this.setState({
      showDeleteConfirmation: true,
      teammateIdToRemove: teammateId
    });
  };

  hideConfirmation = () => {
    this.setState({
      showDeleteConfirmation: false,
      teammateIdToRemove: null
    });
  };

  removeTeammate = () => {
    this.hideConfirmation();

    api.removeTeammate(
      this.state.publicationId,

      this.state.teammateIdToRemove,
      (res: any) => {
        if (res.status === 200) {
          this.setState({ teammates: res.data.teammates }, () =>
            notify.show("Say goodbye! Removed your teammate", "success")
          );
          this.loadTeammates();
        } else {
          if (res.data.message) {
            notify.show(res.data.message, "error");
            return;
          }
          notify.show("Oops, something went wrong", "error");
        }
      }
    );
  };

  changeTeammateRole = () => {
    let { teammates, teammateIndexToChangeRole, newRole } = this.state;

    let { publication } = this.props;

    let publicationClientId = publication.client?._id
      ? publication.client._id
      : publication.client;
    if (
      publicationClientId ===
        teammates[teammateIndexToChangeRole]?.client?._id &&
      newRole !== teammateRoles.ADMIN &&
      newRole !== teammateRoles.AGENCY
    ) {
      notify.show(
        "Contact support@letterdrop.com to change the role of the person who created this workspace",
        "error"
      );
      this.hideChangeRoleConfirmationDialog();
      return;
    }

    api.changeTeammateRole(
      publication._id,
      teammates[teammateIndexToChangeRole]._id,
      newRole,
      (res: any) => {
        if (res.status === 200) {
          notify.show(
            "We set your teammate's role to " + capitalize(newRole),
            "success"
          );
          teammates[teammateIndexToChangeRole].role = newRole;
          this.setState({
            teammates
          });
        } else {
          notify.show(
            res.data.message ? res.data.message : "Oops, something went wrong",
            "error"
          );
        }
        this.hideChangeRoleConfirmationDialog();
      }
    );
  };

  showChangeRoleConfirmationDialog = (
    teammateIndexToChangeRole: any,
    newRole: any
  ) => {
    this.setState({
      showChangeRoleDialog: true,
      teammateIndexToChangeRole,
      newRole
    });
  };

  hideChangeRoleConfirmationDialog = () => {
    this.setState({
      showChangeRoleDialog: false,
      teammateIndexToChangeRole: null,
      newRole: ""
    });
  };

  getUpgradeMessage = () => {
    let { publicationId } = this.state;
    let limit = getFeatureLimit(
      features.TEAM_MEMBERS,
      this.context,
      publicationId
    );

    if (limit === 0) {
      return `Upgrade your plan to invite teammates to your workspace.`;
    }

    return `Your workspace exceeded the limit of ${limit} teammate${handleSingular(
      limit
    )}. Upgrade to a higher plan to invite more teammates.`;
  };

  render() {
    let { classes } = this.props;
    let {
      openInviteTeammate,

      email,

      teammates,

      role,

      showDeleteConfirmation,

      showChangeRoleDialog,

      showUpgradeDialog
    } = this.state;
    return (
      <div>
        <Container style={{ padding: 0, paddingTop: 24 }}>
          <Typography variant="h400" paragraph>
            Team
          </Typography>

          <Grid container direction="column">
            {(teammates.length > 0 && (
              <TableContainer className={classes.tableContainer}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className={classes.headerCell}>
                        Name
                      </TableCell>

                      <TableCell align="left" className={classes.headerCell}>
                        Email
                      </TableCell>

                      <TableCell
                        align="left"
                        className={classes.headerCell}
                      ></TableCell>

                      <TableCell align="left" className={classes.headerCell}>
                        Role
                        <a
                          className={classes.infoLink}
                          href={HELP_CENTER_ARTICLE}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Tooltip title="What do these roles mean?">
                            <InfoOutlinedIcon
                              className={classes.infoIcon}
                              fontSize="small"
                            />
                          </Tooltip>
                        </a>
                      </TableCell>

                      <TableCell className={classes.headerCell}></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {teammates.map((value: any, index: any) => (
                      <TableRow key={index}>
                        <TableCell align="left" className={classes.teamCell}>
                          <Grid container direction="row" alignItems="center">
                            <Grid item>
                              <Avatar
                                className={classes.avatar}
                                alt={
                                  teammates[index].client
                                    ? teammates[index].client.name
                                    : "Invited teammate"
                                }
                                src={
                                  teammates[index].client
                                    ? teammates[index].client.profilePic
                                    : Images.user
                                }
                              />
                            </Grid>

                            <Grid item style={{ marginLeft: 15 }}>
                              {teammates[index].client
                                ? teammates[index].client.name
                                : "Invited teammate"}
                            </Grid>
                          </Grid>
                        </TableCell>

                        <TableCell align="left" className={classes.teamCell}>
                          <Grid container direction="column">
                            <Grid item>{teammates[index].email}</Grid>

                            <Grid item className={classes.chipContainer}>
                              {!teammates[index].createdOn && (
                                <StyledChip
                                  className={classes.inviteChip}
                                  variant="gray"
                                  label="Invite sent"
                                />
                              )}
                              {teammates[index].role ===
                                teamRoles.FREELANCER && (
                                <StyledChip
                                  className={classes.inviteChip}
                                  variant="gray"
                                  label="Temporary"
                                />
                              )}
                            </Grid>
                          </Grid>
                        </TableCell>

                        <TableCell align="left" className={classes.teamCell}>
                          {!teammates[index].createdOn && (
                            <StyledButton
                              variant="textsecondary"
                              onClick={() => {
                                this.resendInvite(teammates[index]);
                              }}
                            >
                              Resend
                            </StyledButton>
                          )}
                        </TableCell>

                        <TableCell align="left" className={classes.teamCell}>
                          <FormControl
                            variant="outlined"
                            size="small"
                            fullWidth
                          >
                            {(teammates[index].role ===
                              teamRoles.FREELANCER && (
                              <Typography style={{ paddingLeft: 15 }}>
                                {capitalize(teammates[index].role)}
                              </Typography>
                            )) || (
                              <StyledSelect
                                name="role"
                                value={teammates[index].role}
                                onChange={(event: any) =>
                                  this.showChangeRoleConfirmationDialog(
                                    index,
                                    event.target.value
                                  )
                                }
                              >
                                {Object.keys(teammateRoles).map((key) => {
                                  return (
                                    <StyledMenuItem
                                      value={teammateRoles[key]}
                                      key={teammateRoles[key]}
                                    >
                                      {capitalize(teammateRoles[key])}
                                    </StyledMenuItem>
                                  );
                                })}
                              </StyledSelect>
                            )}
                          </FormControl>
                        </TableCell>

                        <TableCell align="right" className={classes.teamCell}>
                          <Tooltip title="Remove teammate">
                            <IconButton
                              size="medium"
                              onClick={() => {
                                this.showConfirmationToRemove(
                                  teammates[index]._id
                                );
                              }}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )) || (
              <Grid item className={classes.noDataContainer}>
                <Typography>You haven't invited anyone to your team</Typography>
              </Grid>
            )}

            <Grid item>
              <Typography className={classes.cardTitle}>
                <StyledButton
                  variant="textprimary"
                  className={classes.marginTop20}
                  startIcon={<AddCircleIcon />}
                  onClick={this.toggleInviteTeammate}
                >
                  Add Teammate
                </StyledButton>
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <StyledDialog
          open={openInviteTeammate}
          title="Invite a teammate"
          body={
            <Grid
              container
              direction="row"
              className={classes.addTeamContainer}
              spacing={1}
            >
              <Grid item xs={2}>
                <Typography
                  variant="bodym"
                  paragraph
                  className={classes.marginTop12}
                >
                  Email
                </Typography>
              </Grid>

              <Grid item xs={9}>
                <StyledInput
                  autoFocus
                  id="outlined-dense"
                  value={email}
                  size="medium"
                  name="email"
                  onChange={this.handleChange}
                  InputProps={{
                    classes: {
                      input: classes.codeFont
                    }
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={2} className={classes.margin10}>
                <Typography
                  variant="bodym"
                  paragraph
                  className={classes.marginTop12}
                >
                  Role
                </Typography>
              </Grid>

              <Grid item xs={9} className={classes.margin10}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <StyledSelect
                    name="role"
                    value={role}
                    onChange={this.handleChange}
                  >
                    {Object.keys(teammateRoles).map((key) => {
                      return (
                        <StyledMenuItem
                          value={teammateRoles[key]}
                          key={teammateRoles[key]}
                        >
                          {capitalize(teammateRoles[key])}
                        </StyledMenuItem>
                      );
                    })}
                  </StyledSelect>
                </FormControl>
              </Grid>
            </Grid>
          }
          successButtonName="Invite"
          successCallback={this.sendInvite}
          cancelCallback={this.toggleInviteTeammate}
        />

        <StyledDialog
          open={showDeleteConfirmation}
          title="Are you sure?"
          body="They'll lose access to this team once removed."
          successButtonName="Yes, I'm sure"
          successCallback={this.removeTeammate}
          cancelCallback={this.hideConfirmation}
        />

        <StyledDialog
          open={showChangeRoleDialog}
          title="Are you sure?"
          body={
            <>
              {" "}
              You're about to change this user's role. This affects what they
              can access and do. For more information on the different roles,
              visit this{" "}
              <a
                href={HELP_CENTER_ARTICLE}
                target="_blank"
                rel="noreferrer"
                className={classes.articleLink}
              >
                help center article
              </a>
              .
            </>
          }
          successButtonName="Yes, I'm sure"
          successCallback={this.changeTeammateRole}
          cancelCallback={this.hideChangeRoleConfirmationDialog}
        />
        {showUpgradeDialog && (
          <UpgradePlanDialog
            open={showUpgradeDialog}
            body={this.getUpgradeMessage()}
            cancelCallback={() => {
              this.setState({ showUpgradeDialog: false });
            }}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(TeamManagement);
