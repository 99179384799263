// @ts-nocheck
import { grey } from "@mui/material/colors";
import colors from "../../Themes/Colors";
import { space } from "../../Config/theme";

const styles = (theme: any) => ({
  paper: {
    backgroundColor: colors.card,
    color: colors.fontPrimary,
    borderRadius: 10,
    boxShadow: "none",
    marginTop: space.L
  },

  reducedPaperMargin: {
    boxShadow: "none"
  },

  headerTitle: {
    width: "100%",
    marginBottom: space,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  },

  header: {
    width: "100%",
    marginBottom: space.MEDIUM,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  },

  draftDiv: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: space.SMALL
    }
  },

  slackDraft: {
    position: "absolute"
  },

  shareToSlack: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: space.SMALL
    }
  },

  shareToSlackButton: {
    position: "absolute",
    right: 5,
    background: colors.primary,
    color: colors.white
  },

  headerText: {
    fontWeight: "bold",
    color: colors.black,
    marginLeft: space.SMALL
  },

  anotherAccount: {
    padding: "20px 0px"
  },

  connectAccountSection: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "50px 20px",
    flexDirection: "column"
  },

  connectAccountText: {
    color: colors.fontSecondary
  },

  connectAccountImage: {
    width: 250,
    height: 250,
    marginBottom: 50
  }
});

export default styles;
