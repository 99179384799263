// @ts-nocheck
import { grey } from "@mui/material/colors";
import colors from "../../Themes/Colors";

const styles = (theme: any) => ({
  container: {
  padding: 0
},

  sectionTitle: {
    marginTop: 10
  },

  sectionSubtitle: {
    color: colors.fontSecondary
  },

  apiKey: {
    borderRadius: 5,
    fontFamily: "Inconsolata",
    width: "90%",
    marginTop: 10,
    marginBottom: 20
  },

  saveGrid: {
    marginTop: 10
  }
});
export default styles;
