// @ts-nocheck
import {
  CircularProgress,
  Container,
  Grid,
  Paper,
  Divider,
  Typography
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { withStyles } from "@mui/styles";
import React from "react";
import { notify } from "../Components/CustomNotifications";

import Loading from "../Components/Loading";

import SlackShareMessage from "../Components/SlackShareMessage";
import API from "../Services/SlackApi";
import colors, { designColors } from "../Themes/Colors";
import ImagesList from "../Themes/Images";
import styles from "./styles/SlackShareStyle";
import firebase from "firebase/app";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PageAccessContext from "../Utils/PageAccessContext";
import {
  getQueryParamsFromURL,
  getScheduleDateFromURL,
  handleSingular,
  isAccessDisabled
} from "../Utils/UserUtils";
import clsx from "clsx";

import { Helmet } from "react-helmet";
import StyledButton from "../design/components/StyledButton";

import StyledDialog from "../design/components/StyledDialog";
import { pageAccess, socialMediaStatus } from "../Utils/Types";

const Page404 = React.lazy(() => import("../Containers/Page404"));

const _ = require("lodash");
const api = API.create();

class SlackShare extends React.Component {
  static contextType = PageAccessContext;
  saveSlackMessageDebounce: any;
  constructor(props: any) {
    super(props);
    this.state = {
      id:
        props.match?.params?.slackid || props.match?.params?.id || props.postId,
      slackMessageId: null,
      loading: true,
      error: false,
      errorMessage: "",
      messages: [],
      workspaces: null,
      publication: null,
      shareInitialized: false,
      confirmPosting: false,
      backpage: getQueryParamsFromURL(window.location.search, "slack/drafts")
        .backpage,
      agencyView: getQueryParamsFromURL(window.location.search, "posts")
        .agencyView,
      scheduledOn: getScheduleDateFromURL(window.location.search, null),
      draftsCount: 0,
      socialPostText: "",
      showCopyConfirmation: false,
      copyIndex: -1
    };
  }

  componentDidMount = () => {
    this.load();
    this.saveSlackMessageDebounce = _.debounce(this.saveSlackMessage, 1000);
  };

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (this.props.match?.params.id !== prevProps.match?.params.id) {
      this.setState(
        {
          id: this.props.match?.params?.id || this.props.postId,
          slackMessageId: null,
          loading: true,
          error: false,
          errorMessage: "",
          messages: [],
          workspaces: null,
          publication: null,
          shareInitialized: false,
          confirmPosting: false,
          backpage: getQueryParamsFromURL(
            window.location.search,
            "slack/drafts"
          ).backpage,
          agencyView: getQueryParamsFromURL(window.location.search, "posts")
            .agencyView,
          scheduledOn: getScheduleDateFromURL(window.location.search, null)
        },
        this.load
      );
    }
  }

  load = () => {
    let { id, scheduledOn, socialPostText } = this.state;
    api.getSlackDetails(id, scheduledOn, socialPostText, (res: any) => {
      if (res.status === 200) {
        let { messages, publication, workspaces, slackMessageId, draftsCount } =
          res.data;

        this.setState({
          loading: false,
          error: false,
          publication: publication,
          workspaces: workspaces,
          messages,
          slackMessageId,
          draftsCount
        });
      } else {
        this.setState({
          loading: false,
          error: true,
          errorMessage: res.data
        });
      }
    });
  };

  connectToSlackCommunities = () => {
    let { publication, scheduledOn } = this.state;

    let { isReviewScreen, postId } = this.props;
    api.install(
      publication._id,
      false,
      isReviewScreen ? postId : "slack",
      { scheduledOn: scheduledOn },
      (res: any) => {
        if (res.status === 200) {
          window.location.href = res.data;
        } else {
          notify.show(
            "Something went wrong. Please contact support@letterdrop.com",
            "error"
          );
        }
      }
    );
  };

  postToSlack = () => {
    let { messages, publication } = this.state;

    this.toggleConfirmDialog();
    for (let message of messages) {
      if (!message.account) {
        notify.show("One or more message account is not selected", "error");
        return;
      }

      if (message.channels.length === 0) {
        notify.show("One ore more message channel is not selected", "error");
        return;
      }

      if (!message.text.trim()) {
        notify.show("One ore more message is not typed", "error");
        return;
      }
    }

    let data = {
      messages,
      publicationId: publication._id
    };

    // if validated send the messages to backend in one go.
    this.setState(
      {
        shareInitialized: true
      },
      () => {
        api.postToSlack(data, (res: any) => {
          if (res.status === 200) {
            let slackMessageResponses = res.data;
            let hasScheduled = false;
            for (let slackMessageResponse of slackMessageResponses) {
              if (slackMessageResponse?.scheduledOn && !hasScheduled) {
                hasScheduled = true;
              }
              if (slackMessageResponse?.slackMessageURL)
                for (let key in slackMessageResponse.slackMessageURL) {
                  setTimeout(
                    window.open(slackMessageResponse.slackMessageURL[key]),
                    1000
                  );
                }
            }
            if (hasScheduled) {
              notify.show("Your Slack message was scheduled", "success");
            }
            this.gotoBackPageORClearFields(slackMessageResponses);
          } else if (res.status === 400) {
            notify.show(res.data, "error");
          }
          this.setState({
            shareInitialized: false
          });
        });
      }
    );
  };

  gotoBackPageORClearFields = (slackMessageResponses = []) => {
    let { isReviewScreen } = this.props;

    let { publication, backpage, slackMessageId, agencyView } = this.state;

    if (agencyView) {
      window.location.href = `/${backpage}`;
      return;
    }

    let scheduledPosts = slackMessageResponses.filter(
      (message) => message.scheduledOn
    );

    if (scheduledPosts[0]?.messageId && !isReviewScreen) {
      let toUrl = `/${publication._id}/calendar/?mediaId=${
        scheduledPosts[0].messageId
      }&createdOn=${new Date(scheduledPosts[0].scheduledOn).toISOString()}`;

      this.props.history.push(toUrl);
      return;
    }

    if (slackMessageId) {
      this.props.history.push(`/${publication._id}/${backpage}`);
      return;
    }

    this.setState({ shareInitialized: false }, this.load);
  };

  handlePostToAnotherAccount = () => {
    let { messages } = this.state;

    let { isReviewScreen, postId } = this.props;
    if (isReviewScreen) {
      // get it from database to get id from backend to store without duplicates in database
      let newMessage = null;
      if (messages.length > 0 && messages[0]) {
        let lastPostIndex = messages.length - 1; //Fetch details from last post
        let { images, text, type, scheduledOn, channels } =
          messages[lastPostIndex];
        newMessage = {
          _id: null,
          images,
          text,
          type,
          scheduledOn,
          channels
        };
      }
      api.getNewSlackMessageForPost(postId, newMessage, (res: any) => {
        if (res.status === 200) {
          messages.push(res.data);
          this.setState({
            messages
          });
        }
      });
    } else {
      let newMessage = { ...messages[0], channels: [], account: null };
      messages.push(newMessage);
      this.setState({
        messages
      });
    }
  };

  updateMessageAtIndex = (message: any, index: any) => {
    let { messages } = this.state;
    let oldMessageText = messages[index]?.text;
    //stop AI post generation if user types
    if (oldMessageText !== message?.text) {
      messages[index].socialPostLoading = false;
    }
    messages[index] = message;
    this.setState({
      messages
    });
  };

  toggleConfirmDialog = () => {
    let { confirmPosting, publication } = this.state;
    if (!confirmPosting) {
      let isPublishAccessDisabled = isAccessDisabled(
        publication._id,
        this.context,
        pageAccess.PUBLISH_POST
      );
      if (isPublishAccessDisabled) {
        notify.show("You don’t have the right permissions to publish", "error");
        return;
      }
    }
    this.setState({
      confirmPosting: !confirmPosting
    });
  };

  deleteSlackMessage = (index: any) => {
    let { messages, slackMessageId, backpage, publication } = this.state;

    let { isReviewScreen, postId } = this.props;
    if (isReviewScreen) {
      let id = messages[index]._id;
      api.deleteSlackMessageForPost(id, postId, (res: any) => {
        if (res.status === 200) {
          messages.splice(index, 1);
          this.setState({
            messages
          });
          if (slackMessageId) {
            this.props.history.push(`/${publication._id}/${backpage}`);
          }
          return;
        }
        notify.show(
          res.status === 400 ? res.data : "Oops, something went wrong.",
          "error"
        );
      });
    } else {
      messages.splice(index, 1);
      this.setState({
        messages
      });
    }
  };
  saveSlackMessage = (message: any) => {
    // save to backend

    api.saveSlackMessageDetails(message, (res: any) => {
      if (res.status === 200) {
        console.log("Saved");
      }
    });
  };
  copyEverywhere = () => {
    let { messages, copyIndex } = this.state;
    if (!messages[copyIndex]) {
      this.toggleShowCopyConfirmation(-1);
      return;
    }
    for (let index = 0; index < messages.length; index++) {
      if (messages[index].status !== socialMediaStatus.COMPLETED) {
        messages[index].text = messages[copyIndex].text;
        messages[index].images = messages[copyIndex].images;
        messages[index].type = messages[copyIndex].type;
        messages[index].scheduledOn = messages[copyIndex].scheduledOn;
        this.setState({ messages }, () =>
          this.saveSlackMessage(messages[index])
        );
      }
    }

    this.toggleShowCopyConfirmation(-1);
  };

  toggleShowCopyConfirmation = (index: any) => {
    let { showCopyConfirmation } = this.state;
    this.setState({
      showCopyConfirmation: !showCopyConfirmation,
      copyIndex: index
    });
  };

  render() {
    let { classes, isReviewScreen = false, postId, post } = this.props;
    let {
      loading,

      error,

      errorMessage,

      publication,

      messages,

      shareInitialized,

      workspaces,

      id,

      confirmPosting,

      slackMessageId,

      scheduledOn,

      draftsCount,

      showCopyConfirmation
    } = this.state;

    if (loading) {
      return <Loading />;
    }

    if (error) {
      return <Page404 errorMessage={errorMessage} />;
    }

    return (
      <Container
        style={{
          padding: isReviewScreen ? 0 : "24px 24px 100px 24px"
        }}
      >
        {!isReviewScreen && (
          <>
            <Helmet>
              <title>
                Slack {publication && "- " + publication.name} | Letterdrop
              </title>
            </Helmet>
          </>
        )}

        <Paper
          className={clsx(
            isReviewScreen ? classes.paper : classes.reducedPaperMargin
          )}
        >
          {!isReviewScreen && (
            <>
              <div className={classes.headerTitle}>
                <img
                  src="/resources/images/slack.svg"
                  style={{ width: 24, height: 24 }}
                  alt="slack svg"
                />

                <Typography variant="h500" className={classes.headerText}>
                  Create a Slack Message
                </Typography>
              </div>

              <div className={classes.header}>
                {workspaces && workspaces.length > 0 && (
                  <>
                    <div className={classes.draftDiv}>
                      <StyledButton
                        className={classes.slackDraft}
                        variant="textsecondary"
                        size="small"
                        onClick={() => {
                          this.props.history.push(
                            `/${publication._id}/slack/drafts`
                          );
                        }}
                      >
                        Drafts{" "}
                        <Typography
                          variant="h100"
                          style={{
                            marginLeft: 5,
                            color: designColors.grayScale[80]
                          }}
                        >
                          {draftsCount}
                        </Typography>
                      </StyledButton>
                    </div>

                    <div className={classes.shareToSlack}>
                      <StyledButton
                        onClick={this.toggleConfirmDialog}
                        className={classes.shareToSlackButton}
                        startIcon={
                          shareInitialized ? "" : <SendIcon size="small" />
                        }
                      >
                        {shareInitialized && (
                          <CircularProgress
                            size={20}
                            color="inherit"
                            style={{ marginRight: 10 }}
                          />
                        )}
                        {shareInitialized ? "Posting..." : "Post to Slack"}
                      </StyledButton>
                    </div>
                  </>
                )}
              </div>
            </>
          )}

          {(!workspaces || !workspaces.length) && (
            <Grid
              container
              direction="row"
              className={classes.connectAccountSection}
            >
              <img
                src={ImagesList.socialMedia}
                className={classes.connectAccountImage}
                alt="Connect slack workspace"
              />

              <Typography className={classes.connectAccountText}>
                Connect a slack workspace to start posting
              </Typography>

              <StyledButton
                onClick={this.connectToSlackCommunities}
                style={{
                  background: colors.queryBuilderBackgroundColor,
                  marginTop: 25,
                  color: colors.black
                }}
                disabled={firebase.auth().currentUser ? false : true}
                startIcon={
                  <img
                    src="/resources/images/slack.svg"
                    style={{ width: 22, height: 22 }}
                    alt="slack-icon"
                  />
                }
              >
                Connect to Slack
              </StyledButton>
            </Grid>
          )}

          {workspaces.length > 0 &&
            messages.length > 0 &&
            messages.map((message: any, index: any) => {
              return (
                <SlackShareMessage
                  id={id}
                  message={message}
                  isReviewScreen={isReviewScreen}
                  publication={publication}
                  index={index}
                  key={index}
                  postId={postId}
                  post={post}
                  workspaces={workspaces}
                  updateMessageAtIndex={this.updateMessageAtIndex}
                  deleteSlackMessage={this.deleteSlackMessage}
                  scheduledOn={scheduledOn}
                  saveSlackMessageDebounce={this.saveSlackMessageDebounce}
                  toggleShowCopyConfirmation={this.toggleShowCopyConfirmation}
                  copyEverywhere={this.copyEverywhere}
                  showCopyConfirmation={showCopyConfirmation}
                />
              );
            })}

          <Divider />
          {workspaces && workspaces.length > 0 && !slackMessageId && (
            <Grid
              id="add_another_account"
              container
              justifyContent="space-between"
              className={classes.anotherAccount}
            >
              <StyledButton
                variant="secondary"
                onClick={this.handlePostToAnotherAccount}
                startIcon={<AddCircleIcon />}
                style={{ marginTop: 20 }}
              >
                Post another message
              </StyledButton>
            </Grid>
          )}
        </Paper>

        <StyledDialog
          open={confirmPosting}
          title="Are you sure?"
          body={`${messages.length} message${handleSingular(
            messages.length
          )} will be posted or scheduled ?`}
          successButtonName="Yes, I'm sure"
          successCallback={this.postToSlack}
          cancelCallback={this.toggleConfirmDialog}
        />
      </Container>
    );
  }
}

export default withStyles(styles)(SlackShare);
