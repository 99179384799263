// @ts-nocheck
import React, { Component, Suspense } from "react";

import Loading from "../Components/Loading";
import API from "../Services/Api";
import qs from "query-string";
import firebase from "firebase";
import { PROFILE_PIC, teamRoles } from "../Utils/Types";
import PageAccessContext from "../Utils/PageAccessContext";
import { setItemInLocalStorage } from "../Utils/StorageHelper";
import { Container } from "@mui/system";
import { Typography } from "@mui/material";

const api = API.create();

const Page404 = React.lazy(() => import("../Containers/Page404"));

class AcceptTeamInvite extends Component {
  static contextType = PageAccessContext;
  loginRedirect: any;
  constructor(props: any) {
    super(props);

    let qsParse = qs.parse(this.props.location.search);
    this.state = {
      isLoggedIn: firebase.auth().currentUser ? true : false,
      token: props.match.params.id ? props.match.params.id : "",
      currentUserEmail: firebase.auth().currentUser
        ? firebase.auth().currentUser.email
        : "",

      email: qsParse.email ? qsParse.email.toLowerCase() : "",
      loading: true,
      error: false,
      errorMessage: "",
      teamInviteExisits: true
    };
  }

  componentDidMount() {
    this.acceptTeamInvite();
  }

  acceptTeamInvite = async () => {
    let { token, email } = this.state;
    let idToken = firebase.auth().currentUser
      ? await firebase.auth().currentUser.getIdToken()
      : null;

    api.acceptTeamInvite(token, email, idToken, (res: any) => {
      let { response, pageAccess } = res.data;

      if (res.status === 200) {
        if (response.customToken) {
          firebase
            .auth()
            .signInWithCustomToken(response.customToken)
            .then((user) => {
              setItemInLocalStorage(PROFILE_PIC, res.data.profilePic);
              this.redirectUser(response, pageAccess);
            })
            .catch((err) => {
              this.props.history.push(
                "/login?email=" + email + "&next_url=" + window.location.href
              );
            });
          return;
        }
        this.redirectUser(response, pageAccess);
      } else {
        if (response?.requiredLogin) {
          this.props.history.push(
            "/login?email=" + email + "&next_url=" + window.location.href
          );
          return;
        }
        if (
          response &&
          response.message &&
          response.message === "Invite doesn't exist"
        ) {
          //Freelancer flow
          this.setState({
            loading: false,
            error: false,
            teamInviteExisits: false,
            errorMessage:
              "Looks like your invite was revoked. Reach out to the person who originally invited you."
          });
        } else {
          this.setState({
            loading: false,
            error: true,
            errorMessage: response?.message || ""
          });
          this.loginRedirect = setTimeout(() => {
            this.props.history.push("/login?email=" + email);
          }, 3000);
        }
      }
      return;
    });
  };

  redirectUser = (response: any, pageAccess: any) => {
    this.context.setContextData(pageAccess, () => {});

    this.props.history.push(
      response.role === teamRoles.FREELANCER
        ? "/agency/writer-projects"
        : "/workspaces"
    );
  };

  componentWillUnmount = () => {
    clearTimeout(this.loginRedirect);
  };

  render() {
    const { loading, error, errorMessage, teamInviteExisits } = this.state;

    if (loading) {
      return <Loading />;
    }

    if (error) {
      return (
        <Suspense fallback={<div />}>
          <Page404 errorMessage={errorMessage} />
        </Suspense>
      );
    }

    if (!teamInviteExisits) {
      return (
        <Container
          maxWidth="lg"
          style={{ marginTop: "30vh", textAlign: "center" }}
        >
          <Typography variant="h5">{errorMessage}</Typography>
        </Container>
      );
    }
  }
}

export default AcceptTeamInvite;